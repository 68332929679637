import { getDynamicTranslation } from "../components/core/localization/localizationUtils";

export class ApiHelper {
  /**
   * Parses the JSON object of parameters to actual parameter string.
   * Params are either a string representation or the whole JSON object so the passing of the parameters to the call is simplified.
   * If the params are empty just return the url. If the params are a string, return the string as is.
   * If the params are an JSON object use the attribute name as key and the attribute value as param value and seperate them by '&'.
   * Returns a list of query parameters in following format: url?{key_1}={value_1}&{key_2}={value_2}...
   * @param url the url of the API endpoint
   * @param params params in JSON object format or string
   */
  static parseUrlWithParams(
    url: string,
    params: null | string | any = null,
    asPartOfQueryString = true
  ): string {
    if (!params || Object.keys(params).length === 0) {
      return url;
    }

    // if the parameters come as string just use them as is
    if (typeof params === "string") {
      return params;
    }

    //otherwise destruct JSON object to array of strings in format "key=value"
    let paramsArray: string[] = [];
    for (var p in params)
      if (params.hasOwnProperty(p)) {
        // don't add to array if the param string is empty or an empty array
        if (Array.isArray(params[p]) && params[p].length === 0) {
          continue;
        } else if (!Array.isArray(params[p]) && params[p] === "") {
          continue;
        }
        paramsArray.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
      }
    // join the query params with separator '& '
    let queryParamsString = paramsArray.join("&");

    if (asPartOfQueryString && queryParamsString !== "") {
      // append query params string to '?' character that marks the beginning of the params
      queryParamsString = "?" + queryParamsString;
    }

    return url + queryParamsString;
  }

  static showErrorMessageFromApi(errorObject: any) {
    let errorMessage = "";
    for (const key in errorObject) {
      if (Object.prototype.hasOwnProperty.call(errorObject, key)) {
        const element = getDynamicTranslation(errorObject[key], "snackbars");
        errorMessage += element;
      }
    }
    return errorMessage;
  }
}
