import { Box, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import { useDispatch, useSelector } from "react-redux";
import {
  ELearningFormData,
  mapAddELearningToDto,
  mapToELearningFormDatatoListData,
} from "../../../../../models/elearning";
import {
  mapToTrainingTypeString,
  mapTrainingTypeToArticleWithNounPossessiveCase,
  TrainingType,
} from "../../../../../models/enums/trainingType.enum";
import { mapAddEventToDto } from "../../../../../models/eventData";
import { mapEventTemplateDataToDto } from "../../../../../models/templateData";
import { AppState } from "../../../../../redux";
import {
  addBlendedLearning,
  addELearning,
} from "../../../../../redux/e-learnings/actions";
import { addEvent } from "../../../../../redux/events/actions";
import { addEventTemplate } from "../../../../../redux/templates/events/actions";
import { EventItem } from "../../list/eventItem/EventItem";
import "./PreviewEvent.scss";
import { TaskPreview } from "./TaskPreview";
import { updateScormTitle } from "../../../../../api/scorm/scormService";

interface PreviewEventProps {
  showEventPreview: {
    open: boolean;
    template: boolean;
  };
  typeOfTrainingToBeCreated: TrainingType;
  onClose: () => void;
}

export const PreviewEvent = ({
  showEventPreview,
  typeOfTrainingToBeCreated,
  onClose,
}: PreviewEventProps) => {
  const dispatch = useDispatch();
  const preview = useSelector((state: AppState) => state.preview);
  const eventTemplates = useSelector((state: AppState) => state.eventTemplates);

  const handleClose = () => {
    onClose();
  };

  const trainingType = mapToTrainingTypeString(typeOfTrainingToBeCreated);

  return (
    <Dialog open={showEventPreview.open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        {typeOfTrainingToBeCreated === TrainingType.DefaultEvent
          ? "Veranstaltungsvorschau"
          : `Vorschau ${mapTrainingTypeToArticleWithNounPossessiveCase(
              typeOfTrainingToBeCreated,
              trainingType
            )}`}
      </DialogTitle>
      <DialogContent>
        <Box component="div" className="preview-event-box">
          <EventItem
            event={mapToELearningFormDatatoListData(preview.previewEvent)}
            preview
          />
        </Box>
        <Box component="div" style={{ marginBottom: "1.25rem" }}>
          <TaskPreview
            eventType={preview.previewEvent.eventType}
            typeOfTrainingToBeCreated={typeOfTrainingToBeCreated}
          />
        </Box>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              const templateMode = eventTemplates.eventTemplateMode;
              if (showEventPreview.template) {
                dispatch(
                  addEventTemplate(
                    mapEventTemplateDataToDto(preview.previewEvent),
                    false,
                    true,
                    preview.previewEvent
                  )
                );
              } else {
                if (typeOfTrainingToBeCreated === TrainingType.DefaultEvent) {
                  dispatch(
                    addEvent(
                      mapAddEventToDto(
                        preview.previewEvent,
                        templateMode ? eventTemplates.eventTemplate.id : undefined
                      )
                    )
                  );
                } else if (typeOfTrainingToBeCreated === TrainingType.ELearning) {
                  dispatch(
                    addELearning(
                      mapAddELearningToDto(preview.previewEvent as ELearningFormData)
                    )
                  );
                } else if (typeOfTrainingToBeCreated === TrainingType.BlendedLearning) {
                  dispatch(
                    addBlendedLearning(
                      mapAddELearningToDto(preview.previewEvent as ELearningFormData)
                    )
                  );
                }
              }
              // update scorm title
              const docs = preview.previewEvent.documents;
              if (docs && docs.length > 0) {
                docs.forEach(async (doc) => {
                  await updateScormTitle(doc, preview.previewEvent.title);
                });
              }
              handleClose();
            }}
          >
            {showEventPreview.template &&
            typeOfTrainingToBeCreated === TrainingType.DefaultEvent
              ? "Als Vorlage speichern und erstellen"
              : `${trainingType} erstellen`}
          </Button>
          <Button
            onClick={handleClose}
            size="medium"
            variant="contained"
            color="secondary"
          >
            Abbrechen
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

// TODO no translation yet
