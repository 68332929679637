import React from "react";
import { Alert } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { isMobileDevice } from "../../../../../utils/isMobileDevice";

interface CaptureHintsProps {
  modeCardIDCapture: boolean;
}

const CaptureHints: React.FC<CaptureHintsProps> = ({ modeCardIDCapture }) => {
  const { t } = useTranslation(["authCapture"]);
  const captureNumber = modeCardIDCapture ? 2 : 1;
  const isMobile = isMobileDevice();

  return (
    <Alert severity="info">
      <strong>
        {`${t("captureTitle", { ns: "authCapture" })} ${captureNumber}:`}{" "}
        {isMobile
          ? t("shotCommands.captureTips.mobile", { ns: "authCapture" })
          : t("shotCommands.captureTips.web", { ns: "authCapture" })}
      </strong>
      <br />
      <br />
      {modeCardIDCapture
        ? t("shotCommands.captureTips.capture2", { ns: "authCapture" })
        : t("shotCommands.captureTips.capture1", { ns: "authCapture" })}
      <br />
      <br />
      {t("shotCommands.captureTips.instruction", { ns: "authCapture" })}
      <br />
      <br />
      {t("shotCommands.captureTips.title", { ns: "authCapture" })}
      <ul>
        <li>{t("shotCommands.captureTips.lighting", { ns: "authCapture" })}</li>
        <li>{t("shotCommands.captureTips.cleaning", { ns: "authCapture" })}</li>
        {modeCardIDCapture ? (
          <li>{t("shotCommands.captureTips.identityCard", { ns: "authCapture" })}</li>
        ) : (
          <li>{t("shotCommands.captureTips.profile", { ns: "authCapture" })}</li>
        )}
      </ul>
    </Alert>
  );
};

export default CaptureHints;
