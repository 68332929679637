import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import {
  LumosArrowDownSvg,
  LumosLanguageSvg,
} from "../../../assets/icons/custom-svg-components/LumosSvgCollection";
import { LanguageOption, UserLanguage } from "../../../models/userLanguageData";
import { userLanguageService } from "../../../api";
import Loader from "../../theming/loader/Loader";
import {
  defaultLanguage,
  formatLanguageOptions,
  languageOptionsFallback,
} from "./localizationUtils";

/**
 * @description LanguageSwitcher component, allowing users to switch between languages if multiple languages are available
 */
const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    return localStorage.getItem("selectedLanguage") || i18n.language;
  });
  const [languageOptions, setLanguageOptions] = useState<LanguageOption[]>(
    languageOptionsFallback
  );
  const [userLanguageIsLoading, setUserLanguageIsLoading] = useState(true);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const languages: UserLanguage[] = await userLanguageService.getUserLanguages();

        const formattedLanguages = formatLanguageOptions(languages);

        setLanguageOptions(formattedLanguages);

        if (!languageOptions.find((option) => option.value === selectedLanguage)) {
          const defaultLang = languageOptions.find((option) => option.isDefault);
          setSelectedLanguage(defaultLang ? defaultLang.value : defaultLanguage.value);
        }

        setUserLanguageIsLoading(false);
      } catch (error) {
        setUserLanguageIsLoading(false);
      }
    };

    fetchLanguages();
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedLanguage", selectedLanguage);
  }, [selectedLanguage]);

  const selectedLanguageLabel =
    languageOptions.find((option) => option.value === selectedLanguage.split("-")[0])
      ?.label || defaultLanguage.label;

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
    setIsOpen(false);
  };

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (selectedLang: string) => {
    changeLanguage(selectedLang);
  };

  if (languageOptions.length <= 1) {
    return null;
  }

  return userLanguageIsLoading ? (
    <Loader />
  ) : (
    <Box display="inline-flex" alignItems="center" position="relative">
      <Box onClick={handleToggleDropdown} style={{ cursor: "pointer" }}>
        <div
          onClick={handleToggleDropdown}
          style={{ display: "flex", alignItems: "center" }}
        >
          <LumosLanguageSvg />
          {selectedLanguageLabel}
          <LumosArrowDownSvg />
        </div>
      </Box>
      {isOpen ? (
        <Box
          position="absolute"
          top="100%"
          left="0"
          boxShadow="0 .25rem .5rem rgba(0, 0, 0, 0.1)"
          style={{ backgroundColor: "#fff", minWidth: "100%", zIndex: 600 }}
        >
          {languageOptions
            .filter((option) => option.value !== selectedLanguage)
            .map((option) => (
              <div
                key={option.value}
                onClick={() => handleOptionClick(option.value)}
                style={{ padding: ".5rem", cursor: "pointer" }}
              >
                {option.label}
              </div>
            ))}
        </Box>
      ) : null}
    </Box>
  );
};

export default LanguageSwitcher;
