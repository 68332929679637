import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { UserLanguage } from "../models/userLanguageData";
import { AxiosResponse } from "axios";

export class UserLanguageService extends BaseCrudService {
  /**
   * Retrieves the user language data from the API.
   * @returns {Promise<UserLanguage[]>} A promise that resolves to the user language data.
   */
  async getUserLanguages(): Promise<UserLanguage[]> {
    const response: AxiosResponse<UserLanguage[]> = await this.getAll<UserLanguage[]>(
      apiRoutes.user_language
    );

    return response.data;
  }
}
