import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { TrainingType } from "../../../../models/enums/trainingType.enum";
import { AppState } from "../../../../redux";
import CancelButton from "./buttons/CancelButton";
import CreateOrUpdateButton from "./buttons/CreateOrUpdateButton";
import CreateTemplateFromEventButton from "./buttons/CreateTemplateFromEventButton";
import DeleteEventButton from "./buttons/DeleteEventButton";
import ProcessingEvent from "./ProcessingEvent";
import { updateScormTitle } from "../../../../api/scorm/scormService";

interface EventFormButtonsProps {
  hasBeenDeleted: () => void;
  isEditMode: boolean;
  id: string;
  typeOfTrainingToBeCreated: TrainingType;
}

/**
 * EventFormButtons component renders a set of buttons for creating, updating,
 * and managing events within a form.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Function} hasBeenDeleted - Callback function triggered when an event has been deleted.
 * @param {boolean} isEditMode - Indicates whether the component is in edit mode.
 * @param {string} id - Identifier of the event.
 * @param {TrainingType} typeOfTrainingToBeCreated - Type of training for which the buttons are displayed.
 * @returns {JSX.Element} - Rendered component.
 */

const EventFormButtons = ({
  id,
  isEditMode,
  typeOfTrainingToBeCreated,
  hasBeenDeleted,
}: EventFormButtonsProps) => {
  // Redux selector to get the overall event state
  const event = useSelector((state: AppState) => state.event);

  const handleSaveSuccess = (title: string) => {
    if (isEditMode) {
      const docs = event?.editEvent?.documents;
      if (docs && docs.length > 0) {
        docs.forEach(async (doc) => {
          await updateScormTitle(doc.id, title);
        });
      }
    }
  };

  return (
    <>
      {/* Grid container for organizing buttons */}
      <Grid container spacing={4}>
        {/* Empty grid item to create space */}
        <Grid item xs={12} />
        {/* Check if event is loading */}
        {event.isLoading ? (
          // Display processing event component
          <Grid item xs={isEditMode ? 6 : 12} sm={isEditMode ? 3 : 7}>
            <ProcessingEvent
              isEditMode={isEditMode}
              typeOfTrainingToBeCreated={typeOfTrainingToBeCreated}
            />
          </Grid>
        ) : (
          // Display buttons for creating, updating, and managing events
          <>
            <Grid item xs={6} sm={3}>
              {/* CreateOrUpdateButton for creating or updating events */}
              <CreateOrUpdateButton
                isEditMode={isEditMode}
                typeOfTrainingToBeCreated={typeOfTrainingToBeCreated}
                onSaveSuccess={handleSaveSuccess}
              />
            </Grid>
            {/* Display CreateTemplateFromEventButton if not in edit mode and the training type is DefaultEvent */}
            {!isEditMode && typeOfTrainingToBeCreated === TrainingType.DefaultEvent && (
              <CreateTemplateFromEventButton />
            )}
          </>
        )}
        {/* Display CancelButton for canceling form actions */}
        <Grid item xs={6} sm={3}>
          <CancelButton
            isEditMode={isEditMode}
            typeOfTrainingToBeCreated={typeOfTrainingToBeCreated}
          />
        </Grid>
        {/* Display DeleteEventButton if in edit mode */}
        {isEditMode ? (
          <>
            <Grid item xs={6} sm={3} />
            <Grid item xs={6} sm={3}>
              {/* DeleteEventButton for deleting events */}
              <DeleteEventButton
                typeOfTrainingToBeCreated={typeOfTrainingToBeCreated}
                hasBeenDeleted={() => {
                  hasBeenDeleted();
                }}
                id={id}
              />
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  );
};

export default EventFormButtons;

// TODO no translation yet
