/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { AppState } from "../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Loader from "../../../components/theming/loader/Loader";
import { bookEvent } from "../../../redux/bookings/actions";
import { BookingDataWithPayment, BookingEventData } from "../../../models/bookingData";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { ReducedOrderData } from "../../../models/orderData";
import { getStripeData } from "../../../redux/stripe/actions";
import { getCart } from "../../../redux/cart/actions";
import routes from "../../../routing/routes";

export const StripeCheckoutDetailPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["booking", "common", "snackbars"]);

  const cartState = useSelector((appState: AppState) => appState.cart);
  const stripeState = useSelector((appState: AppState) => appState.stripe);

  const [userHasNoOrganizations, setUserHasNoOrganizations] = useState(false);
  const [orderIsFreeOfCharge, setOrderIsFreeOfCharge] = useState(false);

  useEffect(() => {
    if (!cartState.isLoading) {
      dispatch(getCart());
    }
  }, []);

  useEffect(() => {
    if (!stripeState.isLoading) {
      if (cartState.cart.stripe_payment) {
        dispatch(getStripeData(cartState.cart.stripe_payment));
      }
    }
  }, [cartState]);

  // prevents mutltiple booking calls
  const stripeResponseHandled = useRef(false);

  const handleStripeResponse = () => {
    const events: BookingEventData[] = cartState.cart.cart_items.map((cartItem) => {
      return {
        event: cartItem.id,
        status: 20,
        catering_option: cartItem.catering_option,
        use_alternative_billing_address: true,
      };
    });

    let invoice_is_for_user = false;
    if (userHasNoOrganizations || orderIsFreeOfCharge) {
      invoice_is_for_user = true;
    }

    const order: ReducedOrderData = {
      id: cartState.cart.order,
      orderNumber: -1,
      orderSum: 0,
      events: [],
      items: [],
    };

    const bookingDataWithPayment: BookingDataWithPayment = {
      events,
      payment: {
        order: order,
        payment_method: "SEPA",
        invoice_organization: {
          id: "",
        },
        invoice_is_for_user: invoice_is_for_user,
        paypal_data: {
          id: "",
          transaction_id: "",
          gross_amount: "",
          capture_id: "",
          currency_code: "",
        },
      },
    };

    const book_for = cartState.cart.cart_items.find((item) => !!item.book_for?.id);
    if (book_for) {
      bookingDataWithPayment.book_for = book_for.book_for.id;
    }

    if (events.length > 0) {
      dispatch(bookEvent(bookingDataWithPayment, stripeState.stripeData.id));
    } else {
      enqueueSnackbar(t("booking.emptyCart", { ns: "snackbars" }), {
        variant: "error",
      });
    }
  };

  if (cartState.isLoading || stripeState.isLoading) {
    return <Loader loadingText={t("waitNote", { ns: "common" })} />;
  }

  if (
    stripeState.stripeData.status === "complete" &&
    stripeResponseHandled.current === false
  ) {
    stripeResponseHandled.current = true;
    handleStripeResponse();
  }

  if (stripeState.stripeData.status === "open") {
    history.push(routes.stripeCheckoutInit);
  }

  return null;
};
