import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Box, Tooltip } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { AppState } from "../../../../redux";
import { getFavoritesCount } from "../../../../redux/favorites/actions";
import routes from "../../../../routing/routes";
import "./Profile.scss";
import { useTranslation } from "react-i18next";
import { defaultPalette } from "../../../theming/defaultTheme";

export const FavoriteStar: React.FC = () => {
  const { t } = useTranslation(["events", "snackbars"]);

  const favorite = useSelector((state: AppState) => state.favorite);
  const accessibility = useSelector((state: AppState) => state.accessibility);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!favorite.isLoading && !favorite.numberofFavoritesLoaded) {
      dispatch(getFavoritesCount());
    }
  }, [dispatch, favorite.numberofFavoritesLoaded, favorite.isLoading]);

  return (
    <Box mb={1} ml={2}>
      <Box component="span">
        <Badge
          badgeContent={favorite.numberOfFavorites}
          color="primary"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          overlap="circular"
        >
          {" "}
          <Tooltip
            color="primary"
            title={
              favorite.isLoading
                ? ""
                : favorite.numberOfFavorites !== 0
                  ? t("favorites.items", {
                      numberOfFavorites: favorite.numberOfFavorites,
                      ns: "events",
                    })
                  : t("favorites.isEmpty", { ns: "events" })
            }
            arrow
          >
            <FontAwesomeIcon
              icon={faStar}
              size="2x"
              color={accessibility.monoMode ? "#000" : defaultPalette.primary?.main}
              style={{ marginRight: "0.3rem", cursor: "pointer" }}
              onClick={() => {
                history.push(routes.favorites);
              }}
            />
          </Tooltip>
        </Badge>
      </Box>
    </Box>
  );
};
