/* eslint-disable camelcase */
/* eslint-disable react/jsx-max-depth */
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Link,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import CustomTextField from "../../../components/forms/CustomTextField";
import { LoginData } from "../../../models/userData";
import { login } from "../../../redux/auth/actions";
import routes from "../../../routing/routes";
import "./LoginPage.scss";
import customerLoginSVG from "../../../assets/general/customer_login.svg";
import i18n from "../../../localization/i18n";
import DOMPurify from "dompurify";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "../../../models/enums/salutationType.enum";

export const LoginPage: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const { t } = useTranslation(["authentication", "common", "layout", "snackbars"]);

  // !NOTE: for svg use fixed width
  const { from } = (location.state as { from: { pathname: string } }) || {
    from: { pathname: routes.login },
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [metaDataIsLoading, setMetaDataIsLoading] = useState(true);

  const [featureFlags, setFeatureFlags] = useState({
    registration_is_enabled: true,
    forgot_password_is_enabled: true,
  });

  useEffect(() => {
    const fetchFeatureFlag = async () => {
      try {
        const response = await axios.get("/api/feature-flags");
        setFeatureFlags({
          registration_is_enabled: response.data.registration_is_enabled,
          forgot_password_is_enabled: response.data.forgot_password_is_enabled,
        });
      } catch (e) {
        enqueueSnackbar(
          t("backendResponses.error.captureNotSaved", { ns: "snackbars" }),
          {
            variant: "error",
          }
        );
      }
    };
    fetchFeatureFlag();
  }, []);

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await axios.get("/api/meta-data");
        setMetaData({
          login_text_german: response.data.login_text_german,
          login_text_english: response.data.login_text_english,
          login_username_german: response.data.login_username_german,
          login_username_english: response.data.login_username_english,
        });
      } catch (error) {
        enqueueSnackbar(
          t("backendResponses.error.captureNotSaved", { ns: "snackbars" }),
          {
            variant: "error",
          }
        );
      } finally {
        setMetaDataIsLoading(false);
      }
    };
    fetchMetaData();
  }, [i18n.language]);

  // Local state for metadata and loading status
  const [metaData, setMetaData] = useState({
    login_text_german: "",
    login_text_english: "",
    login_username_german: "",
    login_username_english: "",
  });

  const loginText =
    getCurrentLanguage() === "de" || getCurrentLanguage() === "de-DE"
      ? {
          text: metaData.login_text_german,
          username: metaData.login_username_german,
        }
      : {
          text: metaData.login_text_english,
          username: metaData.login_username_english,
        };

  const createSafeHTML = (htmlString: string) => {
    return { __html: DOMPurify.sanitize(htmlString) };
  };

  // Show loader if metadata is still loading
  const loginContent = metaDataIsLoading ? (
    <CircularProgress />
  ) : (
    <Box
      className="login-content"
      style={{ padding: 0, margin: 0, overflowWrap: "anywhere" }}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <p dangerouslySetInnerHTML={createSafeHTML(loginText.text)} />
    </Box>
  );

  return (
    <Grid container alignItems="center" style={{ marginTop: "1.25rem" }}>
      <Grid item xs={12} md={9} style={{ paddingBottom: ".375rem" }}>
        <Typography>{loginContent}</Typography>
        <Box style={{ width: "100%", textAlign: "center" }}>
          <img
            src={customerLoginSVG}
            style={{
              maxHeight: "40vh",
              width: "99%",
              objectFit: "cover",
              objectPosition: "top",
              display: windowHeight < 700 ? "none" : "block",
            }}
            alt={t("altText.loginSVG", { ns: "layout" })}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={9}>
        <Box>
          <Formik
            onSubmit={(data: LoginData) => {
              dispatch(login(data, from));
            }}
            initialValues={{ username: "", password: "" }}
          >
            <Form
              className={classes.loginForm}
              style={{ marginTop: "1.25rem" }}
              data-testid="login-form"
            >
              <Grid container direction="row" spacing={1}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <CustomTextField
                      autoComplete="username"
                      name="username"
                      id="profile-mail"
                      label={loginText.username}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <CustomTextField
                      autoComplete="current-password"
                      name="password"
                      id="profile-password"
                      type="password"
                      label={t("profile.password", { ns: "common" })}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Button
                      id="submit-login"
                      type="submit"
                      size="large"
                      variant="contained"
                      color="primary"
                      disableElevation
                      disableRipple
                    >
                      {t("login", { ns: "authentication" })}
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                {featureFlags.registration_is_enabled ? (
                  <Grid item xs={12} sm={6}>
                    <Link
                      className="icon-arrow-right large-link"
                      href="/registration"
                      rel="noopener noreferrer"
                    >
                      {t("loginPage.register", { ns: "authentication" })}
                    </Link>
                  </Grid>
                ) : null}
                {featureFlags.forgot_password_is_enabled ? (
                  <Grid item xs={12} sm={6}>
                    <Link
                      href={routes.forgotPassword}
                      className="icon-arrow-right large-link"
                    >
                      {t("password.forgotten.title", {
                        ns: "authentication",
                      })}
                    </Link>
                  </Grid>
                ) : null}
              </Grid>
            </Form>
          </Formik>
        </Box>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  formGrid: {
    paddingLeft: ".7031rem",
    paddingRight: ".7031rem",
  },
  margin: {
    margin: theme.spacing(1),
  },
  loginForm: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "100%",
    },
  },
}));
