import { useState, useEffect } from "react";

/**
 * Custom hook to manage accordion expansion state with localStorage persistence.
 * @param storageKey - The key for localStorage.
 * @returns [expanded, handleChange] - The expanded panel state and a function to update it.
 */
export const useAccordionState = (storageKey: string) => {
  const [expanded, setExpanded] = useState<string | false>(
    localStorage.getItem(storageKey) || false
  );

  useEffect(() => {
    localStorage.setItem(storageKey, expanded || "");
  }, [expanded, storageKey]);

  /**
   * Clears localStorage when the component unmounts, so that the accordion
   * closes when the user navigates away from the page or the tab.
   */
  useEffect(() => {
    return () => {
      localStorage.removeItem(storageKey);
    };
  }, [storageKey]);

  /**
   * Handles the change of the accordion panel.
   * @param panel - The panel key.
   * @returns void
   */
  const handleChange = (panel: string) => (_: unknown, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return [expanded, handleChange] as const;
};
