import { Box } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { LumosTargetGroupSvg } from "../../../../../assets/icons/custom-svg-components/LumosSvgCollection";
import {
  ReducedParticipationType,
  TargetGroupIdData,
} from "../../../../../models/targetGroupData";
import { AppState } from "../../../../../redux";
import { mapToTargetGroupTranslationKey } from "../../../../../models/enums/eventType.enum";
import { getDynamicTranslation } from "../../../localization/localizationUtils";

interface ITargetGroupListProps {
  targetGroups: TargetGroupIdData;
}

const TargetGroupList: React.FunctionComponent<ITargetGroupListProps> = (props) => {
  const targetGroups = useSelector((state: AppState) => state.targetGroups.targetGroups);

  function mapTargetGroupIdToString(targetGroupNumber: string): string {
    const mappedParticipationType = targetGroups.participation_types.find(
      (x) => x.id === targetGroupNumber
    );
    const participationTypes = mappedParticipationType?.label || "";

    return participationTypes;
  }

  function mapParticipationTypesToValidItemsString(
    participationTypeIds: string[],
    addComma: boolean = false
  ): string {
    const participationTypeItems: ReducedParticipationType[] = participationTypeIds.map(
      (id) => {
        return { id, document_type: mapTargetGroupIdToString(id), label: "" };
      }
    );
    const validParticipationTypeItems: ReducedParticipationType[] =
      participationTypeItems.filter((item) => item.document_type.length > 0);
    let validParticipationTypeItemsString = validParticipationTypeItems
      .map((item) => mapToTargetGroupTranslationKey(item.document_type))
      .map((translationKey) => getDynamicTranslation(translationKey, "common"))
      .join(", ");
    if (addComma) validParticipationTypeItemsString += ", ";
    return validParticipationTypeItemsString;
  }

  return (
    <Box component="span" display="flex" alignItems="center">
      {props.targetGroups.participation_types &&
        props.targetGroups.participation_types.length > 0 && (
          <>
            <LumosTargetGroupSvg />
            {mapParticipationTypesToValidItemsString(
              props.targetGroups.participation_types
            )}
          </>
        )}
    </Box>
  );
};

export default TargetGroupList;
