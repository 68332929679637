/* eslint-disable @typescript-eslint/no-unused-vars,no-unused-vars */
import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { NIL as NIL_UUID } from "uuid";
import {
  mapToSalutationTypeString,
  SalutationType,
} from "../../../models/enums/salutationType.enum";
import { ActivitiyHistory } from "../../../models/mailHistory";
import { AppState } from "../../../redux";
import { getTargetGroups } from "../../../redux/target-groups/actions";
import { getStatistics } from "../../../redux/activityHistory/actions";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import Loader from "../../../components/theming/loader/Loader";
import TabsLumos from "../../../components/theming/tabs/TabsLumos";
import TargetGroupList from "../../../components/core/events/list/eventItem/TargetGroupList";
import ActivityHistoryFilterForm from "../../../components/core/activityHistory/ActivityHistoryFilterForm";
import MailHistory from "../../../components/core/activityHistory/MailHistory";
import UserParticipations from "../../../components/core/activityHistory/Participations";
import { SearchUserField } from "../../../components/core/activityHistory/SearchUserField";
import { useTabPersistence } from "../../../hooks/useTabPersistence";

export interface RowProps {
  row: ActivitiyHistory;
}

export const ActivityHistoryPage: React.FC = () => {
  const targetGroups = useSelector((state: AppState) => state.targetGroups);
  const activityHistory = useSelector((state: AppState) => state.activityHistory);
  const [statistics, setStatistics] = useState<ActivitiyHistory | undefined>(
    activityHistory.statistics
  );
  const [loadForUser, setLoadForUser] = useState<string>(NIL_UUID);
  const [isParticipationsTab, setIsParticipationsTab] = useState<boolean>(false);
  const { activeTab, handleTabChange } = useTabPersistence({ defaultTab: 0 });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!targetGroups.isLoading && !targetGroups.targetGroupsLoaded) {
      dispatch(getTargetGroups());
    }
  }, [dispatch, targetGroups]);

  return (
    <>
      <HeadingLumos>Aktivitätenhistorie</HeadingLumos>
      <Box my={3}>
        <Typography>
          Hier können Sie nach einem bestimmten Nutzer suchen, um sich dessen Aktivitäten
          anzeigen lassen zu können. Gesucht werden kann nach E-Mail, Nutzernamen oder
          Vor- und Nachnamen.
        </Typography>
      </Box>
      <Formik
        onSubmit={(_data: { search: string; statistics?: ActivitiyHistory }) => {}}
        enableReinitialize
        initialValues={{
          search: "",
        }}
      >
        {() => {
          return (
            <Form style={{ marginBottom: "1rem" }}>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12}>
                  <b>Nutzersuche</b>
                </Grid>
                <Grid item xs={6} md={8}>
                  <SearchUserField
                    initialUser={undefined}
                    onChangeUserId={(id: string) => {
                      setLoadForUser(id);
                      dispatch(getStatistics(id));
                      setStatistics(undefined);
                    }}
                  />
                </Grid>
                {loadForUser !== NIL_UUID && (
                  <Grid item xs={6} sm={4}>
                    <Button
                      size="medium"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        dispatch(getStatistics(loadForUser));
                        setStatistics(activityHistory.statistics);
                      }}
                    >
                      Aktivitäten für diesen Nutzer anzeigen
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Form>
          );
        }}
      </Formik>

      {activityHistory.isLoading ? (
        <>
          <Loader />
          <Box minHeight={500} />
        </>
      ) : (
        <>
          {loadForUser !== NIL_UUID && statistics ? (
            <Box my={3}>
              <h3>Nutzerinformation</h3>
              <Grid container spacing={0}>
                <Grid item xs={12} md={6}>
                  <Typography style={{ fontWeight: "bold" }}>Nutzername: </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {statistics?.user.fullname}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography style={{ fontWeight: "bold" }}>Anrede: </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {statistics?.user.salutation === SalutationType.Neutral
                    ? "-"
                    : mapToSalutationTypeString(statistics?.user.salutation)}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography style={{ fontWeight: "bold" }}>Titel: </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {[null, undefined, ""].includes(statistics?.user.academic_title)
                    ? "-"
                    : statistics?.user.academic_title}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography style={{ fontWeight: "bold" }}>E-Mail: </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {[null, undefined, ""].includes(statistics?.user.email)
                    ? "-"
                    : statistics?.user.email}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography style={{ fontWeight: "bold" }}>Telefonnummer: </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {[null, undefined, ""].includes(statistics?.user.phone)
                    ? "-"
                    : statistics?.user.phone}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography style={{ fontWeight: "bold" }}>Handynummer: </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {[null, undefined, ""].includes(statistics?.user.mobile)
                    ? "-"
                    : statistics?.user.mobile}
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <Typography style={{ fontWeight: "bold" }}>LANR: </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {statistics?.user.lanr ? statistics?.user.lanr : "-"}
                </Grid> */}
                <Grid item xs={12} md={6}>
                  <Typography style={{ fontWeight: "bold" }}>Zielgruppen: </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TargetGroupList
                    targetGroups={{
                      // eslint-disable-next-line camelcase
                      participation_types:
                        statistics?.user.participation_types === null
                          ? []
                          : statistics?.user.participation_types,
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box minHeight={500} />
          )}
          {statistics ? (
            <>
              <h3>Aktivitäten</h3>
              <Box mt={3} mb={6}>
                <ActivityHistoryFilterForm
                  statistics={statistics}
                  updateStatistics={(stats: ActivitiyHistory) => setStatistics(stats)}
                  isParticipationsTab={isParticipationsTab}
                />
              </Box>
              <TabsLumos
                activeTab={activeTab}
                onTabChange={handleTabChange}
                tabs={[
                  {
                    label: "Versandte Mails",
                    content: (
                      <MailHistory
                        mailHistory={statistics.mail_history}
                        onLoad={() => setIsParticipationsTab(false)}
                      />
                    ),
                  },
                  {
                    label: "Veranstaltungen",
                    content: (
                      <UserParticipations
                        participations={statistics.participations}
                        onLoad={() => setIsParticipationsTab(true)}
                      />
                    ),
                  },
                ]}
              />
            </>
          ) : (
            <Box minHeight={500} />
          )}
        </>
      )}
    </>
  );
};
