import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { BundIDData, BundIDRequestData } from "../models/bundIDData";

export class BundIDService extends BaseCrudService {
  async createBundIDRequest(event_participation: string) {
    const bundIDData: BundIDRequestData = {
      event_participation: event_participation,
      event: "",
      verified: false,
      unmatched_fields: "",
    };
    const response = await this.create<BundIDRequestData>(
      apiRoutes.create_bund_id_request,
      bundIDData
    );
    return response.data;
  }

  async getBundIDRequestData() {
    const response = await this.getAll<BundIDRequestData>(apiRoutes.bund_id_request);
    return response.data;
  }

  async getBundIDData() {
    const response = await this.getAll<BundIDData>(apiRoutes.bund_id_session);
    return response.data;
  }

  async updateBundIDSessionStatus(id: string) {
    const data = {};
    const response = await this.edit(apiRoutes.bund_id_auth, id, data);
    return response.data;
  }
}
