import React, { useState, ReactNode, useEffect } from "react";
import { makeStyles, Paper, Tab, Tabs, Tooltip } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setOpenTab } from "../../../redux/tabs/actions";
import TabPanel from "./TabPanel";

/**
 * @interface TabLumos
 */
interface TabLumos {
  content: ReactNode;
  label: string;
  disabled?: boolean;
  disabledTooltip?: string;
}

/**
 * @interface TabsLumosProps
 */
interface TabsLumosProps {
  tabs: TabLumos[];
  openTabType?: string;
  activeTab: number;
  // eslint-disable-next-line no-unused-vars
  onTabChange: (newIndex: number) => void;
}

/**
 * The TabsLumos component is a tabbed navigation component that allows the
 * user to switch between different views.
 * @param {TabsLumosProps} props
 * @returns {React.ReactElement}
 */
const TabsLumos = ({ onTabChange, openTabType, tabs, activeTab }: TabsLumosProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(activeTab);

  /**
   * This hook is used to update the value of the tab when the
   * activeTab prop changes.
   */
  useEffect(() => {
    setValue(activeTab);
  }, [activeTab]);

  /**
   * This function is called when the user changes the tab and updates
   * the value of the tab.
   * @param {React.ChangeEvent<unknown>} _event
   * @param {number} newValue
   * @returns {void}
   */
  const handleChange = (_event: React.ChangeEvent<unknown>, newValue: number) => {
    if (onTabChange) onTabChange(newValue);
    if (openTabType === "templates") {
      dispatch(setOpenTab(newValue));
    }
    setValue(newValue);
  };

  return (
    <>
      <Paper
        className={classes.root}
        square
        elevation={0}
        variant="outlined"
        style={{
          backgroundColor: "#f8f8f8",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((tab, index) => (
            <Tab
              style={{ pointerEvents: "auto" }}
              key={tab.label}
              disabled={tab.disabled}
              label={
                tab.disabled && tab.disabledTooltip ? (
                  <Tooltip arrow title={tab.disabledTooltip}>
                    <span>{tab.label}</span>
                  </Tooltip>
                ) : (
                  tab.label
                )
              }
              id={`tab-${index}`}
            />
          ))}
        </Tabs>
      </Paper>
      {tabs.map((tab, index) => (
        <TabPanel key={tab.label} value={value} index={index}>
          {tab.content}
        </TabPanel>
      ))}
    </>
  );
};
export default TabsLumos;

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    // comment in to minimize tab size to content
    // maxWidth: "max-content",
  },
}));
