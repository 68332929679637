import { CancellationInvoiceListData } from "../../models/cancellationInvoiceData";
import { Pagination } from "../../models/pagination";

export interface CancellationInvoiceState {
  invoiceList: Pagination<CancellationInvoiceListData>;
  invoiceListLoaded: boolean;
  currentPage: number;
  isLoading: boolean;
}

export const CLEAR_INVOICE = "CLEAR_INVOICE";
export const INVOICE_REQUEST = "INVOICE_REQUEST";
export const INVOICE_FAILURE = "INVOICE_FAILURE";
export const LOAD_INVOICE_DATA_SUCCESS = "LOAD_INVOICE_DATA_SUCCESS";
export const SET_INVOICE_NUMBER = "SET_INVOICE_NUMBER";
export const SET_INVOICE_DATA = "SET_INVOICE_DATA";
export const SET_CANCELLATION_INVOICE_LIST_DATA = "SET_CANCELLATION_INVOICE_LIST_DATA";
export const SET_INVOICES_PAGE_COUNT = "SET_INVOICES_PAGE_COUNT";

export const SET_MY_INVOICES_LIST_SUCCESS = "SET_MY_INVOICES_LIST_SUCCESS";

export interface ClearCancellationInvoiceAction {
  type: typeof CLEAR_INVOICE;
}

export interface CancellationInvoiceRequestAction {
  type: typeof INVOICE_REQUEST;
}

export interface CancellationInvoiceFailureAction {
  type: typeof INVOICE_FAILURE;
}

export interface SetCancellationInvoiceNumberAction {
  type: typeof SET_INVOICE_NUMBER;
  invoice_number: string;
}

export interface LoadCancellationInvoiceDataAction {
  type: typeof LOAD_INVOICE_DATA_SUCCESS;
  invoice: string;
}

export interface SetCancellationInvoiceListAction {
  type: typeof SET_CANCELLATION_INVOICE_LIST_DATA;
  data: Pagination<CancellationInvoiceListData>;
}

export interface SetPageCountAction {
  type: typeof SET_INVOICES_PAGE_COUNT;
  pageCount: number;
}

export type CancellationInvoiceActionTypes =
  | ClearCancellationInvoiceAction
  | CancellationInvoiceRequestAction
  | CancellationInvoiceFailureAction
  | LoadCancellationInvoiceDataAction
  | SetCancellationInvoiceNumberAction
  | SetCancellationInvoiceListAction
  | SetPageCountAction;
