import React from "react";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import TabsLumos from "../../../components/theming/tabs/TabsLumos";
import { DocumentTemplates } from "../../../components/core/templates/document/DocumentTemplates";
import { EventTemplates } from "../../../components/core/templates/event/EventTemplates";
import { TaskTemplates } from "../../../components/core/templates/task/TaskTemplates";
import { useTabPersistence } from "../../../hooks/useTabPersistence";

/**
 * Page for managing templates.
 * @returns JSX.Element - TemplatesPage
 */
export const TemplatesPage: React.FC = () => {
  const { activeTab, handleTabChange } = useTabPersistence({ defaultTab: 0 });

  return (
    <>
      <HeadingLumos>Vorlagen</HeadingLumos>
      <TabsLumos
        activeTab={activeTab}
        onTabChange={handleTabChange}
        openTabType="templates"
        tabs={[
          {
            label: "Veranstaltungen",
            content: <EventTemplates />,
          },
          {
            label: "Standardaufgaben",
            content: <TaskTemplates />,
          },
          {
            label: "Dokumente",
            content: <DocumentTemplates />,
          },
        ]}
      />
    </>
  );
};

// TODO no translation yet
