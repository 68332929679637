import React from "react";
import { Box, Grid, Link, Typography } from "@material-ui/core";
import {
  BFDI_RIGHT_OF_INFORMATION_URL,
  BFDI_URL,
  PRIVACY_URL,
} from "../../routing/externalUrls";

export const PrivacyPolicyPage: React.FC = () => {
  const EMAIL_INFO = "info@amanzii.com";
  const EMAIL_PRIVACY = "datenschutz@amanzii.com";

  return (
    <Grid container>
      <Grid item sm={7}>
        <h1>Datenschutzerklärung</h1>
      </Grid>
      <Grid item sm={12}>
        <Box>
          <b>Datenschutzhinweise nach Artikel 13,14 DSGVO</b>
          <p>
            {" "}
            Für die Bereitstellung des Weiterbildungs- und Belehrungssystems LUMOS ist
            uns der verantwortungsbewusste Umgang mit personenbezogenen Daten sehr
            wichtig. Wir möchten, dass Sie wissen, warum wir welche Daten erheben und wie
            wir diese verwenden. Wir haben technische und organisatorische Maßnahmen
            getroffen, die sicherstellen, dass die Vorschriften über den Datenschutz
            sowohl von uns als auch von unseren externen Dienstleistern beachtet werden.
            Maßgebliche Gesetze sind dabei die Datenschutz-Grundverordnung (DS-GVO), das
            Bundesdatenschutzgesetz (BDSG) und das Digitale Dienste Gesetz (DDG).
          </p>
          <p>
            Grundsätzlich ist die Nutzung der Internetseite ohne Angabe personenbezogener
            Daten möglich. Sofern Sie die Leistung des Weiterbildungs- und
            Belehrungssystems LUMOS in Anspruch nehmen möchten, ist eine Verarbeitung
            personenbezogener Daten erforderlich.
          </p>
          <p>
            Wir behalten uns vor, diese Datenschutzerklärung gelegentlich anzupassen,
            damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um
            Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen. Für
            Ihren Besuch gilt immer die aktuelle Datenschutzerklärung. Weitere
            Informationen zum Datenschutz und zum Datenschutzbeauftragten der Firma
            amanzii GmbH finden Sie auf unserer Unternehmenswebsite unter{" "}
            <Link href={PRIVACY_URL} target="_blank">
              www.amanzii.com/datenschutz/
            </Link>{" "}
          </p>
          <b>1. Name und Kontaktdaten der verantwortlichen Stelle i.S.d. DSGVO</b>
          <p>
            Verantwortlicher: <br />
          </p>
          amanzii GmbH <br />
          Frankfurter Straße <br />
          1A 61231 Bad Nauheim <br />
          <p>
            <Link href={`mailto:${EMAIL_INFO}`}>{EMAIL_INFO}</Link> <br />
            vertreten durch den Geschäftsführer Oliver Kuklok.
          </p>
          <p>
            Der betriebliche Datenschutzbeauftragte ist unter der o.g. Anschrift zu
            erreichen bzw. unter{" "}
            <Link href={`mailto:${EMAIL_PRIVACY}`}>{EMAIL_PRIVACY}</Link> <br />
            per E-Mail.
          </p>
          <b>
            2. Kategorien von personenbezogenen Daten, deren Erhebung, Speicherung so wie
            Art und Zweck der Verarbeitung
          </b>
          <p>
            Sie können jederzeit ohne die Angabe von personenbezogenen Daten unsere
            Website aufrufen. Um unser Angebot vollumfänglich nutzen zu können, müssen
            wir weitere personenbezogene Daten von Ihnen erfassen. Die eingegebenen Daten
            verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes oder
            Dienstes, den Sie auch verwenden. Hier finden Sie die erhobenen und
            gespeicherten Daten nach der Art und Zweck der Verarbeitung aufgelistet:
          </p>
          <b>2.1 Registrierungsfunktion</b>
          <p>
            Für die Nutzung unseres Angebots ist eine Registrierung erforderlich. Die im
            Zuge dieser Registrierung eingegebenen Daten, die aus der Eingabemaske des
            Registrierungsformular ersichtlich sind, mindestens jedoch die E-Mailadresse,
            werden ausschließlich für die Verwendung unseres Angebots erhoben und
            gespeichert. Mit Ihrer Registrierung auf unserer Seite werden wir zudem Ihre
            IP-Adresse und das Datum sowie die Uhrzeit Ihrer Registrierung speichern.
            Dies dient als Absicherung unsererseits für den Fall, dass ein Dritter Ihre
            Daten missbraucht und sich mit diesen Daten ohne Ihr Wissen auf unserer Seite
            registriert. Eine Weitergabe an Dritte erfolgt nicht. Es können weiterhin die
            folgenden Daten zur Registrierung erhoben werden:
          </p>
          <ul style={{ paddingLeft: "2rem" }}>
            <li>E-Mailadresse</li>
            <li>Anrede</li>
            <li>Name</li>
            <li>Adresse</li>
            <li>
              Geburtsdatum, um bei Minderjährigen die Einverständniserklärung einholen zu
              können. In diesem Fall speichern wir auch das Einverständnis des
              Sorgeberechtigten inkl. der Kontakt-E-Mail
            </li>
            <li>Ggf. zugehörige Organisation</li>
          </ul>
          <p>
            Die Daten werden gespeichert, solange der Nutzer das Konto aufrechterhält.
            Wenn das Konto durch den Nutzer zur Löschung gekennzeichnet wird, kann von
            außen nicht mehr auf die Daten zugegriffen werden. Nach Entfall des Zwecks
            oder dem Ablauf der Dokumentationspflicht von nach EU-DSGVO Art. 6 Abs.1a),
            b), c), e) des Auftraggebers werden diese Daten gelöscht. In dieser Zeit hat
            der Nutzer die Möglichkeit sein Konto zu reaktivieren.
          </p>
          <b>2.2 Zertifikatsprüfung</b>
          <p>
            Sie können über LUMOS behördlich gültige Zertifikate erlangen. Wir
            verarbeiten die Informationen über erlangte Zertifikate im Auftrag der
            zuständigen Behörde oder Organisation und speichern diese nach deren
            Vorgaben. Für die Überprüfung der Zertifikatsgültigkeit werden folgende Daten
            gespeichert:
          </p>
          <ul style={{ paddingLeft: "2rem" }}>
            <li>Name</li>
            <li>Geburtsdatum</li>
            <li>Zertifikatsnummer und -ausstellungsdatum</li>
            <li>Ggf. Adresse</li>
            <li>Ggf. zugehörige Organisation</li>
          </ul>
          <b>2.3 Biometrische Daten</b>
          <p>
            Für die Authentisierung zum Start der Belehrung werden Fotos durch die Kamera
            des Endgerätes des Nutzers erstellt. Diese biometrischen Daten werden nach
            Art. 6 Abs.1 a), b), e) für drei Monate gespeichert, um Sie als Nutzer bei
            Fragen zur Vertragserfüllung unterstützen zu können. Anschließend werden
            jegliche biometrischen Daten ohne weitere Verarbeitung automatisch gelöscht.
          </p>
          <b>2.4 Bezahlsysteme</b>
          <p>
            Zur Online-Zahlungsabwicklung bieten wir verschiedene Online-Bezahldienste
            an. Die Übermittelung personenbezogener Daten ist zur Abwicklung Ihres Kaufs
            mit der von Ihnen ausgewählten Zahlungsart notwendig, um die Zahlung zu
            administrieren und Ihre Identität zu bestätigen. Die notwendigen Daten nach
            Art. 6 Abs.1 zur Nutzung eines Sofort-Bezahlungssystems als Online-Bankkunde
            oder des vom Nutzer gewählten Zahlungsdienstleisters dürfen erhoben und
            verarbeitet werden.
          </p>
          <b>2.4.1 PayPal</b>
          <p>
            Auf unserer Website bieten wir die Bezahlung über den Zahlungsdienstleister
            PayPal (Europe) an. Sollten Sie sich für eine Bezahlung mit PayPal
            entscheiden, werden im Rahmen der so ausgelösten Bestellung Ihre Kontaktdaten
            an PayPal übermittelt. PayPal ist ein Angebot der PayPal (Europe) S.à.r.l. &
            Cie. S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg. PayPal übernimmt dabei
            die Funktion eines Online-Zahlungsdienstleisters sowie eines Treuhänders und
            bietet Käuferschutzdienste an. Um Zahlungen mit PayPal durchzuführen,
            benötigen Sie ein entsprechendes PayPal-Konto. Zur erfolgreichen
            Zahlungsabwicklung von PayPal-Zahlungen werden die folgenden
            personenbezogenen Daten übermittelt:
          </p>
          <ul style={{ paddingLeft: "2rem" }}>
            <li>Vorname</li>
            <li>Nachname</li>
            <li>Adresse</li>
            <li>Telefonnummer</li>
            <li>IP-Adresse</li>
            <li>E-Mailadresse</li>
            <li>
              Weitere Daten, die zur Bestellabwicklung erforderlich sind, wie z.B.
              Artikelanzahl oder -bezeichnung oder Rechnungsnummer{" "}
            </li>
          </ul>
          <p>
            Personenbezogenen Daten können seitens PayPals auch an Leistungserbringer, an
            Subunternehmer oder andere verbundene Unternehmen zur Erfüllung der
            vertraglichen Pflichten oder zur Verarbeitung von personenbezogenen Daten im
            Auftrag weitergegeben werden. Welche Daten von PayPal allgemein erhoben,
            verarbeitet, gespeichert und weitergegeben werden, entnehmen Sie der
            Datenschutzerklärung von PayPal unter{" "}
            <Link
              href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full"
              target="_blank"
            >
              https://www.paypal.com/de/webapps/mpp/ua/privacy-full
            </Link>{" "}
          </p>
          <b>2.4.2 Stripe</b>
          <p>
            Für Zahlungen mit Debit- oder Kreditkarte, sowie SEPA-Überweisungen verwenden
            wir auf unserer Website ein Zahlungstool des Online-Bezahldienstes Stripe mit
            Sitz in den USA. Für Kunden innerhalb von der EU ist Stripe Payments (Europe
            Ltd., 1 Grand Canal Street Lower, Grand Canal Dock, Dublin, Irland)
            verantwortlich. Wenn Sie sich für Stripe als Zahlungsart entscheiden, wird
            Ihre Zahlung über Stripe Payments abgewickelt. Stripe muss zu Ihrer
            Authentifizierung und Zahlungsabwicklung Daten verarbeiten und speichern.
            Dabei handelt es sich, abhängig von der gewählten Bezahlmethode, um die
            folgenden personenbezogenen Daten und Transaktionsdaten:
          </p>
          <ul style={{ paddingLeft: "2rem" }}>
            <li>Debit-/ Kreditkartennummer oder Kontonummer</li>
            <li>Bankleitzahl</li>
            <li>Währung</li>
            <li>Betrag und Zahlungsdatum</li>
            <li>Name</li>
            <li>E-Mailadresse</li>
            <li>Land</li>
            <li>Ggf. Telefonnummer</li>
            <li>Ggf. Rechnungsadresse</li>
            <li>Ggf. Transaktionsverlauf</li>
            <li>IP-Adresse</li>
          </ul>
          <p>
            Weiterhin kann Stripe die Daten zur Betrugsabwehr, für die
            Finanzberichterstattung und um die eigenen Dienste vollständig anbieten zu
            können, verwenden. Stripe verkauft keine Ihrer Daten an unabhängige Dritte
            oder andere Unternehmen ohne Verbindung zu Stripe. Die Daten können aber etwa
            an interne Abteilungen, einer beschränkten Anzahl externer Partner oder zur
            Einhaltung gesetzlicher Vorschriften weitergeleitet werden. Stripe verwendet
            Cookies zur Datenerfassung. Cookies, die Stripe für ihre Funktionen
            verwendet, können Sie in Ihrem Browser löschen, deaktivieren und verwalten.
            Unter dem Abschnitt „Cookies“ finden Sie dazu Anleitungen für die
            bekanntesten Browser. Personenbezogene Daten werden grundsätzlich solange das
            Dienstleistungverhältnis besteht gespeichert. Zur Erfüllung von gesetzlichen
            und behördlichen Vorgaben kann Stripe darüber hinaus personenbezogene Daten
            speichern. Da Stripe ein weltweit tätiges Unternehmen ist, können die Daten
            auch in jedem Land, wo Stipe Dienstleistungen anbietet, gespeichert werden.
            So können auch Daten außerhalb Ihres Landes, zum Beispiel in den USA
            gespeichert werden. Stripe verarbeitet Daten von Ihnen u.a. in den USA.
            Stripe ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der
            korrekte und sichere Datentransfer personenbezogener Daten von EU-Bürgern in
            die USA geregelt wird. Mehr Informationen dazu finden Sie auf{" "}
            <Link
              href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en"
              target="_blank"
            >
              https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en
            </Link>{" "}
          </p>
          <p>
            Stripe verwendet zusätzlich sogenannte Standardvertragsklauseln (= Art. 46.
            Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses –
            SCC) sind von der EU-Kommission bereitgestellte Vorlagen, die sicherstellen,
            dass Ihre Daten den europäischen DSGVO-Standards entsprechen, wenn diese in
            Länder außerhalb der EU überliefert und dort gespeichert werden. Durch das
            EU-US Data Privacy Framework und durch die Standardvertragsklausen
            verpflichtet sich Stripe, bei der Verarbeitung Ihrer relevanten Daten, das
            europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA
            gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf
            einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und
            die entsprechenden Standardvertragsklauseln u.a. hier:{" "}
            <Link
              href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de"
              target="_blank"
            >
              https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de
            </Link>{" "}
          </p>
          <p>
            Mehr Informationen zu den Standardvertragsklauseln und über die Daten, die
            durch die Verwendung von Stripe verarbeitet werden, erfahren Sie in der
            Privacy Policy auf{" "}
            <Link href="https://stripe.com/at/privacy" target="_blank">
              https://stripe.com/at/privacy
            </Link>{" "}
          </p>
          <p>
            Für Ihr Recht auf Auskunft, Berichtigung und Löschung Ihrer personenbezogenen
            Daten können Sie auch jederzeit das Stripe-Team über{" "}
            <Link href="https://support.stripe.com/contact/email" target="_blank">
              https://support.stripe.com/contact/email
            </Link>{" "}
            kontaktieren.
            <p>
              <b>2.5 Kontakt und Support-Funktionen</b>
            </p>
          </p>
          <p>
            Wir bieten sowohl Privat- als auch Geschäftskunden an, sich bei Fragen und
            Problemen direkt an uns zu wenden. Zum Empfangen und Verwaltung Ihrer
            Anfragen verwenden wir die aufgelisteten Technologien und Funktionen:
          </p>
          <b>2.5.1 E-Mail</b>
          <p>
            Wir weisen Sie darauf hin, dass der Versand von E-Mails mit einem Risiko
            verbunden sein könnte. Hinweise zum sicheren Umgang mit Ihrer E-Mail finden
            Sie hier:{" "}
            <Link
              href="https://www.bsi.bund.de/DE/Themen/Verbraucherinnen-und-Verbraucher/Informationen-und-Empfehlungen/Onlinekommunikation/E-Mail-Sicherheit/e-mail-sicherheit.html"
              target="_blank"
            >
              https://www.bsi.bund.de/DE/Themen/Verbraucherinnen-und-Verbraucher/Informationen-und-Empfehlungen/Onlinekommunikation/E-Mail-Sicherheit/e-mail-sicherheit.html
            </Link>
            . Wenn Sie sich via E-Mail an uns wenden, bestätigen Sie, dass Sie sich über
            eventuelle Risiken informiert haben und erklären Ihr Einverständnis.
          </p>
          <b>2.5.2 Kontaktformular</b>
          <p>
            Wir bieten Ihnen auf unserer Seite die Möglichkeit, mit uns über ein
            Kontaktformular in Verbindung zu treten. In diesem Fall werden die vom Nutzer
            gemachten Angaben zum Zwecke der Bearbeitung seiner Kontaktaufnahme
            gespeichert. Eine Weitergabe an Dritte erfolgt nicht. Ein Abgleich der so
            erhobenen Daten mit Daten, die möglicherweise durch andere Komponenten
            unserer Seite erhoben werden, erfolgt ebenfalls nicht.
          </p>
          <b>2.5.3 Atlassian Confluence und Jira Service Management</b>
          <p>
            Zur internen Verwaltung, Bearbeitung und Speicherung von Informationen und
            Daten nutzen wir die Atlassian Cloud (Atlassian Inc. (San Francisco, Harrison
            Street Location), 1098 Harrison Street, San Francisco, California 94103, USA)
            sowie Drittanbieter Tools aus dem Atlassian Marketplace. Im Vordergrund
            stehen hierbei die Atlassian Produkte Confluence und Jira Service Management.
            Die Daten der Atlassian Cloud werden bei Amazon Webservices (AWS) gehostet.
            Die von uns verwendete Cloud ist so konfiguriert, dass die Datenresidenz
            ausschließlich in Deutschland liegt. Anhand der Datenresidenz kann
            sichergestellt werden, dass primäre Produktdaten innerhalb der festgelegten
            Datenregionen gehostet werden. Unter primäre Produktdaten fallen Inhalte wie:
          </p>
          <ul style={{ paddingLeft: "2rem" }}>
            <li>
              Jira Service Management Daten:
              <ul style={{ paddingLeft: "2rem" }}>
                <li>Inhalte von Vorgängen/ Anfragetypen/ Formularen</li>
                <li>Anhänge</li>
                <li>Serviceboardkonfiguration</li>
                <li>Produktinterne Benachrichtigungen</li>
              </ul>
            </li>
            <li>
              Confluence Daten:
              <ul style={{ paddingLeft: "2rem" }}>
                <li>Seiten und Blogs</li>
                <li>Anhänge</li>
                <li>Reaktionen und Kommentare</li>
                <li>Produktinterne Benachrichtigungen</li>
              </ul>
            </li>
          </ul>
          <p>
            Atlassian verfügt über diverse Compliance-Funktionen, die die Sicherheits-
            und Datenschutzanforderungen der DSGVO abdecken, z.B. wie SOC2, PCI DSS, ISO
            27001/27018. Die Übertragung und Speicherung der Daten erfolgen
            verschlüsselt. Um entsprechend DSGVO-konform zu handeln, besteht zwischen uns
            und Atlassian eine Auftragsdatenvereinbarung (AVV), welche explizit die
            Standard-Vertragsklauseln der EU enthält.
          </p>
          <p>
            <b>2.6 Technische Funktionen</b>
          </p>
          <p>
            <b>2.6.1 Serverdaten</b>
          </p>
          <p>
            Damit Sie unseren Service störungsfrei verwenden können, werden u.a. folgende
            technische Daten, die Ihr Internet-Browser an uns bzw. an unseren
            Webspace-Provider übermittelt, erfasst (sogenannte Serverlogfiles):
          </p>
          <ul style={{ paddingLeft: "2rem" }}>
            <li>Browsertyp und -version</li>
            <li>verwendetes Betriebssystem</li>
            <li>Webseite, von der aus Sie uns besuchen (Referrer URL)</li>
            <li>Datum und Uhrzeit Ihres Zugriffs</li>
            <li>Ihre Internet-Protokoll (IP)-Adresse</li>
          </ul>
          <p>
            Diese anonymen Daten werden getrennt von Ihren personenbezogenen Daten
            gespeichert und lassen so keine Rückschlüsse auf Ihre Person zu. Sie werden
            zu statistischen Zwecken ausgewertet, um unseren Internetauftritt und unsere
            Angebote optimieren zu können.
          </p>
          <b>2.6.2 Cookies</b>
          <p>
            Wir verwenden auf unserer Seite Cookies zum Wiedererkennen mehrfacher Nutzung
            von Funktionen und Diensten durch denselben Nutzer bzw.
            Internetanschlussinhaber. Cookies sind kleine Textdateien, die Ihr
            Internet-Browser auf Ihrem Rechner ablegt und speichert. Es handelt sich
            dabei zumeist um sog. "Session-Cookies", die nach dem Ende Ihres Besuches
            wieder gelöscht werden. Andere Cookies geben Informationen ab, um Sie
            automatisch wiederzuerkennen. Diese Wiedererkennung erfolgt aufgrund der in
            den Cookies gespeicherten IP-Adresse. Die so erlangten Informationen dienen
            dazu, die Website und Funktionen zu optimieren und Ihnen einen leichteren
            Zugang zu ermöglichen. Sie können die Installation der Cookies durch eine
            entsprechende Einstellung Ihres Browsers verhindern. Wir weisen Sie jedoch
            darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen
            unserer Website vollumfänglich nutzen können. Einen ausführlichen Artikel
            über Cookies und deren Verwaltung finden Sie auf der Website der
            Verbraucherzentrale unter{" "}
            <Link
              href="https://www.verbraucherzentrale.de/wissen/digitale-welt/datenschutz/cookies-kontrollieren-und-verwalten-so-gehts-11996"
              target="_blank"
            >
              https://www.verbraucherzentrale.de/wissen/digitale-welt/datenschutz/cookies-kontrollieren-und-verwalten-so-gehts-11996
            </Link>{" "}
            .
          </p>
          <b>3. Übermittlung von Daten an Dritte</b>
          <p>
            Eine Übermittlung von oben genannten Daten an Dritte, außerhalb des
            Auftragsverarbeitungsvertrags nach EU-DSGVO erfolgt ausdrücklich nicht!
          </p>
          <b>4. Ihre Betroffenenrechte</b>
          <p>
            Als Betroffener gem. DSGVO haben Sie Rechte, die Sie wahrnehmen können.
            Weitere Informationen hierzu erhalten Sie in dem{" "}
            <Link href={BFDI_RIGHT_OF_INFORMATION_URL} target="_blank">
              Artikel zum Recht auf Auskunft (Art. 15 DSGVO)
            </Link>{" "}
            auf der Website der Bundesbeauftragten für den Datenschutz und die
            Informationsfreiheit (BfDI).
          </p>
          <b>5. Widerspruch gegen die Verarbeitung</b>
          <p>
            Insofern Ihre personenbezogenen Daten auf Grund von berechtigten Interessen
            des Verantwortlichen erhoben wurden (Art. 6 Abs. 1 f) DSGVO), haben Sie das
            Recht Widerspruch gegen die Verarbeitung einzulegen. Um von ihrem Recht
            Gebrauch zu machen, genügt eine E-Mail an{" "}
            <Link href={`mailto:${EMAIL_PRIVACY}`}>{EMAIL_PRIVACY}</Link>. Ein Widerruf
            der Einwilligung hat die sofortige Beendigung des Nutzungsvorgangs und die
            Löschung aller Daten zur Folge.
          </p>
          <p>
            Die Erfassung der Daten zur Bereitstellung der Website und die Speicherung
            der Daten in Logfiles ist für den Betrieb der Internetseite zwingend
            erforderlich. Es besteht folglich seitens des Nutzers in diesem Fall keine
            Widerspruchsmöglichkeit.
          </p>
          <p>
            Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen
            Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei der zuständigen
            Landesdatenschutzaufsichtsbehörde zu, wenn Sie der Ansicht sind, dass die
            Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die
            Datenschutz-Grundverordnung verstößt. Welche
            Landesdatenschutzaufsichtsbehörde für uns zuständig ist, können Sie unter
            <Link href={BFDI_URL} target="_blank">
              {" "}
              Anschriften und Links{" "}
            </Link>{" "}
            auf der Website der BfDI ansehen.
          </p>
          <p>
            Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet
            den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde
            einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78
            DSGVO.
          </p>
        </Box>
        <p>
          <Typography>Stand der Datenschutzerklärung: 28.03.2025</Typography>
        </p>
      </Grid>
    </Grid>
  );
};
