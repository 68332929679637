import { BundIDData, BundIDRequestData } from "../../models/bundIDData";

export interface BundIDState {
  bundIDData: BundIDData;
  bundIDRequestData: BundIDRequestData;
  isLoading: boolean;
  bundIDDataLoaded: boolean;
}

export const CLEAR_BUND_ID = "CLEAR_BUND_ID";
export const BUND_ID_REQUEST = "BUND_ID_REQUEST";
export const BUND_ID_FAILURE = "BUND_ID_FAILURE";
export const BUND_ID_SUCCESS = "BUND_ID_SUCCESS";
export const LOAD_BUND_ID_DATA_SUCCESS = "LOAD_BUND_ID_DATA_SUCCESS";
export const SET_BUND_ID_NUMBER = "SET_BUND_ID_NUMBER";
export const SET_BUND_ID_DATA = "SET_BUND_ID_DATA";
export const SET_BUND_ID_REQUEST_DATA = "SET_BUND_ID_REQUEST_DATA";

export interface ClearBundIDAction {
  type: typeof CLEAR_BUND_ID;
}

export interface BundIDRequestAction {
  type: typeof BUND_ID_REQUEST;
}

export interface BundIDFailureAction {
  type: typeof BUND_ID_FAILURE;
}

export interface BundIDSuccessAction {
  type: typeof BUND_ID_SUCCESS;
}

export interface SetBundIDNumberAction {
  type: typeof SET_BUND_ID_NUMBER;
  bundID_number: string;
}

export interface SetBundIDDataAction {
  type: typeof SET_BUND_ID_DATA;
  bundID: BundIDData;
}

export interface SetBundIDRequestDataAction {
  type: typeof SET_BUND_ID_REQUEST_DATA;
  bundID: BundIDRequestData;
}

export interface LoadBundIDDataAction {
  type: typeof LOAD_BUND_ID_DATA_SUCCESS;
  bundID: string;
}

export type BundIDActionTypes =
  | ClearBundIDAction
  | BundIDRequestAction
  | BundIDFailureAction
  | BundIDSuccessAction
  | LoadBundIDDataAction
  | SetBundIDDataAction
  | SetBundIDNumberAction
  | SetBundIDRequestDataAction;
