import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { billingAddressService } from "../../api";
import {
  BILLING_ADDRESS_CLEAR,
  BILLING_ADDRESS_FAILURE,
  BILLING_ADDRESS_REQUEST,
  BillingAddressActionTypes,
  BillingAddressClearAction,
  BillingAddressFailureAction,
  BillingAddressRequestAction,
  RELOAD_BILLING_ADDRESS,
  ReloadBillingAddressAction,
  SET_BILLING_ADDRESS,
  SET_BILLING_ADDRESS_FOR_LINKED_USER,
  SET_COMPANY_BILLING_UPLOAD_FILE,
  SET_PAYMENT_METHOD,
  SET_PAYPAL_DATA,
  SetBillingAddressAction,
  SetBillingAddressForLinkedUserAction,
  SetBillingPaymentAction,
  SetCompanyBillingUploadFile,
  SetPaypalDataAction,
  SET_INVOICE_NUMBER,
  SET_INVOICE_DATA,
  SetBillingInvoiceNumberAction,
  SetBillingInvoiceDataAction,
  SetBillingOrderDataAction,
  SetCardDataAction,
  SET_CARD_DATA,
  SET_ORDER_DATA,
} from "./types";
import {
  BillingAddressData,
  CrudBillingAddressData,
  UserBillingAddressDto,
} from "../../models/billingAddressData";
import { enqueueSnackbar } from "notistack";
import { InvoiceData } from "../../models/invoiceData";
import { ReducedOrderData } from "../../models/orderData";
import { getDynamicTranslation } from "../../components/core/localization/localizationUtils";

export function getBillingAddresses() {
  return (dispatch: Dispatch<BillingAddressActionTypes>) => {
    dispatch(billingAddressRequest());
    billingAddressService
      .getBillingAddresses()
      .then((response) => {
        dispatch(setBillingAddress(response));
      })
      .catch((err: AxiosError) => {
        dispatch(billingAddressFailure());
      });
  };
}

export function getBillingAddressesForUser(userId: number) {
  return (dispatch: Dispatch<BillingAddressActionTypes>) => {
    dispatch(billingAddressRequest());
    billingAddressService
      .getBillingAddressesForUser(userId)
      .then((response) => {
        dispatch(setBillingAddressForLinkedUser(response, userId));
      })
      .catch((err: AxiosError) => {
        dispatch(billingAddressFailure());
      });
  };
}

export function createBillingAddress(billingAddress: BillingAddressData) {
  return (dispatch: Dispatch<BillingAddressActionTypes>) => {
    dispatch(billingAddressRequest());
    billingAddressService
      .createBillingAddress(billingAddress)
      .then((response) => {
        dispatch(reloadBillingAddress());
        enqueueSnackbar(
          getDynamicTranslation("profile.billingAddressSetSuccess", "snackbars"),
          {
            variant: "success",
          }
        );
      })
      .catch((err: AxiosError) => {
        dispatch(billingAddressFailure());
        enqueueSnackbar(
          getDynamicTranslation("profile.billingAddressSetError", "snackbars"),
          {
            variant: "error",
          }
        );
      });
  };
}

export function editBillingAddress(
  billingAddressId: number,
  billingAddress: BillingAddressData
) {
  return (dispatch: Dispatch<BillingAddressActionTypes>) => {
    dispatch(billingAddressRequest());
    billingAddressService
      .editBillingAddress(billingAddressId, billingAddress)
      .then((response) => {
        dispatch(reloadBillingAddress());
        enqueueSnackbar(
          getDynamicTranslation("profile.billingAddressChangeSuccess", "snackbars"),
          {
            variant: "success",
          }
        );
      })
      .catch((err: AxiosError) => {
        dispatch(billingAddressFailure());
        enqueueSnackbar(
          getDynamicTranslation("profile.billingAddressChangeError", "snackbars"),
          {
            variant: "error",
          }
        );
      });
  };
}

export function billingAddressRequest(): BillingAddressRequestAction {
  return {
    type: BILLING_ADDRESS_REQUEST,
  };
}

export function billingAddressFailure(): BillingAddressFailureAction {
  return {
    type: BILLING_ADDRESS_FAILURE,
  };
}

export function setBillingAddress(
  data: CrudBillingAddressData[]
): SetBillingAddressAction {
  return {
    type: SET_BILLING_ADDRESS,
    data,
  };
}

export function setBillingAddressForLinkedUser(
  data: UserBillingAddressDto,
  userId: number
): SetBillingAddressForLinkedUserAction {
  return {
    type: SET_BILLING_ADDRESS_FOR_LINKED_USER,
    data: data,
    linkedUser: userId,
  };
}

export function reloadBillingAddress(): ReloadBillingAddressAction {
  return {
    type: RELOAD_BILLING_ADDRESS,
  };
}

export function billingAddressClear(): BillingAddressClearAction {
  return {
    type: BILLING_ADDRESS_CLEAR,
  };
}

export function setPaymentMethod(payment_method: string): SetBillingPaymentAction {
  return {
    type: SET_PAYMENT_METHOD,
    payment_method,
  };
}

export function setPaypalData(
  id: string,
  transaction_id: string,
  gross_amount: string,
  capture_id: string,
  currency_code: string
): SetPaypalDataAction {
  return {
    type: SET_PAYPAL_DATA,
    id,
    transaction_id,
    gross_amount,
    capture_id,
    currency_code,
  };
}

export function setCompanyBillingUploadFile(
  upload_file: File
): SetCompanyBillingUploadFile {
  return {
    upload_file,
    type: SET_COMPANY_BILLING_UPLOAD_FILE,
  };
}

export function setInvoiceNumber(invoice_number: string): SetBillingInvoiceNumberAction {
  return {
    type: SET_INVOICE_NUMBER,
    invoice_number,
  };
}

export function setInvoiceData(invoice: InvoiceData): SetBillingInvoiceDataAction {
  return {
    type: SET_INVOICE_DATA,
    invoice,
  };
}

export function setBillingOrderData(order: ReducedOrderData): SetBillingOrderDataAction {
  return {
    type: SET_ORDER_DATA,
    order,
  };
}

export function setCardData(
  card_number: string,
  card_expiration_date: string
): SetCardDataAction {
  return {
    type: SET_CARD_DATA,
    card_number,
    card_expiration_date,
  };
}
