/* eslint-disable camelcase */
import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { usersService } from "../../../api";
import { useDynamicYupValidations } from "../../../libs/yup-validations";
import {
  mapToSalutationTypeString,
  salutationValueOrDefault,
} from "../../../models/enums/salutationType.enum";
import { ProfileUpdateRequestData } from "../../../models/userData";
import { AppState } from "../../../redux";
import { getTargetGroups } from "../../../redux/target-groups/actions";
import { getCurrentLoggedInUser } from "../../../redux/users/actions";
import usePermission from "../../../services/usePermissions";
import CustomDatepicker from "../../forms/CustomDatepicker";
import CustomTextField from "../../forms/CustomTextField";
import CustomSelect from "../../forms/selects/CustomSelect";
import TargetGroupList from "../events/list/eventItem/TargetGroupList";
import { MembershipDocumentsSection } from "./membership-documents/MembershipDocumentsSection";
import SalutationSelect from "../../forms/selects/SalutationSelect";
import AlertConfirmDialog from "../../layout/dialog/AlertConfirmDialog";
import { useHistory } from "react-router-dom";
import routes from "../../../routing/routes";
import { loggingOut } from "../../../redux/auth/actions";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Utils } from "../../../services/utils";
import { FileObject } from "material-ui-dropzone";
import { MembershipDocumentRequestDto } from "../../../models/groupMembership";
import axios from "axios";
import CustomButton from "../../theming/CustomButton";
import styles from "../../../styles/custom-styles.module.scss";

export interface ProfileSettingsFormData {
  salutation: string;
  title: string;
  birthday: Date | null;
  firstName: string;
  lastName: string;
  zipCode: string;
  city: string;
  street: string;
  street_number?: string | null;
  country: string;
  phone: string;
  mobile: string;
  email: string;
  userId: string;
  lanr: string;
  bsnr: string;
  efn: string;
}

const formatDateToBirthday = (birthday: Date | null): string => {
  //! NOTE: CustomDatepicker return a date back like "2012-12-05T23:00:00.000Z" format
  return moment(birthday).format("DD.MM.YYYY");
};

const ProfileContactTab: React.FC = () => {
  const [featureFlags, setFeatureFlags] = useState({
    group_membership_is_enabled: false,
  });

  useEffect(() => {
    const fetchFeatureFlag = async () => {
      try {
        const response = await axios.get("/api/feature-flags");
        setFeatureFlags({
          group_membership_is_enabled: response.data.group_membership_is_enabled,
        });
      } catch (e) {
        enqueueSnackbar(
          t("backendResponses.error.captureNotSaved", { ns: "snackbars" }),
          {
            variant: "error",
          }
        );
      }
    };
    fetchFeatureFlag();
  }, []);

  const history = useHistory();
  const { t } = useTranslation(["common", "events", "snackbars"]);

  const currentDateLocal = new Date();
  const today = currentDateLocal.toDateString();

  const userData = useSelector((state: AppState) => state.user.currentUser);
  const targetGroups = useSelector((state: AppState) => state.targetGroups);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const initialGroupMembershipDocumentValues: MembershipDocumentRequestDto = {
    file: "",
    valid_until: today,
    document_has_no_expiry_date: false,
    document_type: "",
  };

  const [files, setFiles] = useState<FileObject[]>([]);
  const [groupMembershipDocument, setGroupMembershipDocument] =
    useState<MembershipDocumentRequestDto>(initialGroupMembershipDocumentValues);

  const { isStaff } = usePermission(userData?.user_type);

  useEffect(() => {
    if (!targetGroups.isLoading && !targetGroups.targetGroupsLoaded) {
      dispatch(getTargetGroups());
    }
  }, [targetGroups]);

  const alertConfirmText = () => {
    return (
      <div>
        <p>{t("profile.deleteProfile.header", { ns: "common" })}</p>
        <p>{t("profile.deleteProfile.body", { ns: "common" })}</p>
        <p>{t("profile.deleteProfile.footer", { ns: "common" })}</p>
      </div>
    );
  };

  const initialData: ProfileSettingsFormData = {
    salutation: mapToSalutationTypeString(userData?.salutation),
    title: "",
    birthday: null,
    firstName: "",
    lastName: "",
    zipCode: "",
    city: "",
    street: "",
    street_number: "",
    country: "",
    phone: "",
    mobile: "",
    email: "",
    userId: "",
    lanr: "",
    bsnr: "",
    efn: "",
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirm = async () => {
    try {
      await usersService.deactivateAccount();
      dispatch(loggingOut());
      history.push(routes.events);
      enqueueSnackbar(t("profile.accountDeleted", { ns: "snackbars" }), {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(t("profile.accountDeletionFail", { ns: "snackbars" }), {
        variant: "error",
      });
      handleCloseDialog();
    }
  };

  const {
    YupValidationBirthday,
    YupValidationFirstName,
    YupValidationLastName,
    YupValidationStreet,
    YupValidationStreetNumber,
    YupValidationZipCode,
    YupValidationCity,
    YupValidationEmail,
    YupValidationPhoneOptional,
  } = useDynamicYupValidations();

  const validationSchema = yup.object({
    birthday: YupValidationBirthday,
    firstName: YupValidationFirstName,
    lastName: YupValidationLastName,
    street: YupValidationStreet,
    street_number: YupValidationStreetNumber,
    city: YupValidationCity,
    zipCode: YupValidationZipCode,
    email: YupValidationEmail,
    phone: YupValidationPhoneOptional,
  });

  return (
    <>
      <Formik
        onSubmit={(values: ProfileSettingsFormData) => {
          const requestData: ProfileUpdateRequestData = {
            first_name: values.firstName,
            last_name: values.lastName,
            academic_title: values.title,
            salutation: salutationValueOrDefault(values.salutation),
            city: values.city,
            street: values.street,
            street_number: values.street_number,
            zip_code: values.zipCode,
            birthday: formatDateToBirthday(values.birthday),
            email: values.email,
            mobile: values.mobile,
            phone: values.phone,
          };
          usersService.updateProfile(requestData).then((result) => {
            if (result.status === 200) {
              enqueueSnackbar(t("profile.profileChanged", { ns: "snackbars" }), {
                variant: "success",
              });
              dispatch(getCurrentLoggedInUser());
            } else {
              enqueueSnackbar(
                t("profile.profileError", {
                  ns: "snackbars",
                  statusText: result.statusText,
                }),
                { variant: "error" }
              );
            }
          });
        }}
        enableReinitialize
        validateOnChange
        initialValues={
          userData
            ? ({
                salutation: userData?.salutation.toString(),
                title: userData?.academic_title || "",
                birthday: Utils.parseBirthdayWithEuFormat(userData.birthday),
                firstName: userData?.first_name,
                lastName: userData?.last_name,
                zipCode: userData?.address.zip_code,
                city: userData?.address.city,
                street: userData?.address.street,
                street_number: userData?.address.street_number,
                country: userData?.address.country,
                phone: userData?.phone,
                mobile: userData?.mobile,
                email: userData?.email,
                userId: userData?.username,
                lanr: userData?.lanr,
                bsnr: userData?.bsnr,
                efn: userData?.efn,
              } as ProfileSettingsFormData)
            : initialData
        }
        validationSchema={validationSchema}
      >
        {({ values, handleChange, isValid }) => (
          <Form>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography>
                  {t("profile.overviewProfile.title", { ns: "common" })}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <SalutationSelect name="salutation" value={values.salutation} required />
              </Grid>
              <Grid item xs={6} sm={3}>
                <CustomSelect
                  fullWidth
                  autoComplete="title"
                  name="title"
                  label={t("profile.overviewProfile.acadTitle", {
                    ns: "common",
                  })}
                  options={[
                    { key: "dr", value: "Dr.", label: "Dr." },
                    { key: "prof", value: "Prof.", label: "Prof." },
                  ]}
                  id="anrede"
                  labelId="anrede"
                  value={values.title ?? ""}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Field
                  as={CustomDatepicker}
                  fullWidth
                  autoComplete="birthday"
                  name="birthday"
                  id="birthday-profile"
                  label={t("profile.birthdate", { ns: "common" })}
                  value={values.birthday ?? ""}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  fullWidth
                  autoComplete="firstName"
                  name="firstName"
                  id="first-name-profile"
                  label={t("profile.firstName", { ns: "common" })}
                  value={values.firstName ?? ""}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  fullWidth
                  value={values.lastName ?? ""}
                  autoComplete="lastName"
                  name="lastName"
                  id="last-name-profile"
                  label={t("profile.lastName", { ns: "common" })}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={9}>
                <CustomTextField
                  fullWidth
                  autoComplete="street"
                  value={values.street ?? ""}
                  name="street"
                  id="street-profile"
                  label={t("profile.street", { ns: "common" })}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <CustomTextField
                  fullWidth
                  autoComplete="street_number"
                  value={values.street_number ?? ""}
                  name="street_number"
                  id="street-number-profile"
                  label={t("profile.streetNumber", { ns: "common" })}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <CustomTextField
                  fullWidth
                  autoComplete="zipCode"
                  value={values.zipCode ?? ""}
                  name="zipCode"
                  id="zip-code-profile"
                  label={t("profile.zipCode", { ns: "common" })}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={9}>
                <CustomTextField
                  fullWidth
                  value={values.city ?? ""}
                  autoComplete="city"
                  name="city"
                  id="city-profile"
                  label={t("profile.city", { ns: "common" })}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  fullWidth
                  value={values.email ?? ""}
                  type="email"
                  autoComplete="email"
                  name="email"
                  id="email-profile"
                  label={t("profile.mail", { ns: "common" })}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  fullWidth
                  value={values.phone ?? ""}
                  autoComplete="phone"
                  name="phone"
                  id="phone-number-profile"
                  label={t("profile.phoneNumber", { ns: "common" })}
                />
              </Grid>
              {userData?.efn ? (
                <Grid item xs={12} sm={6} md={3}>
                  <CustomTextField
                    fullWidth
                    InputProps={{ readOnly: true }}
                    autoComplete="EFN"
                    name="efn"
                    label="EFN"
                  />
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <CustomButton
                      id="delete-profile"
                      customColor={styles.red}
                      textColor="#fff"
                      hoverColor={styles["red-dark"]}
                      onClick={handleOpenDialog}
                    >
                      {t("profile.deleteProfile.title", { ns: "common" })}
                    </CustomButton>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={!isValid}
                      id="save-profile"
                      color="primary"
                      variant="contained"
                      type="submit"
                    >
                      {t("save", { ns: "common" })}
                    </Button>
                  </Grid>
                </Grid>
                <AlertConfirmDialog
                  title=""
                  content={alertConfirmText()}
                  open={dialogOpen}
                  onClose={handleCloseDialog}
                  onConfirm={handleConfirm}
                  confirm={t("profile.deleteProfile.title", { ns: "common" })}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {featureFlags.group_membership_is_enabled ? (
        <>
          {!isStaff() && (
            <Card variant="outlined" style={{ margin: "2em 0" }}>
              <CardContent>
                <h3>{t("targetGroup.overview", { ns: "events" })}</h3>
                <TargetGroupList
                  targetGroups={{
                    participation_types: userData?.participation_types ?? [],
                  }}
                />
              </CardContent>
            </Card>
          )}

          <MembershipDocumentsSection
            groupMembershipDocument={groupMembershipDocument}
            setGroupMembershipDocument={setGroupMembershipDocument}
            files={files}
            setFiles={setFiles}
          />
        </>
      ) : null}
    </>
  );
};

export default ProfileContactTab;
