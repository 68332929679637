/* eslint-disable camelcase */
/* eslint-disable react/no-multi-comp */
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import { Form, Formik, useFormikContext } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import { UserType } from "../../../models/enums/userType.enum";
import { EventFilterData } from "../../../models/eventData";
import { AppState } from "../../../redux";
import { getAllEvents, setFilterData } from "../../../redux/events/actions";
import { getLinkedAccounts } from "../../../redux/linkAccount/actions";
import usePermission from "../../../services/usePermissions";
import Loader from "../../../components/theming/loader/Loader";
import CreateTrainingForm from "../../../components/core/events/list/CreateTrainingForm";
import EventFilterForm from "../../../components/core/events/list/EventFilterForm";
import "./EventListPage.scss";
import PageItems from "../../../components/core/events/list/PageItems";
import { useTranslation } from "react-i18next";
import TermsAgreedModal from "../../../components/forms/TermsAgreedModal";
import DOMPurify from "dompurify";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "../../../models/enums/salutationType.enum";

export const EventListPage: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["events", "customer", "snackbars"]);

  const eventState = useSelector((state: AppState) => state.event);
  const auth = useSelector((state: AppState) => state.auth);
  const linkAccount = useSelector((state: AppState) => state.linkAccount);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const eventFilterData = eventState.filterData;
  const [openTermsDialog, setOpenTermsDialog] = useState(false);
  const { isStaff } = usePermission(currentUser?.user_type);

  const loadMore = useCallback(
    (filters: EventFilterData) => {
      if (eventState.hasMoreItemsToLoad && !eventState.isLoading) {
        dispatch(getAllEvents(eventState.currentPage + 1, filters));
      }
    },
    [
      dispatch,
      eventState.currentPage,
      eventState.hasMoreItemsToLoad,
      eventState.isLoading,
    ]
  );

  useEffect(() => {
    if (currentUser) {
      if (!currentUser.terms_agreed_at) {
        setOpenTermsDialog(true);
      }
      if (
        !linkAccount.linkAccountListLoaded &&
        currentUser.user_type === UserType.Participant
      ) {
        dispatch(getLinkedAccounts());
      }
    }
  }, [currentUser, linkAccount.linkAccountListLoaded, dispatch]);

  useEffect(() => {
    if (
      !eventState.isLoading &&
      eventState.currentPage === 0 &&
      eventState.hasMoreItemsToLoad
    ) {
      loadMore(eventState.filterData);
    }
  }, [
    eventState.isLoading,
    eventState.filterData,
    eventState.currentPage,
    eventState.hasMoreItemsToLoad,
    loadMore,
  ]);

  const ChangeEventFilterForm = () => {
    const { values, initialValues } = useFormikContext<EventFilterData>();
    useEffect(() => {
      if (
        JSON.stringify(values) !== JSON.stringify(eventState.filterData) &&
        JSON.stringify(values) !== JSON.stringify(initialValues) &&
        !eventState.isLoading
      ) {
        dispatch(setFilterData(values));
      }
    }, [initialValues, values]);

    return null;
  };
  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await axios.get("/api/meta-data");
        setMetaData({
          welcome_text_german: response.data.welcome_text_german,
          welcome_text_english: response.data.welcome_text_english,
        });
      } catch (error) {
        enqueueSnackbar(
          t("backendResponses.error.captureNotSaved", { ns: "snackbars" }),
          {
            variant: "error",
          }
        );
      } finally {
        setMetaDataIsLoading(false);
      }
    };
    fetchMetaData();
  }, []);

  const handleAgree = () => {
    setOpenTermsDialog(false);
    window.location.reload();
  };
  // Local state for metadata and loading status
  const [metaData, setMetaData] = useState({
    welcome_text_german: "",
    welcome_text_english: "",
  });

  const [metaDataIsLoading, setMetaDataIsLoading] = useState(true);

  const welcomeText =
    getCurrentLanguage() === "de" || getCurrentLanguage() === "de-DE"
      ? metaData.welcome_text_german
      : metaData.welcome_text_english;

  const createSafeHTML = (htmlString: string) => {
    return { __html: DOMPurify.sanitize(htmlString) };
  };
  // Show loader if metadata is still loading
  const welcomeTextContent = metaDataIsLoading ? (
    <CircularProgress />
  ) : (
    <Box
      className="welcomeText-content"
      style={{ padding: 0, margin: 0, overflowWrap: "anywhere" }}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <p dangerouslySetInnerHTML={createSafeHTML(welcomeText)} />
    </Box>
  );

  return (
    <>
      <Grid container spacing={4} style={{ marginBottom: "0rem" }}>
        <Grid item sm={10}>
          <Typography>{welcomeTextContent}</Typography>
        </Grid>
        {auth.loggedIn && isStaff() ? <CreateTrainingForm /> : null}
      </Grid>
      <Formik onSubmit={() => {}} enableReinitialize initialValues={eventFilterData}>
        {({ values }) => (
          <>
            <ChangeEventFilterForm />
            <Form style={{ marginBottom: "1rem" }}>
              <EventFilterForm />
            </Form>
            <InfiniteScroll
              pageStart={0}
              loadMore={() => {
                loadMore(values);
              }}
              initialLoad={false}
              hasMore={Boolean(eventState.hasMoreItemsToLoad) && !eventState.isLoading}
              loader={
                <React.Fragment key={0}>
                  <Loader />
                </React.Fragment>
              }
            >
              {eventState.eventList.length === 0 ||
              (eventState.currentPage === 0 && eventState.isLoading) ? (
                eventState.isLoading && <Loader />
              ) : (
                <>
                  <PageItems pages={eventState.eventList} />
                  {Boolean(eventState.isLoading) && <Loader />}
                </>
              )}
            </InfiniteScroll>
          </>
        )}
      </Formik>
      <TermsAgreedModal
        open={openTermsDialog}
        onAgree={handleAgree}
        currentUser={currentUser}
      />
    </>
  );
};

export default EventListPage;
