/* eslint-disable react-hooks/exhaustive-deps, react/jsx-max-depth */
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUserData } from "../../../../redux/users/actions";
import { AppState } from "../../../../redux";
import Loader from "../../../theming/loader/Loader";
import { useTranslation } from "react-i18next";
import { getGroupMembershipDocumentTypes } from "../../../../redux/groupMembershipDocumentType/actions";
import { SCHOOL_STUDENT_CARD_LABEL } from "../../../../constants";
import { MembershipDocumentUploadArea } from "./MembershipDocumentUploadArea";
import { MembershipDocumentSelection } from "./MembershipDocumentSelection";
import { MembershipDocumentRequestDto } from "../../../../models/groupMembership";
import { FileObject } from "material-ui-dropzone";
import DOMPurify from "dompurify";
import CircularProgress from "@material-ui/core/CircularProgress";
import i18n from "i18next";
import { metaDataService } from "../../../../api";
import { getCurrentLanguage } from "../../localization/localizationUtils";

interface AddMembershipDocumentProps {
  isRegistrationPage: boolean;
  groupMembershipDocument: MembershipDocumentRequestDto;
  setGroupMembershipDocument: Dispatch<SetStateAction<MembershipDocumentRequestDto>>;
  files: FileObject[];
  setFiles: Dispatch<SetStateAction<FileObject[]>>;
}

const currentDateLocal = new Date();

export const AddMembershipDocumentForm: React.FC<AddMembershipDocumentProps> = (
  props
) => {
  const currentUser = useSelector((state: AppState) => state.user.currentUser);

  const {
    isRegistrationPage,
    groupMembershipDocument,
    setGroupMembershipDocument,
    files,
    setFiles,
  } = props;

  let user = currentUser;
  if (isRegistrationPage) {
    user = undefined;
  }

  const [documentTypeIsSelected, setDocumentTypeIsSelected] = useState<boolean>(false);

  const [userIsLoading, setUserIsLoading] = useState<boolean>(false);

  const maxAge = process.env.REACT_APP_MAX_AGE_SCHOOL_STUDENT_CARD;
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "customer", "snackbars", "validations"]);

  const groupMembershipDocumentTypes = useSelector(
    (state: AppState) => state.groupMemberShipDocumentTypes
  );

  let groupMembershipDocumentTypeOptions =
    groupMembershipDocumentTypes.groupMembershipDocumentTypes.map((documentType) => {
      const localeLabel = `label_${getCurrentLanguage()}` as keyof typeof documentType;
      return {
        key: documentType.id,
        value: documentType.id,
        label: "" + documentType[localeLabel] || documentType.label,
      };
    });

  const [age, setAge] = useState<number | undefined>(undefined);
  /* TODO Refactor to avoid workaround and multiple rerendering */

  useEffect(() => {
    if (!isRegistrationPage && !currentUser) {
      getCurrentUserData(dispatch);
    }
  }, [userIsLoading]);

  useEffect(() => {
    if (!isRegistrationPage && userIsLoading) {
      getCurrentUserData(dispatch);
      user = currentUser;
      setUserIsLoading(false);
    }
  }, [currentUser]);

  useEffect(() => {
    if (
      !groupMembershipDocumentTypes.isLoading &&
      !groupMembershipDocumentTypes.groupMembershipDocumentTypesLoaded
    ) {
      dispatch(getGroupMembershipDocumentTypes());
    }
  }, [groupMembershipDocumentTypes]);

  useEffect(() => {
    if (currentUser?.birthday) {
      const utcToday = new Date(
        currentDateLocal.getTime() + currentDateLocal.getTimezoneOffset() * 60000
      );
      const birthdayParts = currentUser.birthday.split(".");
      const birthDate = new Date(
        parseInt(birthdayParts[2]),
        parseInt(birthdayParts[1]) - 1,
        parseInt(birthdayParts[0])
      );
      const calcAge =
        utcToday.getFullYear() -
        birthDate.getFullYear() -
        (utcToday.getMonth() < birthDate.getMonth() ||
        (utcToday.getMonth() === birthDate.getMonth() &&
          utcToday.getDate() < birthDate.getDate())
          ? 1
          : 0);

      setAge(calcAge);
    }
  }, [currentUser]);

  // Remove school student card when person is older than maxAge (18)
  if (!age || !maxAge || age >= parseInt(maxAge)) {
    const newArray = groupMembershipDocumentTypeOptions.filter(
      (item) => item.label !== SCHOOL_STUDENT_CARD_LABEL
    );
    groupMembershipDocumentTypeOptions = newArray;
  }

  if (user === undefined && !isRegistrationPage) {
    return <Loader loadingText={t("waitNote", { ns: "common" })} />;
  }

  const [german, setGerman] = useState("");
  const [english, setEnglish] = useState("");
  const [metaDataIsLoading, setMetaDataIsLoading] = useState(true);

  useEffect(() => {
    metaDataService
      .getMetaData()
      .then((metaData) => {
        setGerman(metaData?.data?.group_membership_info_text_german);
        setEnglish(metaData?.data?.group_membership_info_text_english);
        setMetaDataIsLoading(false);
      })
      .catch(() => {
        setMetaDataIsLoading(false);
      });
  }, []);

  const infoText = i18n.language === "de" ? german : english;
  const createSafeHTML = (htmlString: string) => {
    return { __html: DOMPurify.sanitize(htmlString) };
  };

  const infoContent = metaDataIsLoading ? (
    <CircularProgress />
  ) : (
    <Box
      className="info-content"
      style={{ padding: 0, margin: 0, overflowWrap: "anywhere" }}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <p dangerouslySetInnerHTML={createSafeHTML(infoText)} />
    </Box>
  );

  return german || english ? (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography>{infoContent}</Typography>
        </Grid>
      </Grid>

      <>
        <Grid item xs={12}>
          <MembershipDocumentSelection
            documentTypeIsSelected={documentTypeIsSelected}
            setDocumentTypeIsSelected={setDocumentTypeIsSelected}
            isRegistrationPage={isRegistrationPage}
            groupMembershipDocument={groupMembershipDocument}
            setGroupMembershipDocument={setGroupMembershipDocument}
            files={files}
            setFiles={setFiles}
          />
        </Grid>
        <Grid item xs={12}>
          {documentTypeIsSelected ? (
            <MembershipDocumentUploadArea files={files} setFiles={setFiles} />
          ) : null}
        </Grid>
      </>
    </>
  ) : null;
};
