import { StripeData } from "../../models/stripeData";

export interface StripeState {
  stripeData: StripeData;
  isLoading: boolean;
  stripeDataLoaded: boolean;
  stripeFailure: boolean;
}

export const CLEAR_STRIPE = "CLEAR_STRIPE";
export const STRIPE_REQUEST = "STRIPE_REQUEST";
export const STRIPE_FAILURE = "STRIPE_FAILURE";
export const STRIPE_SUCCESS = "STRIPE_SUCCESS";
export const LOAD_STRIPE_DATA_SUCCESS = "LOAD_STRIPE_DATA_SUCCESS";
export const SET_STRIPE_NUMBER = "SET_STRIPE_NUMBER";
export const SET_STRIPE_DATA = "SET_STRIPE_DATA";

export interface ClearStripeAction {
  type: typeof CLEAR_STRIPE;
}

export interface StripeRequestAction {
  type: typeof STRIPE_REQUEST;
}

export interface StripeFailureAction {
  type: typeof STRIPE_FAILURE;
}

export interface StripeSuccessAction {
  type: typeof STRIPE_SUCCESS;
}

export interface SetStripeNumberAction {
  type: typeof SET_STRIPE_NUMBER;
  stripe_number: string;
}

export interface SetStripeDataAction {
  type: typeof SET_STRIPE_DATA;
  stripe: StripeData;
}

export interface LoadStripeDataAction {
  type: typeof LOAD_STRIPE_DATA_SUCCESS;
  stripe: string;
}

export type StripeActionTypes =
  | ClearStripeAction
  | StripeRequestAction
  | StripeFailureAction
  | StripeSuccessAction
  | LoadStripeDataAction
  | SetStripeDataAction
  | SetStripeNumberAction;
