import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { OrganizationSettingsData } from "../../../../../models/organizationSettingsData";
import React, { useEffect } from "react";
import HeadingLumos from "../../../../theming/HeadingLumos";
import { Form, Formik } from "formik";
import { Box, Button, Grid, Switch } from "@material-ui/core";
import {
  loadOrganizationSettings,
  setOrganizationSettings,
} from "../../../../../redux/organizationSettings/actions";
import Loader from "../../../../theming/loader/Loader";
import { AppState } from "../../../../../redux";

interface OrganizationSettingsForm {
  organizationId: string;
}

/**
 * Component for managing organization settings.
 * @param organizationId - The id of the organization.
 */
const OrganizationSettingsManagement: React.FC<OrganizationSettingsForm> = (props) => {
  const { organizationId } = props;
  const { t } = useTranslation(["organization", "snackbars"]);
  const dispatch = useDispatch();

  const organizationSettingsData = useSelector(
    (state: AppState) => state.organizationSettings
  );

  useEffect(() => {
    dispatch(loadOrganizationSettings(organizationId));
  }, [dispatch, organizationId]);

  const handleChange =
    (setFieldValue: any, fieldName: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue(fieldName, event.target.checked);
    };

  const handleSubmit = (values: OrganizationSettingsData) => {
    dispatch(
      setOrganizationSettings(
        organizationSettingsData?.organizationSettingsData?.id,
        values
      )
    );
  };
  return !organizationSettingsData?.organizationSettingsDataLoaded ? (
    <Loader />
  ) : (
    <>
      <HeadingLumos>{t("orgaSettings", { ns: "organization" })}</HeadingLumos>
      <Formik
        enableReinitialize
        initialValues={{
          id: organizationSettingsData?.organizationSettingsData?.id || "",
          // eslint-disable-next-line camelcase
          skip_guardians:
            organizationSettingsData?.organizationSettingsData?.skip_guardians || false,
        }}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12}>
                <Box>
                  <h3>{t("guardianSubheading", { ns: "organization" })}</h3>
                </Box>
                <Box className="confirm-condition-box">
                  <span>{t("skipGuardian", { ns: "organization" })}</span>
                  <Switch
                    color="primary"
                    checked={values.skip_guardians}
                    onChange={handleChange(setFieldValue, "skip_guardians")}
                    name="skip_guardians"
                    inputProps={{ "aria-label": "Skip Guardians checkbox" }}
                  />
                </Box>
              </Grid>
            </Grid>

            <Box style={{ marginTop: "1rem" }}>
              <Button
                id="save-orga-settings"
                type="submit"
                size="large"
                variant="contained"
                color="primary"
              >
                {t("save", { ns: "common" })}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default OrganizationSettingsManagement;
