import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ExpandMore } from "@material-ui/icons";
import HeadingLumos from "../../../../theming/HeadingLumos";
import Loader from "../../../../theming/loader/Loader";
import MembershipsList from "./MembershipsList";
import AccountInvitationList from "./AccountInvitationList";
import { useAccordionState } from "../../../../../hooks/useAccordionState";
import { MembershipState } from "../../../../../redux/organizationMembership/types";
import { AccountInvitationState } from "../../../../../redux/accountInvitation/types";

/**
 * @interface MembershipsManagementProps
 */
interface MembershipsManagementProps {
  organizationId: string;
  accountInvitations: AccountInvitationState;
  memberships: MembershipState;
}

/**
 * Component for managing memberships and account invitations within an organization.
 * @param organizationId The ID of the organization
 */
export const MembershipsManagement = ({
  organizationId,
  accountInvitations,
  memberships,
}: MembershipsManagementProps) => {
  const { t } = useTranslation(["common", "organization"]);

  const [expanded, handleChange] = useAccordionState("expandedPanel");

  return (
    <Box>
      <HeadingLumos>
        {t("membership.membersOverview", { ns: "organization" })}
      </HeadingLumos>
      {memberships?.isLoading || accountInvitations.isLoading ? (
        <Loader />
      ) : (
        <>
          <Accordion
            expanded={expanded === "memberships"}
            onChange={handleChange("memberships")}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              {t("membership.memberships", {
                ns: "organization",
                number: memberships?.memberships?.length || 0,
              })}
            </AccordionSummary>
            <AccordionDetails>
              {memberships?.memberships?.length > 0 ? (
                <MembershipsList
                  memberships={memberships.memberships}
                  organizationId={organizationId}
                />
              ) : (
                <Typography>
                  {t("membership.noMemberships", { ns: "organization" })}
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "invitations"}
            onChange={handleChange("invitations")}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              {t("accountInvitation.accountInvitations", {
                ns: "organization",
                number: accountInvitations?.accountInvitations?.length || 0,
              })}
            </AccordionSummary>
            <AccordionDetails>
              {accountInvitations?.accountInvitations?.length > 0 ? (
                <AccountInvitationList
                  accountInvitations={accountInvitations.accountInvitations}
                  organizationId={organizationId}
                />
              ) : (
                <Typography>
                  {t("accountInvitation.noInvitations", {
                    ns: "organization",
                  })}
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </Box>
  );
};
