import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { UserType } from "../models/enums/userType.enum";
import { AppState } from "../redux";
import routes from "./routes";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

interface AuthRouteProps {
  children: ReactNode;
  path?: string;
  exact?: boolean;
}

// A wrapper for <Route> that redirects to the home page if the user tries to manually route to auth pages
// views that should only be shown if the user is not logged in like login and registration screen
const AuthRoute: React.FC<AuthRouteProps> = ({ children, ...props }) => {
  const auth = useSelector((state: AppState) => state.auth);
  const user = useSelector((state: AppState) => state.user);
  const redirectRoute = useRef<string>(routes.events);
  const redirectQueryParams = useRef<string>("");

  if (auth.loggedInFrom !== routes.login) {
    redirectRoute.current = auth.loggedInFrom;
    redirectQueryParams.current = auth.queryParams;
  }

  const [afterLoginPage, setAfterLoginPage] = useState<string>(routes.events);
  const [metaDataIsLoading, setMetaDataIsLoading] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await axios.get("/api/meta-data");

        if (response?.data?.redirect_after_login_page) {
          const metaKey = response.data.redirect_after_login_page as keyof typeof routes;
          const mappedRoute = routes[metaKey] || routes.events;
          setAfterLoginPage(mappedRoute);
        } else {
          setAfterLoginPage(routes.events); // Fallback
        }
      } catch (error) {
        enqueueSnackbar(
          t("backendResponses.error.captureNotSaved", { ns: "snackbars" }),
          {
            variant: "error",
          }
        );
      } finally {
        setMetaDataIsLoading(false);
      }
    };
    fetchMetaData();
  }, [user, auth]);

  return (
    <Route
      {...props}
      render={({ location }) =>
        auth.loggedIn &&
        !auth.isLoading &&
        !metaDataIsLoading &&
        user?.currentUser?.user_type ? (
          <Redirect
            to={{
              pathname:
                user.currentUser?.user_type === UserType.Admin
                  ? routes.admin
                  : afterLoginPage,
              search: redirectQueryParams.current,
              state: { from: location },
            }}
          />
        ) : metaDataIsLoading ? (
          <div>Lädt...</div>
        ) : (
          children
        )
      }
    />
  );
};

export default AuthRoute;
