import React, { useEffect } from "react";
import { AppState } from "../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { createStripe } from "../../../redux/stripe/actions";
import routes from "../../../routing/routes";
import { useHistory } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import Loader from "../../../components/theming/loader/Loader";
import HeadingLumos from "../../../components/theming/HeadingLumos";

export const StripePage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(["booking"]);

  const orderState = useSelector((appState: AppState) => appState.order);
  const stripeState = useSelector((appState: AppState) => appState.stripe);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

  useEffect(() => {
    if (
      !stripeState.isLoading &&
      !stripeState.stripeDataLoaded &&
      !stripeState.stripeFailure
    ) {
      dispatch(createStripe(orderState.orderData));
    }
  }, [stripeState.isLoading, stripeState.stripeDataLoaded]);

  if (orderState.isSending || stripeState.isLoading) {
    return <Loader />;
  }

  if (stripeState.stripeDataLoaded && stripeState.stripeData.client_secret) {
    const clientSecret = stripeState.stripeData.client_secret;

    const options = { clientSecret };

    return (
      <>
        <HeadingLumos>{t("stripeTitle", { ns: "booking" })}</HeadingLumos>
        <div id="checkout">
          <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </div>
      </>
    );
  }

  if (stripeState.stripeFailure === true) {
    setTimeout(() => history.push(routes.checkout), 3000);
    return <Loader />;
  }

  return null;
};
