/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-var-requires */
import { useEffect, useState } from "react";
import { Box, Grid, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import routes from "../../../../../routing/routes";
import HeadingLumos from "../../../../theming/HeadingLumos";
import example1 from "../../../../../assets/video_authentisierung/authentifizierung-1.png";
import { BundIDModal } from "../bundID/BundIDModal";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux";
import Loader from "../../../../theming/loader/Loader";
import { getFeatureFlags } from "../../../../../redux/featureFlagging/actions";
import { useDispatch } from "react-redux";
import "./AuthOptions.scss";

export function AuthOptions() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(["authOptions"]);

  const logo = require(
    `../../../../../assets/icons/bundid/Logo_standard_Sicherheitszone.png`
  );

  const featureFlags = useSelector((state: AppState) => state.featureFlagging);

  const [open, setOpen] = useState(false);

  const { id, event_participation_id } = useParams<{
    id: string;
    event_participation_id: string;
  }>();

  useEffect(() => {}, [open]);

  useEffect(() => {
    if (!featureFlags.isLoading && !featureFlags.featureFlagsLoaded) {
      dispatch(getFeatureFlags());
    }
  }, [featureFlags]);

  function openPhotoAuthentication() {
    history.push(
      routes.elearning_photo_authentication_id
        .replace(":id", id)
        .replace(":event_participation_id", event_participation_id)
    );
  }

  if (featureFlags.isLoading && !featureFlags.featureFlagsLoaded) {
    return <Loader />;
  }

  if (!featureFlags.featureFlags.bund_id_authentication_is_enabled) {
    openPhotoAuthentication();
  }

  return (
    <>
      <Grid item xs={12}>
        <HeadingLumos>{t("header", { ns: "authOptions" })}</HeadingLumos>
        <span>{t("note", { ns: "authOptions" })}</span>
      </Grid>

      <div id="auth-options-container">
        {featureFlags.featureFlags.foto_id_is_enabled ? (
          <Box className="box-style">
            <img src={example1} alt="lumos-photo-auth" />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                openPhotoAuthentication();
              }}
              fullWidth
            >
              {t("optionPhotoAuth", { ns: "authOptions" })}
            </Button>
          </Box>
        ) : (
          ""
        )}
        <Box className="box-style">
          <img src={logo} alt="bundID-logo" />
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setOpen(!open);
            }}
            fullWidth
          >
            BundID
          </Button>
        </Box>
      </div>
      <BundIDModal
        open={open}
        setOpen={setOpen}
        eventParticipation={event_participation_id}
      />
    </>
  );
}
