/* eslint-disable */
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Hidden,
  Tooltip,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { EventType } from "../../../models/enums/eventType.enum";
import { ParticipationStatus } from "../../../models/enums/participationStatus.enum";
import { UserType } from "../../../models/enums/userType.enum";
import { AppState } from "../../../redux";
import {
  cancelMyEvent,
  clickedEvaluationLink,
  getMyEvent,
  removeFromWaitingList,
} from "../../../redux/bookings/actions";
import { showDialog } from "../../../redux/dialogs/actions";
import { getDocumentsByEvent, setDocumentsList } from "../../../redux/documents/actions";
import { Utils } from "../../../services/utils";
import useValidId from "../../../components/hooks/useValidId";
import routes from "../../../routing/routes";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import { isEventOver } from "../../../components/core/events/shared/checkEventIsOver";
import "./UserEventsPage.scss";
import CalendarLink from "../../../components/core/events/my-events/details/CalendarLink";
import EventDescription from "../../../components/core/events/my-events/details/EventDescription";
import EventDocuments from "../../../components/core/events/my-events/details/EventDocuments";
import { NIL as NIL_UUID } from "uuid";
import Loader from "../../../components/theming/loader/Loader";
import CancelEventButton from "../../../components/core/events/my-events/CancelMyEventButton";

export const UserEventDetailsPage: React.FC = () => {
  let { id } = useParams<{ id: string }>();
  const { t } = useTranslation(["common", "events", "snackbars"]);
  const dispatch = useDispatch();
  const bookings = useSelector((state: AppState) => state.booking);
  const documents = useSelector((state: AppState) => state.documents);
  const { isValidId } = useValidId(routes.my_events_details_event_id, id);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);

  useEffect(() => {
    // additionaly check if id is 0 because it needs to be initialized
    if (
      isEventOver(bookings.myEvent.event.end) &&
      bookings.myEvent.status === ParticipationStatus.Participated &&
      bookings.myEvent.event.evaluation_link &&
      !bookings.myEvent.user_has_clicked_evaluation_link &&
      bookings.myEvent.id !== NIL_UUID
    ) {
      dispatch(
        showDialog({
          title: "Bewertung der Veranstaltung",
          message: (
            <>
              Möchten Sie sich einen kurzen Moment Zeit nehmen, um die Organisation und
              Durchführung der Veranstaltung zu bewerten?
            </>
          ),
          confirmButtonText: "Zur Evaluation",
          action: () => {
            window.open(bookings.myEvent.event.evaluation_link);
            dispatch(clickedEvaluationLink(bookings.myEvent.id, true, false));
          },
        })
      );
    }
  }, [bookings.myEvent, dispatch]);

  const eventId = id;

  useEffect(() => {
    if (isValidId && !bookings.isLoading && !bookings.myEventLoaded) {
      dispatch(getMyEvent(eventId));
    }
  }, [id, isValidId, bookings]);

  useEffect(() => {
    if (
      [
        ParticipationStatus.Booked,
        ParticipationStatus.Participated,
        ParticipationStatus.Cancelled,
      ].includes(bookings.myEvent.status)
    ) {
      dispatch(getDocumentsByEvent(bookings.myEvent.event.id, false));
    } else {
      dispatch(setDocumentsList([]));
    }
  }, [bookings.myEvent.event.id, bookings.myEvent.status, dispatch]);

  const eventTitle =
    bookings.myEvent.event.title.length > 0
      ? bookings.myEvent.event.title
      : "(Kein Titel)";

  if (bookings.isLoading && !bookings.myEventLoaded) {
    return <Loader />;
  }
  return (
    <>
      <HeadingLumos>{eventTitle}</HeadingLumos>
      <Grid container style={{ marginTop: "1.25rem", padding: ".5rem" }} spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              sm={6}
              className={
                "myevents-details-property-label myevents-details-property-container"
              }
            >
              {t("course.courseNumber", { ns: "common" })}
            </Grid>
            <Grid item xs={12} sm={6} className={"myevents-details-property-container"}>
              {bookings.myEvent.event.public_id}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className={
                "myevents-details-property-label myevents-details-property-container"
              }
            >
              {t("price", { ns: "events" })}
            </Grid>
            <Grid item xs={12} sm={6} className={"myevents-details-property-container"}>
              {Utils.formatPrice(bookings.myEvent.event.price)}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className={
                "myevents-details-property-label myevents-details-property-container"
              }
            >
              {t("points", { ns: "events" })}
            </Grid>
            <Grid item xs={12} sm={6} className={"myevents-details-property-container"}>
              {bookings.myEvent.event.score}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className={
                "myevents-details-property-label myevents-details-property-container"
              }
            >
              {t("speaker", { ns: "events" })}
            </Grid>
            {
              // TODO: Error in loading speakers
              <Grid
                item
                xs={12}
                sm={6}
                className={"myevents-details-property-container"}
              >
                {bookings.myEvent.event.speakers &&
                  bookings.myEvent.event.speakers.map((speaker: any, index: number) => {
                    const speakerName = `${speaker.representation}`;
                    return (
                      <React.Fragment key={`speaker-${index}`}>
                        {speakerName}
                        <br />
                      </React.Fragment>
                    );
                  })}
              </Grid>
            }
          </Grid>
        </Grid>

        <Hidden mdUp>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Hidden>

        <Grid item xs={12} md={5}>
          <Grid container spacing={0}>
            <Grid
              item
              xs={6}
              className={
                "myevents-details-property-label myevents-details-property-container"
              }
            >
              {t("start", { ns: "events" })}
            </Grid>
            <Grid item xs={12} sm={6} className={"myevents-details-property-container"}>
              {new Date(bookings.myEvent.event.begin).toLocaleDateString()} (
              {new Date(bookings.myEvent.event.begin).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
              )
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className={
                "myevents-details-property-label myevents-details-property-container"
              }
            >
              {t("end", { ns: "events" })}
            </Grid>
            <Grid item xs={12} sm={6} className={"myevents-details-property-container"}>
              {new Date(bookings.myEvent.event.end).toLocaleDateString()} (
              {new Date(bookings.myEvent.event.end).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
              )
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className={
                "myevents-details-property-label myevents-details-property-container"
              }
            >
              {t("eventLocation.title", { ns: "events" })}
            </Grid>
            <Grid item xs={12} sm={6} className={"myevents-details-property-container"}>
              {bookings.myEvent.event.location}
            </Grid>
            {(bookings.myEvent.status === ParticipationStatus.Booked ||
              bookings.myEvent.status === ParticipationStatus.Participated) && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={
                    "myevents-details-property-label myevents-details-property-container"
                  }
                >
                  {bookings.myEvent.event.event_type === EventType.Online
                    ? t("eventLocation.onlineEventAddress", { ns: "events" })
                    : t("eventLocation.onSiteEventAddress", { ns: "events" })}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={"myevents-details-property-container"}
                >
                  <Box component="span" style={{ whiteSpace: "pre-wrap" }}>
                    {bookings.myEvent.event.location_details}
                  </Box>
                </Grid>
              </>
            )}
            {bookings.myEvent.status === ParticipationStatus.WaitingList && (
              <>
                <Grid
                  item
                  xs={12}
                  className={
                    "myevents-details-property-label myevents-details-property-container"
                  }
                >
                  <Typography variant="caption" color={"error"}>
                    (Sie befinden sich auf der Warteliste)
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        {(bookings.myEvent.status === ParticipationStatus.Booked ||
          bookings.myEvent.status === ParticipationStatus.Participated) && (
          <CalendarLink
            booking={bookings.myEvent}
            trainingType={bookings.myEvent.event.training_type}
          />
        )}
        <EventDescription description={bookings.myEvent.event.description} />
        <EventDocuments
          documents={documents}
          no_automatic_participation_certificate={
            bookings.myEvent.event.no_automatic_participation_certificate
          }
        />
        {bookings.myEvent.status === ParticipationStatus.WaitingList &&
          new Date(bookings.myEvent.event.begin).getTime() > new Date().getTime() && (
            <Grid item xs={12} style={{ marginTop: "1.25rem" }}>
              <Button
                size="medium"
                variant="contained"
                color="primary"
                onClick={() => {
                  dispatch(
                    showDialog({
                      title: "Von der Warteliste entfernen",
                      message:
                        "Sind Sie sicher, dass Sie sich von der Warteliste entfernen möchten?",
                      action: () => {
                        dispatch(removeFromWaitingList(bookings.myEvent.id));
                      },
                    })
                  );
                }}
              >
                Von Warteliste entfernen
              </Button>
            </Grid>
          )}

        {/*<CancelEventButton*/}
        {/*  bookingId={bookings.myEvent.id}*/}
        {/*  type={bookings.myEvent.event.event_type}*/}
        {/*  createdAt={bookings.myEvent.created_on}*/}
        {/*  status={bookings.myEvent.status}*/}
        {/*  eventDate={bookings.myEvent.overall_begin}*/}
        {/*/>*/}
      </Grid>
    </>
  );
};

// TODO no translation yet
