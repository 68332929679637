/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import { AppState } from "../index";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { invoiceService } from "../../api";
import {
  CLEAR_INVOICE,
  ClearInvoiceAction,
  InvoiceActionTypes,
  INVOICE_FAILURE,
  INVOICE_REQUEST,
  InvoiceRequestAction,
  InvoiceFailureAction,
  SetinvoiceListAction,
  SET_MY_INVOICE_LIST_DATA,
  SET_INVOICES_PAGE_COUNT,
  SetPageCountAction,
} from "./types";
import { enqueueSnackbar } from "notistack";
import { invoiceListData, InvoiceFilterData } from "../../models/invoiceData";
import { Pagination } from "../../models/pagination";
import { setInvoiceData } from "../billingAddress/actions";
import { initialInvoiceState } from "./reducers";
import { InvoiceFilterService } from "../../services/filters/invoiceFilterService";

export function createInvoice() {
  return (dispatch: Dispatch<InvoiceActionTypes>, getState: () => AppState) => {
    dispatch(invoiceRequest());
    return invoiceService
      .createInvoice()
      .then((invoice) => {
        dispatch(setInvoiceData(invoice));
        dispatch(clearInvoice());
      })
      .catch((err: AxiosError) => {
        const errorStatus = err.response?.status;
        const errorObject = err.response?.data;
      });
  };
}

export function clearInvoice(): ClearInvoiceAction {
  return { type: CLEAR_INVOICE };
}

export function clearInvoices(): ClearInvoiceAction {
  return { type: CLEAR_INVOICE };
}

export function invoiceRequest(): InvoiceRequestAction {
  return {
    type: INVOICE_REQUEST,
  };
}

export function invoiceFailure(): InvoiceFailureAction {
  return {
    type: INVOICE_FAILURE,
  };
}

export function getInvoices(page: number) {
  return (dispatch: Dispatch<InvoiceActionTypes>) => {
    dispatch(invoiceRequest());
    invoiceService
      .getinvoiceList(page)
      .then((response: Pagination<invoiceListData>) => {
        dispatch(setMyInvoicesList(response));
      })
      .catch((err: AxiosError) => {
        dispatch(invoiceFailure());
        enqueueSnackbar(
          "Ihre Rechnungen konnten nicht abgerufen werden. Laden Sie die Seite neu und wiederholen den Vorgang!"
        );
      });
  };
}

export function getAllMyInvoices(page: number, filterData: InvoiceFilterData) {
  return (dispatch: Dispatch<InvoiceActionTypes>) => {
    const invoiceFilterService = new InvoiceFilterService(page, filterData);
    const filterHasChanged = invoiceFilterService.checkIfFilterHasChanged();

    if (filterHasChanged) {
      page = initialInvoiceState.currentPage;
      invoiceFilterService.searchFilter = { page: page };
      dispatch(clearInvoices()); // set initial state for invoice list
    }
    dispatch(invoiceRequest());

    const route = invoiceFilterService.getRoute();
    invoiceFilterService.reflectFiltersInUrl(route);

    invoiceService
      .getinvoiceList(page)
      .then((response: Pagination<invoiceListData>) => {
        dispatch(setMyInvoicesList(response));
      })
      .catch(() => {
        dispatch(invoiceFailure());
      });
  };
}

export function setMyInvoicesList(
  data: Pagination<invoiceListData>
): SetinvoiceListAction {
  return {
    type: SET_MY_INVOICE_LIST_DATA,
    data,
  };
}

export function setPageCount(pageCount: number): SetPageCountAction {
  return {
    type: SET_INVOICES_PAGE_COUNT,
    pageCount: pageCount,
  };
}
