/* eslint-disable @typescript-eslint/no-unused-vars,no-unused-vars,camelcase */
import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { NIL as NIL_UUID } from "uuid";
import { TrainingType } from "../../../models/enums/trainingType.enum";
import { AppState } from "../../../redux";
import { editChecklistForEvent } from "../../../redux/checklist/actions";
import { showDialog } from "../../../redux/dialogs/actions";
import { editEventDocumentList } from "../../../redux/events/actions";
import { clickedEvaluationLink, getLecture } from "../../../redux/lectures/actions";
import useValidId from "../../../components/hooks/useValidId";
import routes from "../../../routing/routes";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import TabsLumos from "../../../components/theming/tabs/TabsLumos";
import { isEventOver } from "../../../components/core/events/shared/checkEventIsOver";
import { Documents } from "../../../components/core/events/shared/Documents";
import { Participants } from "../../../components/core/events/shared/participants/Participants";
import MyLecturesBasicInformation from "../../../components/core/events/my-lectures/MyLecturesBasicInfomation";
import "../event/UserEventsPage.scss";
import { useTabPersistence } from "../../../hooks/useTabPersistence";
import { Certificates } from "../../../components/core/events/shared/certificates/Certificates";

/**
 * Component for managing the details of a lecture.
 * @returns JSX.Element
 */
export const UserLecturesDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();

  const lecture = useSelector((state: AppState) => state.lectures);
  const { isValidId } = useValidId(routes.my_lectures_details_event_id, id);
  const { activeTab, handleTabChange } = useTabPersistence({ defaultTab: 0 });

  useEffect(() => {
    // additionaly check if id is 0 because it needs to be initialized
    if (
      isEventOver(lecture.lectureDetails.end) &&
      lecture.lectureDetails.training_type === TrainingType.DefaultEvent &&
      lecture.lectureDetails.evaluation_link_speaker &&
      !lecture.lectureDetails.user_has_clicked_evaluation_link &&
      lecture.lectureDetails.id !== NIL_UUID &&
      lecture.lectureDetails.speaking_event !== NIL_UUID
    ) {
      dispatch(
        showDialog({
          title: "Bewertung der Veranstaltung",
          message: (
            <>
              Möchten Sie sich einen kurzen Moment Zeit nehmen, um die Organisation und
              Durchführung der Veranstaltung zu bewerten?
            </>
          ),
          confirmButtonText: "Zur Evaluation",
          action: () => {
            window.open(lecture.lectureDetails.evaluation_link_speaker);
            // if the user clicks the link set flag to true, so the next time the popup doesn't show up
            dispatch(
              clickedEvaluationLink(
                lecture.lectureDetails.id,
                lecture.lectureDetails.speaking_event,
                true
              )
            );
          },
        })
      );
    }
  }, [lecture.lectureDetails, dispatch]);

  useEffect(() => {
    if (isValidId && !lecture.lectureDetailsLoaded) {
      dispatch(getLecture(id));
    }
  }, [dispatch, id, isValidId, lecture.lectureDetailsLoaded]);

  return (
    <>
      <HeadingLumos>{lecture.lectureDetails.title}</HeadingLumos>
      <Formik
        enableReinitialize
        onSubmit={(data) => {
          if (data.submitChecklist) {
            dispatch(
              editChecklistForEvent(id, data.checklist, data.checklist_additional_info)
            );
          } else {
            dispatch(editEventDocumentList(data.documents, id));
          }
        }}
        initialValues={{
          documents: [],
          checklist: lecture.lectureDetails.checklist,
          checklist_additional_info: lecture.lectureDetails.checklist_additional_info,
          submitChecklist: false,
        }}
      >
        {({ values }) => (
          <Form>
            <TabsLumos
              activeTab={activeTab}
              onTabChange={handleTabChange}
              tabs={[
                {
                  label: "Basisinformationen",
                  content: (
                    <MyLecturesBasicInformation lecture={lecture.lectureDetails} />
                  ),
                },
                {
                  label: "Material",
                  content: (
                    <Documents typeOfTrainingToBeCreated={TrainingType.DefaultEvent} />
                  ),
                },
                {
                  label: "Teilnehmer",
                  content: (
                    <Participants
                      eventData={lecture.lectureDetails}
                      trainingType={TrainingType.DefaultEvent}
                    />
                  ),
                },
                {
                  label: "Teilnehmerzertifikate",
                  content: <Certificates eventId={id} />,
                },
              ]}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

// TODO no translation yet
