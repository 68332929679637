import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux";
import { useEffect } from "react";
import { setPageCount } from "../../../redux/invoices/actions";
import { getInvoices } from "../../../redux/invoices/actions";
import { getCancellationInvoices } from "../../../redux/cancellationInvoices/actions";
import {
  Box,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Pagination as PaginationUI } from "@material-ui/lab";
import { PAGE_SIZE } from "../../../models/pagination";
import Loader from "../../../components/theming/loader/Loader";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Utils } from "../../../services/utils";
import { useTranslation } from "react-i18next";
import { InvoiceState } from "../../../redux/invoices/types";
import { CancellationInvoiceState } from "../../../redux/cancellationInvoices/types";
import { InvoiceBillingAddressData } from "../../../models/billingAddressData";

interface InvoicesTableProps {
  isCancellationInvoiceList?: boolean;
}

/**
 * Component for displaying a table of user invoices.
 * @returns {JSX.Element} The component.
 */
export const InvoicesTable: React.FC<InvoicesTableProps> = (
  props: InvoicesTableProps
) => {
  const { t } = useTranslation(["common", "invoices", "snackbars"]);

  const { isCancellationInvoiceList } = props;

  const dispatch = useDispatch();

  const accessibility = useSelector((state: AppState) => state.accessibility);

  let tag = "none";

  let invoices: InvoiceState | CancellationInvoiceState = useSelector(
    (state: AppState) => state.invoices
  );
  if (isCancellationInvoiceList === true) {
    invoices = useSelector((state: AppState) => state.cancellationInvoices);
    tag = "cancellationInvoices.none";
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(setPageCount(value));
  };

  const loadData = React.useCallback(() => {
    if (isCancellationInvoiceList === true) {
      dispatch(getCancellationInvoices(invoices.currentPage));
    } else {
      dispatch(getInvoices(invoices.currentPage));
    }
  }, [invoices.currentPage]);

  useEffect(loadData, [loadData]);

  return (
    <>
      {invoices.isLoading ? (
        <Loader />
      ) : invoices.invoiceList?.count === 0 ? (
        <Box
          component="span"
          display="flex"
          alignItems="center"
          style={{ height: "6.25rem" }}
        >
          {t(tag, { ns: "invoices" })}
        </Box>
      ) : (
        <Grid item xs={12} style={{ marginTop: "1.25rem" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow id="table-row">
                  <TableCell>{t("invoiceNumber", { ns: "invoices" })}</TableCell>
                  <TableCell>{t("employerProfile.company", { ns: "common" })}</TableCell>
                  <TableCell>{t("recipientLastName", { ns: "invoices" })}</TableCell>
                  <TableCell>{t("recipientFirstName", { ns: "invoices" })}</TableCell>
                  <TableCell>{t("date", { ns: "invoices" })}</TableCell>
                  <TableCell>{t("download", { ns: "common" })}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.invoiceList.results?.map((invoice) => {
                  let billingAddress: InvoiceBillingAddressData = {
                    id: "",
                    company: "",
                    first_name: "",
                    last_name: "",
                  };
                  if (isCancellationInvoiceList === false && invoice.order) {
                    billingAddress = invoice.order.billing_address;
                  }

                  return (
                    <TableRow key={`invoice-${invoice.id}`} id="table-row">
                      <TableCell>{invoice.invoice_number}</TableCell>
                      <TableCell>{billingAddress.company}</TableCell>
                      <TableCell>{billingAddress.last_name}</TableCell>
                      <TableCell>{billingAddress.first_name}</TableCell>
                      <TableCell>
                        {Utils.formatDateToCetFormat(invoice.invoice_date)}
                      </TableCell>
                      <TableCell>
                        <Link
                          href={invoice.file_name}
                          download={invoice.file_name}
                          target="_blank"
                          className="myinvoices-document-download-icon"
                        >
                          <GetAppIcon
                            color={accessibility.monoMode ? "primary" : undefined}
                          />
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
      <Grid container style={{ marginTop: ".625rem" }}>
        <Box component="div" style={{ margin: "auto" }}>
          <PaginationUI
            count={Math.ceil(invoices.invoiceList.count / PAGE_SIZE)}
            page={invoices.currentPage}
            onChange={handleChange}
          />
        </Box>
      </Grid>
    </>
  );
};
