import styles from "./../../styles/custom-styles.module.scss";
import { getDynamicTranslation } from "../../components/core/localization/localizationUtils";

export enum ELearningProgressStatus {
  Open = "ELearningProgressStatus.open",
  InProgress = "ELearningProgressStatus.inProgress",
  Done = "ELearningProgressStatus.done",
  Revoked = "ELearningProgressStatus.revoked",
}

export function mapCountToProgressStatus(
  count: number,
  trainingPassed: boolean,
  certificateRevoked: boolean
) {
  if (certificateRevoked) {
    return getDynamicTranslation(ELearningProgressStatus.Revoked, "enums");
  }
  if (trainingPassed) {
    return getDynamicTranslation(ELearningProgressStatus.Done, "enums");
  }
  if (count === 0) {
    return getDynamicTranslation(ELearningProgressStatus.Open, "enums");
  }
  if (count > 0) {
    return getDynamicTranslation(ELearningProgressStatus.InProgress, "enums");
  }

  return getDynamicTranslation(ELearningProgressStatus.Open, "enums");
}

export function mapToProgress(status: ELearningProgressStatus) {
  if (status === ELearningProgressStatus.Open) {
    return 0;
  } else if (status === ELearningProgressStatus.InProgress) {
    return 50;
  } else {
    return 100;
  }
}

export function getProgressColor(progress: ELearningProgressStatus) {
  if (progress === ELearningProgressStatus.InProgress) return styles.primary;
  else if (progress === ELearningProgressStatus.Open) return styles.orange;
  else if (progress === ELearningProgressStatus.Revoked) return styles.red;
  else return styles.green;
}
