import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux";
import { history } from "../../../routing/history";
import { OrganizationData } from "../../../models/organizationData";
import { getOrganizationById } from "../../../redux/organization/actions";
import CrudOrganization from "../../../components/core/profile-settings/organization/CrudOrganization";
import TabsLumos from "../../../components/theming/tabs/TabsLumos";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import Loader from "../../../components/theming/loader/Loader";
import routes from "../../../routing/routes";
import InvoiceManagement from "../../../components/core/profile-settings/organization/invoice/InvoiceManagement";
import BookingsManagement from "../../../components/core/profile-settings/organization/bookings/BookingsManagement";
import MembershipTab from "../../../components/core/profile-settings/organization/membership/MembershipTab";
import { useTabPersistence } from "../../../hooks/useTabPersistence";
import OrganizationSettingsManagement from "../../../components/core/profile-settings/organization/settings/OrganizationSettingsManagement";

interface RouteParams {
  id: string;
}

/**
 * Component for managing organization details and memberships.
 * @returns JSX.Element
 */
export const OrganizationAdminPage = () => {
  const { id } = useParams<RouteParams>();
  const { t } = useTranslation(["organization", "snackbars", "invoices"]);
  const dispatch = useDispatch();
  const organizations = useSelector((state: AppState) => state.organizations);
  const user = useSelector((state: AppState) => state.user);
  const [organization, setOrganization] = useState<OrganizationData | null>(null);
  const [loading, setLoading] = useState(true);

  const { activeTab, handleTabChange } = useTabPersistence({ defaultTab: 0 });

  useEffect(() => {
    if (id) {
      dispatch(getOrganizationById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (organizations?.organizationData?.length > 0) {
      const selectedOrganization = organizations.organizationData.find(
        (org) => org.id === id
      );
      setOrganization(selectedOrganization || null);
    } else {
      setLoading(false);
    }
  }, [organizations, id]);

  useEffect(() => {
    if (organization && user) {
      const isAdmin = organization.admins.some(
        (admin) => admin?.admin_id === user?.currentUser?.id
      );
      if (isAdmin) {
        setLoading(false);
      } else {
        history.push(routes.home);
      }
    }
  }, [organization, user]);

  const tabs = [
    {
      label: t("bookings.manageBookingsTab", { ns: "organization" }),
      content: <BookingsManagement organizationId={id} />,
    },
    {
      label: t("membership.manageMemberships", { ns: "organization" }),
      content: <MembershipTab organizationId={id} />,
    },
    {
      label: t("invoice.manageInvoices", { ns: "organization" }),
      content: <InvoiceManagement organizationId={id} />,
    },
    {
      label: t("organization", { ns: "organization" }),
      content: organization && <CrudOrganization organizationData={organization} />,
    },
    {
      label: t("orgaSettings", { ns: "organization" }),
      content: <OrganizationSettingsManagement organizationId={id} />,
    },
  ];

  return loading ? (
    <Loader />
  ) : !loading && organization ? (
    <>
      <HeadingLumos>{organization?.name}</HeadingLumos>
      <TabsLumos tabs={tabs} activeTab={activeTab} onTabChange={handleTabChange} />
    </>
  ) : (
    <Typography>{t("noOrganization", { ns: "organization" })}</Typography>
  );
};
