/* eslint-disable */
// TODO: Remove eslint disable after refactoring
import { useEffect, useRef, useState } from "react";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import { Camera, PlayArrow } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppState } from "../../../../../redux";
import {
  addDocumentCapture,
  addImageCapture as addParticipantCapture,
} from "../../../../../redux/videoAuthentication/actions";
import example1Mobile from "../../../../../assets/video_authentisierung/authentifizierung-1-mobile.png";
import example2Mobile from "../../../../../assets/video_authentisierung/authentifizierung-2-mobile.png";
import example1 from "../../../../../assets/video_authentisierung/authentifizierung-1.png";
import example2 from "../../../../../assets/video_authentisierung/authentifizierung-2.png";
import { CaptureList } from "./CaptureList";
import { CaptureIdCardList } from "./CaptureIdCardList";
import { CountDown } from "./CountDown";
import { NavControls } from "./NavControls";
import CaptureHints from "./CaptureHints";
import OnSiteAuthPopup from "./on-site-popup/OnSiteAuthPopup";
import { loggerService } from "../../../../../api";
import { LogLevelType } from "../../../../../models/enums/logLevelType.enum";
import { isMobileDevice } from "../../../../../utils/isMobileDevice";
import { isSafariBrowser } from "../../../../../utils/isSafariBrowser";
import { isAppleDevice } from "../../../../../utils/isAppleDevice";
import { isIpadDevice } from "../../../../../utils/isIpadDevice";

/**
 * Renders the Capture component.
 * This component handles capturing images from the camera and provides UI for the capture process.
 * @returns JSX element
 */
export const Capture = () => {
  const { t } = useTranslation(["authCapture", "snackbars"]);
  const isMobile = isMobileDevice();
  const isApple = isAppleDevice();
  const isSafari = isSafariBrowser();
  const isIpad = isIpadDevice();

  const isMobileOrIpad = isMobile || isIpad;

  // Refs for canvas and video elements
  const lastCaptureRef = useRef<HTMLDivElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(document.createElement("video"));

  // Redux state and dispatch
  const { captures } = useSelector((state: AppState) => state.videoAuthentication);
  const { documentCaptures } = useSelector(
    (state: AppState) => state.videoAuthentication
  );
  const dispatch = useDispatch();

  // Local state
  const [cameraStream, setCameraStream] = useState<MediaStream | null>(null);
  const [timerStarted, setTimerStarted] = useState<boolean>(false);
  const [modeCardIDCapture, setModeCardIDCapture] = useState<boolean>(false);

  // Other hooks
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const componentName = "Capture";

  const canvasWidthScreenWidthRatio = 0.7;

  useEffect(() => {
    if (lastCaptureRef.current) {
      lastCaptureRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [captures]);

  /**
   * Calculates the countdown duration for image capture.
   *
   * @returns {number} - The countdown duration in seconds.
   */
  const contdownToCapture = () =>
    process.env.REACT_APP_COUNTDOWN_AUTH_VIDEOCAPTURE
      ? Number(process.env.REACT_APP_COUNTDOWN_AUTH_VIDEOCAPTURE)
      : 10;

  /**
   * Routes to the next step and stops all video-related processes.
   */
  const routeToNextStep = () => {
    stopVideoAndCameraStream();
    loggerService.addLog(`${componentName} - routeToNextStep`, LogLevelType.Info);
    history.push("?step=2");
  };
  /**
   * Routes back to the previous step and stops all video-related processes.
   */
  const routeBack = () => {
    stopVideoAndCameraStream();
    loggerService.addLog(`${componentName} - routeBack`, LogLevelType.Info);
    history.push("?");
  };

  // Focuse at the top of the page, if side is loaded
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Request camera stream on component load
  useEffect(() => {
    if (videoRef.current !== null) {
      requestCameraStream();
    }
    return () => {
      stopCameraStream();
    };
  }, [videoRef.current, isMobile, isMobileOrIpad]);

  // Attach camera stream to video element
  useEffect(() => {
    const video = videoRef.current;

    if (video === null) {
      return;
    }

    video.srcObject = cameraStream;

    if (cameraStream) {
      video.play();
    }
    return () => {
      stopVideo();
    };
  }, [cameraStream]);

  // Attach video to canvas
  useEffect(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");

    if (cameraStream && video && ctx && canvas && !video.paused && !video.ended) {
      const [start, stop] = drawMaskedVideoFrame(video, canvas, ctx);
      start();
      return stop;
    }
  }, [videoRef, canvasRef, cameraStream, modeCardIDCapture]);

  // ================================================================> Drawing on canvas
  /**
   * Function to draw masked video frame
   * @param {HTMLVideoElement} video - The video element.
   * @param {HTMLCanvasElement} canvas - The canvas element.
   * @param {CanvasRenderingContext2D} ctx - The canvas rendering context.
   * @returns {Array<Function>} - Start and stop functions for animation.
   */
  function drawMaskedVideoFrame(
    video: HTMLVideoElement,
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D
  ) {
    let requestId: number | undefined;

    /**
     * Function to animate the video frame.
     * Draws the video frame on the canvas with the mask.
     * @returns {void}
     */
    const animate = () => {
      if (video.videoWidth * video.videoHeight > 0) {
        if (isMobileOrIpad) {
          canvas.width = Math.round(canvasWidthScreenWidthRatio * video.videoWidth);
          canvas.height = Math.round(canvasWidthScreenWidthRatio * video.videoHeight);
          if (video.videoWidth > video.videoHeight) {
            canvas.width = Math.round(canvasWidthScreenWidthRatio * video.videoHeight);
            canvas.height = Math.round(canvasWidthScreenWidthRatio * video.videoWidth);
          }
        } else {
          canvas.width = Math.min(
            800,
            Math.round(canvasWidthScreenWidthRatio * screen.width)
          );
          canvas.height = Math.round((canvas.width / 16) * 9);
        }

        if (modeCardIDCapture) {
          drawWithDocumentMask(ctx, video, canvas);
        } else {
          drawWithParticipantMask(ctx, video, canvas);
        }
      }
      start();
    };

    /**
     * Function to start the animation.
     * @returns {void}
     */
    const start = () => {
      requestId = requestAnimationFrame(animate);
    };

    /**
     * Function to stop the animation.
     * @returns {void}
     */
    const stop = () => {
      if (requestId) {
        cancelAnimationFrame(requestId);
        requestId = undefined;
      }
    };

    return [start, stop];
  }

  // STEP 1 <==========================================> Capture the participant: STEP 1
  /**
   * Function to draw with participant mask
   * @param {CanvasRenderingContext2D} ctx - The canvas rendering context.
   * @param {HTMLVideoElement} video - The video element.
   * @param {HTMLCanvasElement} canvas - The canvas element.
   */
  function drawWithParticipantMask(
    ctx: CanvasRenderingContext2D,
    video: HTMLVideoElement,
    canvas: HTMLCanvasElement
  ) {
    // mirrored for draw of video
    ctx.setTransform(-1.0, 0, 0, 1, canvas.width, 0);
    ctx.drawImage(video, 0, 0);
    ctx.beginPath();

    let canvasWidth = 800;
    let canvasHeight = 450;

    if (isMobileOrIpad) {
      canvasHeight = Math.round(canvasWidthScreenWidthRatio * video.videoHeight);
      canvasWidth = Math.round(canvasWidthScreenWidthRatio * video.videoWidth);

      canvasHeight = Math.max(
        canvasWidthScreenWidthRatio * video.videoHeight,
        canvasWidthScreenWidthRatio * video.videoWidth
      );
      canvasWidth = Math.min(
        canvasWidthScreenWidthRatio * video.videoHeight,
        canvasWidthScreenWidthRatio * video.videoWidth
      );

      const faceRectWidth = Math.round(canvasWidth * 0.625);
      const faceRectHeight = Math.round(canvasHeight * 0.625);

      const faceRectLeft = Math.round(0.5 * (canvasWidth - faceRectWidth));
      const faceRectTop = Math.round(0.025 * canvasHeight);

      ctx.rect(faceRectLeft, faceRectTop, faceRectWidth, faceRectHeight);

      ctx.rect(
        faceRectLeft + faceRectWidth * 0.25,
        faceRectTop + faceRectHeight * 0.15,
        faceRectWidth * 0.5,
        faceRectHeight * 0.7
      );

      const documentRectHeigth = Math.round(canvasHeight * 0.3);
      const documentRectWidth = Math.round(canvasWidth * 0.6);

      const documentRectLeft = 0.5 * (canvasWidth - documentRectWidth);
      const documentRectTop = 0.675 * canvasHeight;

      ctx.rect(documentRectLeft, documentRectTop, documentRectWidth, documentRectHeigth);

      ctx.fillStyle = "#E7E9EB";
      ctx.fillRect(0, 0, canvasWidth, canvasHeight);
    } else {
      const faceRectWidth = 240;
      const faceRectHeight = 280;
      const faceRectLeft = 0.5 * (canvasWidth - faceRectWidth);
      const faceRectTop = 0.1 * (canvasHeight - faceRectHeight);

      ctx.ellipse(
        faceRectLeft + faceRectWidth * 0.5,
        faceRectTop + faceRectHeight * 0.5,
        faceRectWidth * 0.5,
        faceRectHeight * 0.5,
        0,
        0,
        2 * Math.PI
      );
      ctx.rect(faceRectLeft, faceRectTop, faceRectWidth, faceRectHeight);

      const documentRectWidth = 160;
      const documentRectHeigth = 100;

      const documentRectLeft = 100;
      const documentRectTop = 0.2 * (canvasHeight - documentRectHeigth);

      ctx.rect(documentRectLeft, documentRectTop, documentRectWidth, documentRectHeigth);

      ctx.fillStyle = "#E7E9EB";
      ctx.fillRect(0, 0, canvasWidth, canvasHeight);
    }

    ctx.clip();

    // CLEAR Image
    ctx.filter = "none";
    ctx.fillStyle = "#E7E9EB";
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);

    // mirrored for draw of video
    ctx.setTransform(-1.0, 0, 0, 1, canvasWidth, 0);
    ctx.drawImage(video, 0, 0, canvasWidth, canvasHeight);
    ctx.strokeStyle = process.env.REACT_APP_PRIMARY_COLOR ?? "#6db4bb";
    ctx.stroke();
  }

  // STEP 2 <=============================================> Capture the document: STEP 2
  /**
   * Function to draw with document mask of the fullsize document capture.
   * @param {CanvasRenderingContext2D} ctx - The canvas rendering context.
   * @param {HTMLVideoElement} video - The video element.
   * @param {HTMLCanvasElement} canvas - The canvas element.
   */
  function drawWithDocumentMask(
    ctx: CanvasRenderingContext2D,
    video: HTMLVideoElement,
    canvas: HTMLCanvasElement
  ) {
    // mirrored for draw of video
    ctx.setTransform(-1.0, 0, 0, 1, canvas.width, 0);

    ctx.drawImage(video, 0, 0);
    ctx.beginPath();

    let canvasWidth = 800;
    let canvasHeight = 450;

    if (isMobileOrIpad) {
      canvasHeight = Math.round(canvasWidthScreenWidthRatio * video.videoHeight);
      canvasWidth = Math.round(canvasWidthScreenWidthRatio * video.videoWidth);

      canvasHeight = Math.max(
        canvasWidthScreenWidthRatio * video.videoHeight,
        canvasWidthScreenWidthRatio * video.videoWidth
      );
      canvasWidth = Math.min(
        canvasWidthScreenWidthRatio * video.videoHeight,
        canvasWidthScreenWidthRatio * video.videoWidth
      );

      const documentRectHeigth = Math.round(canvasHeight * 0.9);
      const documentRectWidth = Math.round((documentRectHeigth / 8.5) * 5.5);

      const documentRectLeft = Math.round(0.5 * (canvasWidth - documentRectWidth));
      const documentRectTop = Math.round(0.05 * canvasHeight);

      ctx.rect(documentRectLeft, documentRectTop, documentRectWidth, documentRectHeigth);

      const faceRectWidth = Math.round((documentRectWidth / 5.5) * 4);
      const faceRectHeight = Math.round((faceRectWidth / 4) * 3);
      const faceRectLeft = Math.round(0.45 * (canvasWidth - faceRectWidth));
      const faceRectTop = Math.round(0.1 * canvasHeight);

      ctx.rect(faceRectLeft, faceRectTop, faceRectWidth, faceRectHeight);

      ctx.fillStyle = "#E7E9EB";
      ctx.fillRect(0, 0, canvasWidth, canvasHeight);
    } else {
      const faceRectWidth = 230;
      const faceRectHeight = 300;
      const faceRectLeft = 0.2 * (canvasWidth - faceRectWidth);
      const faceRectTop = 0.5 * (canvasHeight - faceRectHeight);

      ctx.rect(faceRectLeft, faceRectTop, faceRectWidth, faceRectHeight);

      const documentRectWidth = 600;
      const documentRectHeigth = 380;

      const documentRectLeft = 0.5 * (canvasWidth - documentRectWidth);
      const documentRectTop = 0.5 * (canvasHeight - documentRectHeigth);

      ctx.rect(documentRectLeft, documentRectTop, documentRectWidth, documentRectHeigth);

      ctx.fillStyle = "#E7E9EB";
      ctx.fillRect(0, 0, canvasWidth, canvasHeight);
    }

    ctx.clip();

    // CLEAR Image
    ctx.filter = "none";
    ctx.fillStyle = "#E7E9EB";
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);

    // mirrored for draw of video
    ctx.setTransform(-1.0, 0, 0, 1, canvas.width, 0);
    ctx.drawImage(video, 0, 0, canvasWidth, canvasHeight);
    ctx.strokeStyle = process.env.REACT_PRIMARY_COLOR ?? "#6db4bb";
    ctx.lineWidth = 3;
    ctx.stroke();
  }

  // ==========================================================> Camera stream and video
  /**
   * Stops the video playback.
   */
  function stopVideo() {
    const video = videoRef.current;
    if (!video) {
      return;
    }

    video.pause();
    video.srcObject = null;
  }

  /**
   * Stops the camera stream.
   */
  function stopCameraStream() {
    for (const track of cameraStream?.getTracks() ?? []) {
      track.stop();
    }

    setCameraStream(null);
  }

  /**
   * Stops all video-related processes.
   */
  function stopVideoAndCameraStream() {
    try {
      stopVideo();
      stopCameraStream();
    } catch {
      loggerService.addLog(
        `${componentName} - stopVideoAndCameraStream: Camera turned off with error`,
        LogLevelType.Error
      );
    }
  }

  /**
   * Requests access to the camera stream.
   */
  function requestCameraStream() {
    if (cameraStream) {
      return;
    }

    const containerWidth =
      canvasRef.current?.parentElement?.getBoundingClientRect().width ?? 0;
    if (containerWidth === 0) {
      return;
    }

    // the height of the canvas
    const videoHeight = isMobileOrIpad
      ? window.innerHeight * 0.5
      : window.innerHeight * 0.65;

    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          facingMode: "user",
          width: containerWidth - 20,
          height: videoHeight,
        },
      })
      .then(
        (stream) => {
          setCameraStream(stream);
        },
        () =>
          enqueueSnackbar(t("events.authenticationCameraError", { ns: "snackbars" }), {
            variant: "error",
          })
      );
  }

  /**
   * Handles proceeding to the next step based on capture status.
   */
  function handleProceed() {
    if (captures.length === 1 && documentCaptures.length === 1) {
      routeToNextStep();
      return;
    }

    if (documentCaptures.length === 0 && captures.length === 1) {
      setModeCardIDCapture(true);
      return;
    }

    routeBack();
  }

  /**
   * Handles image capture from the canvas.
   */
  const handleCapture = () => {
    const capture = canvasRef.current?.toDataURL();

    if (capture) {
      if (modeCardIDCapture) {
        dispatch(addDocumentCapture(capture));
      } else {
        dispatch(addParticipantCapture(capture));
      }
    }

    setTimerStarted(false);
  };

  /**
   * Determines whether the user can proceed to the next step.
   */
  const canProcced =
    (captures.length === 1 && modeCardIDCapture === false) ||
    (documentCaptures.length === 1 && modeCardIDCapture);

  return (
    <Grid container spacing={2}>
      {/* Capture hints  */}
      <Grid item xs={12}>
        <CaptureHints modeCardIDCapture={modeCardIDCapture} />
      </Grid>

      {/* Canvas and camera */}
      <Grid item xs={12}>
        {isMobileOrIpad ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography>
              {" "}
              {t("shotCommands.example", {
                ns: "authCapture",
              })}
            </Typography>
            <Box display="flex" justifyContent="center">
              <img
                src={modeCardIDCapture ? example2Mobile : example1Mobile}
                alt="Beispiel"
                style={{ minWidth: "20%", maxWidth: "30%" }}
              />
            </Box>
          </Grid>
        ) : (
          <>
            <Grid container spacing={1}>
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <Typography>
                  {" "}
                  {t("shotCommands.example", {
                    ns: "authCapture",
                  })}
                </Typography>
              </Grid>
              <Grid item xs={9} sm={9} md={9} lg={9}>
                <Box display="flex" justifyContent="center">
                  <img
                    src={modeCardIDCapture ? example2 : example1}
                    alt="Beispiel"
                    style={{ width: "40%" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                {cameraStream
                  ? timerStarted || (
                      <span>
                        {t("shotCommands.countdown", {
                          countdown: contdownToCapture(),
                          ns: "authCapture",
                        })}
                      </span>
                    )
                  : null}
              </Grid>
            </Grid>
          </>
        )}
        <Box display="flex" justifyContent="center" margin="1.25rem">
          <canvas ref={canvasRef} />
        </Box>
        <Grid
          xs={12}
          container
          spacing={2}
          style={{
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={12} sm={6} md={4}>
            {/* The Button to turn on the camera */}
            <Button
              id="camera-on"
              variant="contained"
              fullWidth
              onClick={
                cameraStream === null ? requestCameraStream : stopVideoAndCameraStream
              }
              startIcon={<PlayArrow />}
              color={cameraStream === null ? "primary" : "secondary"}
              disabled={timerStarted}
              style={{ marginBottom: "0.2rem" }}
            >
              {cameraStream === null
                ? t("shotCommands.cameraOn", {
                    ns: "authCapture",
                  })
                : t("shotCommands.cameraOff", {
                    ns: "authCapture",
                  })}
            </Button>
          </Grid>
          {/* The Button to start the shot */}
          <Grid item xs={12} sm={6} md={4}>
            {cameraStream !== null && !timerStarted && (
              <Button
                fullWidth
                id="start-shot"
                onClick={() => {
                  if (isMobileOrIpad) {
                    handleCapture();
                  } else {
                    setTimerStarted(true);
                  }
                }}
                startIcon={<Camera />}
                variant="contained"
                color="primary"
              >
                {t("shotCommands.startShot", {
                  ns: "authCapture",
                })}
              </Button>
            )}
            {timerStarted ? (
              <CountDown
                seconds={contdownToCapture()}
                onZero={handleCapture}
                color="error"
                variant="h2"
                align="center"
              />
            ) : null}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <CaptureList
          isEditable={modeCardIDCapture === false}
          lastCaptureRef={lastCaptureRef}
        />
      </Grid>
      {modeCardIDCapture ? (
        <Grid item xs={12}>
          <CaptureIdCardList />
        </Grid>
      ) : null}
      {/* eslint-disable react/jsx-no-bind */}
      <NavControls canProceed={canProcced} onProceed={handleProceed} />
      <OnSiteAuthPopup />
    </Grid>
  );
};

/**
 * Function to display AusweisIDent alternative information
 */
// TODO add AusweisIDent
// function displayAusweisIDentInformation() {
//   enqueueSnackbar(
//     "“Eine Authentifizierung ist offenbar aktuell nicht möglich. Sie können sich alternativ mit dem AusweisIDent Verfahren authentifizieren”",
//     {
//       variant: "error",
//     }
//   );
// }
