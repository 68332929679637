import {
  CLEAR_BUND_ID,
  BundIDActionTypes,
  BUND_ID_FAILURE,
  BUND_ID_SUCCESS,
  BundIDState,
  BUND_ID_REQUEST,
  SET_BUND_ID_DATA,
  SET_BUND_ID_REQUEST_DATA,
} from "./types";

export const initialBundIDState: BundIDState = {
  bundIDData: {
    user: "",
    session_key: null,
  },
  bundIDRequestData: {
    event_participation: "",
    event: "",
    verified: false,
    unmatched_fields: "",
  },
  bundIDDataLoaded: false,
  isLoading: false,
};

export function bundIDReducer(
  state = initialBundIDState,
  action: BundIDActionTypes
): BundIDState {
  switch (action.type) {
    case BUND_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case BUND_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        bundIDDataLoaded: false,
      };
    case BUND_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bundIDDataLoaded: true,
      };
    case CLEAR_BUND_ID:
      return initialBundIDState;
    case SET_BUND_ID_DATA:
      return {
        ...state,
        bundIDData: action.bundID,
        isLoading: false,
      };
    case SET_BUND_ID_REQUEST_DATA:
      return {
        ...state,
        bundIDRequestData: action.bundID,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default bundIDReducer;
