import {
  CLEAR_STRIPE,
  StripeActionTypes,
  STRIPE_FAILURE,
  STRIPE_SUCCESS,
  StripeState,
  STRIPE_REQUEST,
  SET_STRIPE_DATA,
} from "./types";
import { NIL as NIL_UUID } from "uuid";

export const initialStripeState: StripeState = {
  stripeData: {
    id: NIL_UUID,
    session_id: NIL_UUID,
    status: "",
    order: NIL_UUID,
    client_secret: "",
  },
  stripeDataLoaded: false,
  isLoading: false,
  stripeFailure: false,
};

export function stripeReducer(
  state = initialStripeState,
  action: StripeActionTypes
): StripeState {
  switch (action.type) {
    case STRIPE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case STRIPE_FAILURE:
      return {
        ...state,
        isLoading: false,
        stripeDataLoaded: false,
        stripeFailure: true,
      };
    case STRIPE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        stripeDataLoaded: true,
      };
    case CLEAR_STRIPE:
      return initialStripeState;
    case SET_STRIPE_DATA:
      return {
        ...state,
        stripeData: action.stripe,
        isLoading: false,
        stripeDataLoaded: true,
      };
    default:
      return state;
  }
}

export default stripeReducer;
