import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useDynamicYupValidations } from "../../../libs/yup-validations";
import { InvoiceOrganizationData } from "../../../models/billingAddressData";
import { BookingDataWithPayment, BookingEventData } from "../../../models/bookingData";
import { AppState } from "../../../redux";
import { bookEvent } from "../../../redux/bookings/actions";
import { CheckoutButtons } from "../../../components/core/booking/checkout/checkoutButtons/CheckoutButtons";
import "./BillingFormPage.scss";
import { useTranslation } from "react-i18next";
import { UserOrganizationsDropDown } from "../../../components/core/booking/checkout/dropDownField/UserOrganizationsDropDown";
import Loader from "../../../components/theming/loader/Loader";
import BillForUser from "../../../components/core/booking/checkout/BillForUser";
import { NIL as NIL_UUID } from "uuid";
import { useEffect, useState } from "react";
import {
  fetchMemberships,
  setMembershipsLoaded,
} from "../../../redux/organizationMembership/action";
import HeadingLumos from "../../../components/theming/HeadingLumos";

export const BillingFormPage: React.FC = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["booking", "common", "snackbars"]);

  const cartState = useSelector((appState: AppState) => appState.cart);
  const billingAddressState = useSelector(
    (appState: AppState) => appState.billingAddress
  );
  const bookingState = useSelector((appState: AppState) => appState.booking);
  const initialState: InvoiceOrganizationData = {
    id: NIL_UUID,
  };
  const organizationMemberships = useSelector((state: AppState) => state.memberships);
  const [userHasNoOrganizations, setUserHasNoOrganizations] = useState(false);
  const [orderIsFreeOfCharge, setOrderIsFreeOfCharge] = useState(false);

  useEffect(() => {
    if (
      !organizationMemberships.isLoading &&
      !organizationMemberships.memberShipsLoaded
    ) {
      dispatch(fetchMemberships());
      dispatch(setMembershipsLoaded());
    }
    setUserHasNoOrganizations(organizationMemberships.memberships.length === 0);
  }, [dispatch, organizationMemberships]);

  useEffect(() => {
    if (!cartState.isLoading && cartState.cartInitialLoaded) {
      setOrderIsFreeOfCharge(cartState.cart.gross_total.toString() === "0.0000");
    }
  }, [cartState]);

  const handleSubmit = (invoice_organization: InvoiceOrganizationData) => {
    const events: BookingEventData[] = cartState.cart.cart_items.map((cartItem) => {
      return {
        event: cartItem.id,
        status: 20,
        catering_option: cartItem.catering_option,
        use_alternative_billing_address: true,
      };
    });

    let invoice_is_for_user = false;
    if (
      invoice_organization.id === NIL_UUID ||
      userHasNoOrganizations ||
      orderIsFreeOfCharge
    ) {
      invoice_is_for_user = true;
    }

    const bookingDataWithPayment: BookingDataWithPayment = {
      events,
      payment: {
        order: billingAddressState.payment.order,
        payment_method: billingAddressState.payment.payment_method,
        invoice_organization: invoice_organization,
        invoice_is_for_user: invoice_is_for_user,
        paypal_data: {
          id: "",
          transaction_id: "",
          gross_amount: "",
          capture_id: "",
          currency_code: "",
        },
      },
    };

    const book_for = cartState.cart.cart_items.find((item) => !!item.book_for?.id);
    if (book_for) {
      bookingDataWithPayment.book_for = book_for.book_for.id;
    }

    if (events.length > 0) {
      dispatch(bookEvent(bookingDataWithPayment));
    } else {
      enqueueSnackbar(t("booking.emptyCart", { ns: "snackbars" }), {
        variant: "error",
      });
    }
  };

  const { YupValidationOrganizationID } = useDynamicYupValidations();

  // Only validate if user has some organizationMemberships and gross total is > 0
  let billingValidationSchema = yup.object({
    id: YupValidationOrganizationID,
  });
  if (orderIsFreeOfCharge || userHasNoOrganizations) {
    billingValidationSchema = yup.object();
  }

  if (
    organizationMemberships.isLoading ||
    !organizationMemberships.memberShipsLoaded ||
    cartState.isLoading
  ) {
    return <Loader />;
  }
  return (
    <>
      <HeadingLumos>{t("invoiceForm.title", { ns: "booking" })}</HeadingLumos>
      <Formik
        initialValues={initialState}
        onSubmit={(value) => {
          handleSubmit(value);
        }}
        validationSchema={billingValidationSchema}
      >
        <Form>
          <Grid container spacing={4} className="grid">
            {orderIsFreeOfCharge ? (
              <BillForUser orderIsFreeOfCharge />
            ) : userHasNoOrganizations ? (
              <BillForUser orderIsFreeOfCharge={false} />
            ) : (
              <>
                <Grid item lg={12}>
                  {t("invoiceForm.selectOrganization", { ns: "booking" })}
                </Grid>
                <Grid item lg={12}>
                  <UserOrganizationsDropDown
                    allUserOrganizations={organizationMemberships.memberships}
                    invoiceOrganization={null}
                  />
                </Grid>
                <Grid item lg={12}>
                  <Box className="noteBox">
                    <Typography>
                      {t("invoiceForm.invoiceNoteOrganization", { ns: "booking" })}
                    </Typography>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item lg={12}>
            <CheckoutButtons isPaypal={false} isLoading={bookingState.isLoading} />
          </Grid>
        </Form>
      </Formik>
    </>
  );
};
