import { useEffect } from "react";
import { Box } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { fetchMembershipsByOrganizationId } from "../../../../../redux/organizationMembership/action";
import { fetchAccountActivationByOrganizationId } from "../../../../../redux/accountInvitation/actions";
import { AppState } from "../../../../../redux";
import { MembershipsManagement } from "./MembershipsManagement";
import AddMembership from "./AddMembership";

/**
 * @interface MembershipTabProps
 * Defines the props for the MembershipTab component.
 */
interface MembershipTabProps {
  organizationId: string;
}

/**
 * The `MembershipTab` component is responsible for displaying and managing memberships
 * within an organization. It fetches and displays existing memberships and account invitations
 * while also allowing users to add new memberships.
 *
 * @param {MembershipTabProps} props - The component props.
 * @returns {JSX.Element} The rendered MembershipTab component.
 */
const MembershipTab = ({ organizationId }: MembershipTabProps) => {
  const dispatch = useDispatch();

  /**
   * Fetches the latest membership and account invitation data for
   * the given organization.
   */
  const fetchMembershipsData = () => {
    dispatch(fetchMembershipsByOrganizationId(organizationId));
    dispatch(fetchAccountActivationByOrganizationId(organizationId));
  };

  /**
   * useEffect hook to fetch membership and account invitation data
   * when the component mounts or when the `organizationId` changes.
   */
  useEffect(() => {
    fetchMembershipsData();
  }, [organizationId, dispatch]);

  const memberships = useSelector((state: AppState) => state.memberships);
  const accountInvitations = useSelector((state: AppState) => state.accountInvitations);

  return (
    <Box>
      <AddMembership
        organizationId={organizationId}
        onMembershipAdded={fetchMembershipsData}
      />
      <MembershipsManagement
        organizationId={organizationId}
        memberships={memberships}
        accountInvitations={accountInvitations}
      />
    </Box>
  );
};

export default MembershipTab;
