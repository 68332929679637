import { FeatureFlagData } from "../../models/featureFlaggingData";

export interface FeatureFlaggingState {
  featureFlags: FeatureFlagData;
  isLoading: boolean;
  featureFlagsLoaded: boolean;
  isError: boolean;
}

export const FEATURE_FLAGGING_REQUEST = "FEATURE_FLAGGING_REQUEST";
export const FEATURE_FLAGGING_FAILURE = "FEATURE_FLAGGING_FAILURE";
export const LOAD_FEATURE_FLAGGING_DATA_SUCCESS = "LOAD_FEATURE_FLAGGING_DATA_SUCCESS";

export interface FeatureFlaggingRequestAction {
  type: typeof FEATURE_FLAGGING_REQUEST;
}

export interface FeatureFlaggingFailureAction {
  type: typeof FEATURE_FLAGGING_FAILURE;
}

export interface LoadFeatureFlaggingAction {
  type: typeof LOAD_FEATURE_FLAGGING_DATA_SUCCESS;
  data: FeatureFlagData;
}

export type FeatureFlaggingActionTypes =
  | FeatureFlaggingRequestAction
  | FeatureFlaggingFailureAction
  | LoadFeatureFlaggingAction;
