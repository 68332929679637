/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Pagination as PaginationUI } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { BookingFilterData } from "../../../models/bookingData";
import { ParticipationStatus } from "../../../models/enums/participationStatus.enum";
import { BookingsSortBy } from "../../../models/enums/sortBy.enum";
import { PAGE_SIZE } from "../../../models/pagination";
import { AppState } from "../../../redux";
import {
  getAllMyEvents,
  getAllMyEventsForPrint,
  setPageCount,
} from "../../../redux/bookings/actions";
import { BookingFilterService } from "../../../services/filters/bookingFilterService";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import Loader from "../../../components/theming/loader/Loader";
import NoEventsFound from "../../../components/core/events/shared/NoEventsFound";
import { MyEventsPrintList } from "../../../components/core/events/my-events/MyEventsPrintList";
import { useReactToPrint } from "react-to-print";
import SvgIconBase from "../../../assets/icons/custom-svg-components/SvgIconBase";
import { ReactComponent as Print } from "../../../assets/icons/print.svg";
import {
  mapToTrainingTypeString,
  TrainingType,
} from "../../../models/enums/trainingType.enum";
import MyEventTitleContent, {
  SUB_EVENTS,
} from "../../../components/core/events/my-events/MyEventTitleContent";
import { v4 as uuidv4 } from "uuid";
import {
  mapToEventTypeString,
  mapToEventTypeStringTranslationKey,
} from "../../../models/enums/eventType.enum";
import { getELearningDates } from "../../../models/elearning";
import { useTranslation } from "react-i18next";
import routes from "../../../routing/routes";
import RevokedCertificateDialog from "../../../components/layout/dialog/RevokedCertificateDialog";
import { isExpired } from "../../../components/core/events/shared/checkEventIsOver";
import ShowEventDoneDialog from "../../../components/layout/dialog/RevokedCertificateDialog";
import { createElearningRegistration } from "../../../redux/bookings/actions";
import { ELearningParticipationStatus } from "../../../models/enums/participationStatus.enum";
import {
  ELearningProgressStatus,
  mapCountToProgressStatus,
} from "../../../models/enums/elearningProgressStatus.enum";
import usePermission from "../../../services/usePermissions";
import { checkIfStartDisabled } from "../../../utils/isStartDisabled";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import i18n from "../../../localization/i18n";
import { getCurrentLanguage } from "../../../models/enums/salutationType.enum";
import DOMPurify from "dompurify";

export const UserEventsPage: React.FC = () => {
  const { t } = useTranslation(["common", "enums", "events", "snackbars"]);

  const dispatch = useDispatch();

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const eventList = useSelector((state: AppState) => state.booking.myEventList);
  const eventListPrint = useSelector(
    (state: AppState) => state.booking.myEventListPrint
  );
  const userData = useSelector((state: AppState) => state.user.currentUser);
  const booking = useSelector((state: AppState) => state.booking);
  const { isSpeaker } = usePermission(userData?.user_type);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(setPageCount(value));
  };

  const getInitialFilterData = React.useCallback(() => {
    let initialFilterData: BookingFilterData = {
      event__training_type: "",
      status: [],
      sorting: t(BookingsSortBy.EndAscending, { ns: "enums" }),
    };

    // ! persist the filter state after page change and set as initial filter values
    const filterService = new BookingFilterService(
      booking.currentPage,
      initialFilterData
    );
    initialFilterData = filterService.parseFiltersFromUrl();

    return initialFilterData;
  }, [t, booking.currentPage]);

  const [hasLoaded, setHasLoaded] = useState(false);

  const loadData = React.useCallback(() => {
    if (!hasLoaded && !booking.isLoading && !booking.myEventListLoaded) {
      dispatch(getAllMyEvents(booking.currentPage, getInitialFilterData()));
      dispatch(getAllMyEventsForPrint(getInitialFilterData()));
      setHasLoaded(true);
    }
  }, [
    hasLoaded,
    booking.isLoading,
    booking.myEventListLoaded,
    dispatch,
    getInitialFilterData,
  ]);

  useEffect(loadData, [loadData]);

  /**
   * The function checks if the start of the eLearning is possible
   * @returns {boolean} - true if the start of the eLearning is possible
   */

  const progress = (bookingItem: any) => {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    return mapCountToProgressStatus(
      bookingItem.elearning_started_count,
      bookingItem.elearning_status === ELearningParticipationStatus.Passed,
      bookingItem.status === ParticipationStatus.CertificateRevoked
    );
  };
  const [metaDataIsLoading, setMetaDataIsLoading] = useState(true);

  // Local state for metadata and loading status
  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await axios.get("/api/meta-data");
        setMetaData({
          my_courses_german: response.data.my_courses_german,
          my_courses_english: response.data.my_courses_english,
        });
      } catch (error) {
        enqueueSnackbar(
          t("backendResponses.error.captureNotSaved", { ns: "snackbars" }),
          {
            variant: "error",
          }
        );
      } finally {
        setMetaDataIsLoading(false);
      }
    };
    fetchMetaData();
  }, [i18n.language]);

  // Local state for metadata and loading status
  const [metaData, setMetaData] = useState({
    my_courses_german: "",
    my_courses_english: "",
  });

  const courseText =
    getCurrentLanguage() === "de" || getCurrentLanguage() === "de-DE"
      ? metaData.my_courses_german
      : metaData.my_courses_english;

  const createSafeHTML = (htmlString: string) => {
    return { __html: DOMPurify.sanitize(htmlString) };
  };

  // Show loader if metadata is still loading
  const courseContent = metaDataIsLoading ? (
    <CircularProgress />
  ) : (
    <Box
      className="login-content"
      style={{ padding: 0, margin: 0, overflowWrap: "anywhere" }}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <p dangerouslySetInnerHTML={createSafeHTML(courseText)} />
    </Box>
  );

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={10}>
          <HeadingLumos>{t("myBookings.title", { ns: "events" })}</HeadingLumos>
          <Typography>{courseContent}</Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            onClick={handlePrint}
            aria-label="print my events"
            style={{
              float: "right",
              cursor: "pointer",
            }}
            color="inherit"
          >
            <SvgIconBase
              style={{ stroke: "var(--text-color)", fontSize: "1.5625" }}
              component={Print}
            />
          </IconButton>
        </Grid>
      </Grid>
      {/*Printable version of myevent list */}
      <Box style={{ display: "none" }}>
        <MyEventsPrintList
          ref={componentRef}
          eventList={eventListPrint}
          userData={userData}
        />
      </Box>
      {/*End of Printable version of myevent list */}

      <Grid container spacing={0} className="myevents-list-header">
        <Hidden mdDown>
          <Grid item xs={1}>
            {t("course.courseID", { ns: "common" })}
          </Grid>
        </Hidden>
        <Grid item xs={10} sm={7} md={4} lg={3}>
          <Hidden mdDown>{t("title", { ns: "events" })}</Hidden>
          <Hidden lgUp>{t("eventType.event", { ns: "events" })}</Hidden>
        </Grid>
        <Hidden smDown>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            {t("myBookings.eventDate", { ns: "events" })}
          </Grid>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            {t("eLearning.periodDetail", { ns: "events" })}
          </Grid>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            {t("eventType.title", { ns: "events" })}
          </Grid>
        </Hidden>
        <Hidden xsDown>
          <Grid item xs={2} style={{ textAlign: "center" }} />
        </Hidden>
      </Grid>
      {booking.isLoading ? (
        <Loader />
      ) : eventList.count === 0 ? (
        <NoEventsFound />
      ) : (
        eventList.results
          .filter(
            (bookingItems) => bookingItems.status !== ParticipationStatus.IsFavorite
          )
          .map((bookingItems) => {
            const eLearningDates = (() => {
              const dates = getELearningDates(bookingItems);
              if (!dates || !dates.end) return "-";
              const { begin, end } = dates;
              return begin.toLocaleDateString() + " - " + end.toLocaleDateString();
            })();
            // TODO: since routing is pretty messy check if changing booking_id effects
            // anytinh. Else delete commented entry.
            //const booking_id = bookingItems.id;
            const booking_id = bookingItems.event.id;
            const when =
              bookingItems.event.training_type === TrainingType.ELearning
                ? "-"
                : new Date(bookingItems.event.begin).toLocaleDateString();
            const trainingType = [
              TrainingType.ELearning,
              TrainingType.BlendedLearning,
            ].includes(bookingItems.event.training_type)
              ? `${mapToTrainingTypeString(bookingItems.event.training_type)}`
              : "";
            const eventType = t(
              mapToEventTypeStringTranslationKey(
                mapToEventTypeString(bookingItems.event.event_type)
              ),
              { ns: "events" }
            );
            const location =
              bookingItems.event.location.length > 0 &&
              bookingItems.event.location !==
                mapToEventTypeString(bookingItems.event.event_type)
                ? ` (${bookingItems.event.location})`
                : "";
            const where =
              `${eventType}${location}`.length > 0 ? ` (${eventType}${location})` : "";
            // remove double-parenthesis
            let whatWhere = (trainingType + where).replaceAll("))", ")");
            // remove first parenthesis if no parenthesis are needed at all
            if (whatWhere.charAt(1) === "(") whatWhere = whatWhere.replace("(", "");
            // replace last opening parenthesis with dash separating event-type and location
            if ((whatWhere.match(/\(/g) || []).length > 1)
              // eslint-disable-next-line
              whatWhere = whatWhere.replace(/\(([^\()]+)\)$/, " - $1)");
            // remove the closing parenthesis which is missing an opening parenthesis
            if (
              (whatWhere.match(/\)/g) || []).length === 1 &&
              (whatWhere.match(/\(/g) || []).length === 0
            )
              whatWhere = whatWhere.replace(")", "");

            const isELearningFinished =
              progress(bookingItems) === ELearningProgressStatus.Done;

            const restrictAccessToLms = bookingItems.restrict_access && !isSpeaker();

            const handleStartEvent = ({
              bookingItems,
              booking_id,
              dispatch,
              restrictAccessToLms,
              isELearningFinished,
            }: {
              bookingItems: any;
              booking_id: string | number;
              dispatch: Function;
              restrictAccessToLms: boolean;
              isELearningFinished: boolean;
            }) => {
              if (bookingItems.status === ParticipationStatus.CertificateRevoked) {
                dispatch(RevokedCertificateDialog());
                return;
              } else if (isELearningFinished || isExpired(bookingItems.event.end)) {
                dispatch(ShowEventDoneDialog());
                return;
              }
              if (!restrictAccessToLms || !isELearningFinished) {
                if (bookingItems.elearning_registration_id === null) {
                  dispatch(createElearningRegistration(bookingItems.id));
                }
                window.open(
                  routes.elearning_test_id
                    .replace(":elearning_id", String(booking_id))
                    .replace(":event_participation_id", bookingItems.id),
                  "_blank",
                  "noopener, noreferrer"
                );
              }
            };

            return (
              <React.Fragment key={uuidv4()}>
                <Grid container spacing={0} className="myevents-list-item">
                  <Hidden mdDown>
                    <Grid item xs={1}>
                      # {bookingItems.event.public_id}
                    </Grid>
                  </Hidden>
                  <Grid item xs={10} sm={7} md={4} lg={3}>
                    <Hidden lgUp>
                      # {bookingItems.event.public_id}
                      <br />
                    </Hidden>
                    <Hidden smDown>
                      <div style={{ textDecoration: "underline" }}>
                        <MyEventTitleContent
                          text={bookingItems.event.title}
                          event={bookingItems}
                        />
                      </div>
                    </Hidden>
                    <Hidden mdUp>
                      <div style={{ textDecoration: "underline" }}>
                        <MyEventTitleContent
                          key={uuidv4()}
                          text={bookingItems.event.title}
                          event={bookingItems}
                        />
                      </div>
                    </Hidden>
                    {bookingItems.event.training_type ===
                      TrainingType.BlendedLearning && (
                      <Box component="div" style={{ display: "block" }}>
                        {SUB_EVENTS.map((subEvent) => (
                          <MyEventTitleContent
                            key={uuidv4()}
                            text={subEvent}
                            event={bookingItems}
                          />
                        ))}
                      </Box>
                    )}
                    <Hidden mdUp>
                      {when.length > 1 && (
                        <div>
                          <b>{t("myBookings.eventDate", { ns: "events" })}: </b> {when}
                        </div>
                      )}
                      {eLearningDates.length > 1 && (
                        <div>
                          <b>{t("eLearning.periodDetail", { ns: "events" })}: </b>{" "}
                          {eLearningDates}
                          {bookingItems.reactivated ? (
                            <>
                              <br />
                              <Typography variant="caption">
                                <strong>
                                  (
                                  {t("eLearning.periodProlonged", {
                                    ns: "events",
                                  })}{" "}
                                  {new Date(
                                    bookingItems.overall_end
                                  ).toLocaleDateString()}
                                  )
                                </strong>
                              </Typography>
                            </>
                          ) : null}
                        </div>
                      )}
                      <div>
                        <b> {t("eventType.title", { ns: "events" })}:</b> {whatWhere}
                      </div>
                    </Hidden>
                  </Grid>
                  <Hidden smDown>
                    <Grid item xs={2} style={{ textAlign: "center" }}>
                      {when}
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "center" }}>
                      {eLearningDates}
                      {bookingItems.reactivated ? (
                        <>
                          <br />
                          <Typography variant="caption">
                            <strong>
                              (
                              {t("eLearning.periodProlonged", {
                                ns: "events",
                              })}{" "}
                              {new Date(bookingItems.overall_end).toLocaleDateString()})
                            </strong>
                          </Typography>
                        </>
                      ) : null}
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "center" }}>
                      {whatWhere}
                    </Grid>
                  </Hidden>
                  <Hidden smDown>
                    <Grid
                      item
                      xs={2}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                        alignItems: "flex-start",
                      }}
                    >
                      {bookingItems.event.training_type !== 0 && (
                        <Button
                          fullWidth
                          id="start-elearning-from-overview-up"
                          size="medium"
                          variant="contained"
                          color="primary"
                          disabled={
                            checkIfStartDisabled(
                              bookingItems.overall_end,
                              bookingItems.status,
                              bookingItems.elearning_status
                            ) ||
                            bookingItems.status === ParticipationStatus.Cancelled ||
                            bookingItems.status ===
                              ParticipationStatus.CancelledByStaff ||
                            bookingItems.status === ParticipationStatus.Participated
                          }
                          onClick={() =>
                            handleStartEvent({
                              bookingItems,
                              booking_id,
                              dispatch,
                              restrictAccessToLms,
                              isELearningFinished,
                            })
                          }
                        >
                          {t("eLearning.start", { ns: "events" })}
                        </Button>
                      )}
                      {/*
                      TODO: Fix the bookingItems bug before enabling this button.
                        <Button
                          fullWidth
                          id="event-details-from-overview-up"
                          size="small"
                          variant="text"
                          color="secondary"
                          style={{
                            color: accessibility.monoMode
                              ? "#000"
                              : process.env.REACT_APP_PRIMARY_COLOR || "#157676",
                            textDecoration: "underline",
                          }}
                          onClick={() => {
                            const route =
                              bookingItems.event.training_type === 10
                                ? `${routes.my_events_details_elearning}/${booking_id}/${bookingItems.id}`
                                : `${routes.my_events_details}/${bookingItems.id}`;
                            history.push(route);
                          }}
                        >
                          {t("myBookings.eventList.bookingDetails", { ns: "events" })}
                        </Button>
                      */}
                    </Grid>
                  </Hidden>
                  {/* mobile  */}
                  <Hidden mdUp>
                    <Grid
                      item
                      xs={12}
                      md={2}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                        alignItems: "flex-start",
                        marginTop: "1rem",
                        width: "15rem",
                      }}
                    >
                      {bookingItems.event.training_type !== 0 && (
                        <Button
                          id="start-event-from-overview-down"
                          size="medium"
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={checkIfStartDisabled(
                            bookingItems.overall_end,
                            bookingItems.status,
                            bookingItems.elearning_status
                          )}
                          onClick={() =>
                            handleStartEvent({
                              bookingItems,
                              booking_id,
                              dispatch,
                              restrictAccessToLms,
                              isELearningFinished,
                            })
                          }
                        >
                          {t("eLearning.start", { ns: "events" })}
                        </Button>
                      )}
                      {/*
                      TODO: Fix the bookingItems bug before enabling this button.
                        <Button
                          id="event-details-from-overview-down"
                          size="small"
                          variant="text"
                          color="secondary"
                          type="submit"
                          style={{
                            color: accessibility.monoMode
                              ? "#000"
                              : process.env.REACT_APP_PRIMARY_COLOR || "#157676",
                            textDecoration: "underline",
                          }}
                          onClick={() => {
                            const route =
                              bookingItems.event.training_type === 10
                                ? `${routes.my_events_details_elearning}/${booking_id}/${bookingItems.id}`
                                : `${routes.my_events_details}/${bookingItems.id}`;
                            history.push(route);
                          }}
                        >
                          {t("myBookings.eventList.bookingDetails", { ns: "events" })}
                        </Button>
                      */}
                    </Grid>
                  </Hidden>
                </Grid>
                <Divider />
              </React.Fragment>
            );
          })
      )}

      <Grid container style={{ marginTop: ".625rem" }}>
        <Box component="div" style={{ margin: "auto" }}>
          <PaginationUI
            count={Math.ceil(eventList.count / PAGE_SIZE)}
            page={booking.currentPage}
            onChange={handleChange}
          />
        </Box>
      </Grid>
    </>
  );
};
