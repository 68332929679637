import {
  CancellationInvoiceState,
  CLEAR_INVOICE,
  CancellationInvoiceActionTypes,
  INVOICE_FAILURE,
  INVOICE_REQUEST,
  SET_CANCELLATION_INVOICE_LIST_DATA,
  SET_INVOICES_PAGE_COUNT,
} from "./types";

/**
 * Initial state for the cancellation invoice reducer.
 */
export const initialCancellationInvoiceState: CancellationInvoiceState = {
  invoiceList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  currentPage: 1,
  isLoading: false,
  invoiceListLoaded: false,
};

/**
 * Reducer function for managing cancellation invoice related state.
 * @param state Current state of the cancellation invoice.
 * @param action Action dispatched to update cancellation invoice state.
 * @returns Updated state of the cancellation invoice.
 */
export function cancellationInvoiceReducer(
  state = initialCancellationInvoiceState,
  action: CancellationInvoiceActionTypes
): CancellationInvoiceState {
  switch (action.type) {
    case INVOICE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case INVOICE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case CLEAR_INVOICE:
      return initialCancellationInvoiceState;
    case SET_CANCELLATION_INVOICE_LIST_DATA:
      return {
        ...state,
        invoiceList: action.data,
        invoiceListLoaded: true,
        isLoading: false,
      };
    case SET_INVOICES_PAGE_COUNT:
      return {
        ...state,
        currentPage: action.pageCount,
        invoiceListLoaded: false,
      };
    default:
      return state;
  }
}

export default cancellationInvoiceReducer;
