/* eslint-disable camelcase */
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, CircularProgress, Grid, Link, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ELearningFormData } from "../../../../models/elearning";
import { EventStatus } from "../../../../models/enums/eventStatus.enum";
import {
  mapToTrainingTypeString,
  TrainingType,
} from "../../../../models/enums/trainingType.enum";
import { UserType } from "../../../../models/enums/userType.enum";
import { AppState } from "../../../../redux";
import { showDialog } from "../../../../redux/dialogs/actions";
import {
  addDefaultDocuments,
  editDocumentState,
  getAllDefaultDocuments,
  getDocumentsByEvent,
  markDocumentForDelete,
  removeFromDocumentsList,
  setDocumentsLoaded,
} from "../../../../redux/documents/actions";
import usePermission from "../../../../services/usePermissions";
import CustomCheckbox from "../../../forms/CustomCheckbox";
import "./Documents.scss";
import { UploadArea } from "./UploadArea";
import { NIL as NIL_UUID } from "uuid";

interface DocumentsProps {
  typeOfTrainingToBeCreated: TrainingType;
  template_mode?: boolean;
  template_id?: string;
}

export const Documents = ({
  template_id,
  template_mode,
  typeOfTrainingToBeCreated,
}: DocumentsProps) => {
  const dispatch = useDispatch();
  // const history = useHistory(); // ! keep for future if speakers are allowed to upload documents

  const { id } = useParams<{ id: string }>();
  const isEditPage = id ? true : false;

  const { setFieldValue, values } = useFormikContext<ELearningFormData>();

  const documents = useSelector((state: AppState) => state.documents);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const { isStaff } = usePermission(currentUser?.user_type);

  const documentIsNotEditable = (
    is_default_online: boolean,
    is_default_on_site: boolean,
    last_changed_by_id: string
  ) => {
    if (is_default_online || is_default_on_site) {
      return true;
    }
    if (
      currentUser?.user_type === UserType.Speaker &&
      currentUser?.id !== last_changed_by_id
    ) {
      return true;
    }
    return false;
  };

  const setDocumentListClass = (isNotEditable: boolean) => {
    if (isNotEditable) {
      return "documents-list-item documents-list-item-noteditable";
    }
    return "documents-list-item";
  };

  useEffect(() => {
    if (isEditPage) {
      if (documents.documentList.length === 0 && !documents.documentListLoaded) {
        const idAsNumber = id;
        const isValidNumber = idAsNumber !== NIL_UUID;
        if (isValidNumber) {
          dispatch(getDocumentsByEvent(idAsNumber, template_mode ? true : false));
          dispatch(setDocumentsLoaded());
        }
      }
    } else if (template_id !== NIL_UUID && template_id !== undefined) {
      if (documents.documentList.length === 0 && !documents.documentListLoaded) {
        dispatch(getDocumentsByEvent(template_id, true));
        dispatch(setDocumentsLoaded());
      }
    } else {
      if (documents.documentList.length === 0 && !documents.documentListLoaded) {
        if (typeOfTrainingToBeCreated === TrainingType.ELearning) {
          dispatch(addDefaultDocuments("", "elearning"));
        } else {
          dispatch(getAllDefaultDocuments());
        }
        dispatch(setDocumentsLoaded());
      }
    }

    const documentsListIDsOnly: string[] = [];
    documents.documentList.forEach((document) => {
      if (!document?.is_default_on_site && !document?.is_default_online) {
        documentsListIDsOnly.push(document?.id);
      }
    });
    setFieldValue("documents", documentsListIDsOnly);
  }, [
    dispatch,
    documents,
    id,
    isEditPage,
    setFieldValue,
    template_mode,
    template_id,
    typeOfTrainingToBeCreated,
  ]);

  return (
    <>
      <Grid container spacing={4}>
        {/* For now don't allow the speakers to upload documents */}
        {values.status !== EventStatus.Completed && isStaff() && (
          <UploadArea trainingType={typeOfTrainingToBeCreated} />
        )}
        {documents.isLoading ? (
          <Box display="flex" alignItems="center">
            <CircularProgress style={{ marginRight: "0.62rem" }} /> Datei wird
            hochgeladen...
          </Box>
        ) : null}
        <Grid item xs={12}>
          <h3>Veranstaltungsmaterialien</h3>
          <Grid container className="documents-list-itemsHeader">
            <Grid
              item
              xs={typeOfTrainingToBeCreated === TrainingType.BlendedLearning ? 6 : 8}
            >
              <b>Name</b>
            </Grid>
            <Grid item xs={1} style={{ textAlign: "center" }}>
              <b>Standard</b>
            </Grid>
            <Grid
              item
              xs={typeOfTrainingToBeCreated === TrainingType.BlendedLearning ? 4 : 2}
              style={{ textAlign: "center" }}
            >
              {typeOfTrainingToBeCreated !== TrainingType.BlendedLearning ? (
                <b>
                  Nach {mapToTrainingTypeString(typeOfTrainingToBeCreated)}{" "}
                  veröffentlichen
                </b>
              ) : (
                <>
                  <b>Veröffentlichung</b>
                  <Grid container spacing={0}>
                    <Grid item xs={6}>
                      <Tooltip
                        placement="bottom"
                        title="* wird nach Ende der Veranstaltung veröffentlicht"
                        arrow
                      >
                        <Box component="span" style={{ cursor: "pointer" }}>
                          Nach VA*
                        </Box>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                      <Tooltip
                        placement="bottom"
                        title="* bei Blended-Learnings: Dokument wird zum Beginn des Bearbeitungszeitraumes eines E-Learnings veröffentlicht, vorausgesetzt das E-Learning wird erst nach Ende der Veranstaltung freigeschaltet"
                        arrow
                      >
                        <Box component="span" style={{ cursor: "pointer" }}>
                          Start des E-Learnings*
                        </Box>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xs={1} style={{ textAlign: "center" }}>
              <b>Löschen</b>
            </Grid>
          </Grid>
          {documents.documentList.length === 0 ? (
            <Box
              component="span"
              display="flex"
              alignItems="center"
              style={{ height: "6.25rem" }}
            >
              Veranstaltung enthält keine Dokumente
            </Box>
          ) : (
            // TODO: this is not controlled by the form (maybe use field array to control each document)
            // <FieldArray
            //   name="documents"
            //   render={(arrayHelpers) => (
            <>
              {documents.documentList.map((document, index) => {
                return (
                  <Grid
                    container
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    className={setDocumentListClass(
                      documentIsNotEditable(
                        document?.is_default_online,
                        document?.is_default_on_site,
                        document?.last_changed_by_id
                      )
                    )}
                  >
                    <Grid
                      item
                      xs={
                        typeOfTrainingToBeCreated === TrainingType.BlendedLearning
                          ? 6
                          : 8
                      }
                    >
                      <Box
                        component="span"
                        display="flex"
                        alignItems="center"
                        height="100%"
                      >
                        <Link href={document?.file} target="_blank">
                          <GetAppIcon className="documents-list-downloadIcon" />
                        </Link>{" "}
                        {document?.title}
                      </Box>
                    </Grid>
                    <Grid item xs={1} style={{ textAlign: "center" }}>
                      {document?.is_default_online || document?.is_default_on_site ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          size="2x"
                          color={accessibility.monoMode ? "#000" : "#95e917"}
                        />
                      ) : null}
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "center" }}>
                      {documentIsNotEditable(
                        document?.is_default_online,
                        document?.is_default_on_site,
                        document?.last_changed_by_id
                      ) ? (
                        <CustomCheckbox
                          name="publish_after_event"
                          disabled
                          checked={document?.publish_after_event}
                        />
                      ) : (
                        <CustomCheckbox
                          // name={`documents[${index}].publish_after_event`}
                          name="publish_after_event"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            document.publish_after_event = event.target.checked;
                            const data: FormData = new FormData();
                            data.append(
                              "publish_after_event",
                              event.target.checked.toString()
                            );
                            dispatch(editDocumentState(document, data));
                          }}
                          checked={document?.publish_after_event}
                        />
                      )}
                    </Grid>
                    {typeOfTrainingToBeCreated === TrainingType.BlendedLearning && (
                      <Grid item xs={2} style={{ textAlign: "center" }}>
                        <CustomCheckbox
                          disabled={
                            documentIsNotEditable(
                              document?.is_default_online,
                              document?.is_default_on_site,
                              document.last_changed_by_id
                            ) || !values.publishELearningAfterEvent
                          }
                          name="publish_at_elearning_start"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            document.publish_at_elearning_start = event.target.checked;
                            const data: FormData = new FormData();
                            data.append(
                              "publish_at_elearning_start",
                              event.target.checked.toString()
                            );
                            dispatch(editDocumentState(document, data));
                          }}
                          checked={document?.publish_at_elearning_start}
                        />
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={1}
                      style={{
                        textAlign: "center",
                        margin: "auto",
                      }}
                    >
                      {documentIsNotEditable(
                        document?.is_default_online,
                        document?.is_default_on_site,
                        document?.last_changed_by_id
                      ) ? (
                        <DeleteIcon className="documents-list-item-delButtonDisabled" />
                      ) : (
                        <DeleteIcon
                          onClick={() => {
                            dispatch(
                              showDialog({
                                title: "Dokument entfernen",
                                message:
                                  "Sind Sie sich sicher, dass Sie dieses Dokument von der Veranstaltung entfernen möchten?",
                                action: () => {
                                  /* we do not call deleteDocument(...), because it should be removed on save
                                     instead we remove it from list and mark it to be deleted on save */
                                  dispatch(removeFromDocumentsList(document));
                                  dispatch(markDocumentForDelete(document));
                                },
                              })
                            );
                          }}
                          className={
                            accessibility.monoMode
                              ? "documents-list-item-delButton-mono"
                              : "documents-list-item-delButton"
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            </>
            //   )}
            // />
          )}
        </Grid>
      </Grid>
      {/* TODO: for now don't allow the speakers to change the documents*/}
      {/* {isSpeaker() && (
        <Grid container spacing={4}>
          <Grid item xs={6} sm={4}>
            <Button
              size="large"
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              onClick={() => {
                setFieldValue("submitChecklist", false);
              }}
            >
              Dokumentenliste speichern
            </Button>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => {
                history.push(routes.my_lectures);
              }}
            >
              Abbrechen
            </Button>
          </Grid>
        </Grid>
      )} */}
    </>
  );
};

// TODO no translation yet
