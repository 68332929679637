/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import { AppState } from "../index";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { enqueueSnackbar } from "notistack";
import { stripeService } from "../../api";
import {
  CLEAR_STRIPE,
  ClearStripeAction,
  StripeActionTypes,
  STRIPE_FAILURE,
  STRIPE_REQUEST,
  StripeRequestAction,
  StripeFailureAction,
  StripeSuccessAction,
  SetStripeDataAction,
  SET_STRIPE_DATA,
  STRIPE_SUCCESS,
} from "./types";
import { StripeData } from "../../models/stripeData";
import { ReducedOrderData } from "../../models/orderData";

export function createStripe(orderData: ReducedOrderData) {
  return (dispatch: Dispatch<StripeActionTypes>, getState: () => AppState) => {
    dispatch(stripeRequest());

    return stripeService
      .createStripe(orderData.id)
      .then((stripeCheckout) => {
        dispatch(setStripeData(stripeCheckout));
      })
      .catch((err: AxiosError) => {
        dispatch(stripeFailure());
      });
  };
}

export function getStripeData(stripeId: string) {
  return (dispatch: Dispatch<StripeActionTypes>) => {
    dispatch(stripeRequest());
    stripeService
      .getStripeData(stripeId)
      .then((response: StripeData) => {
        dispatch(setStripeData(response));
        dispatch(stripeSuccess());
      })
      .catch((err: AxiosError) => {
        const errorStatus = err.response?.status;
        const errorObject = err.response?.data;
        dispatch(stripeFailure());
      });
  };
}

export function clearStripe(): ClearStripeAction {
  return { type: CLEAR_STRIPE };
}

export function stripeRequest(): StripeRequestAction {
  return {
    type: STRIPE_REQUEST,
  };
}

export function stripeFailure(): StripeFailureAction {
  return {
    type: STRIPE_FAILURE,
  };
}

export function stripeSuccess(): StripeSuccessAction {
  return {
    type: STRIPE_SUCCESS,
  };
}

export function setStripeData(stripe: StripeData): SetStripeDataAction {
  return {
    type: SET_STRIPE_DATA,
    stripe,
  };
}
