import { OrganizationSettingsData } from "../../models/organizationSettingsData";

export interface OrganizationSettingsState {
  organizationSettingsData: OrganizationSettingsData;
  isLoading: boolean;
  organizationSettingsDataLoaded: boolean;
  error: string | null;
}

export const LOAD_ORGANIZATION_SETTINGS_REQUEST = "LOAD_ORGANIZATION_SETTINGS_REQUEST";
export const LOAD_ORGANIZATION_SETTINGS_SUCCESS = "LOAD_ORGANIZATION_SETTINGS_SUCCESS";
export const LOAD_ORGANIZATION_SETTINGS_FAILURE = "LOAD_ORGANIZATION_SETTINGS_FAILURE";

export const SET_ORGANIZATION_SETTINGS_REQUEST = "SET_ORGANIZATION_SETTINGS_REQUEST";
export const SET_ORGANIZATION_SETTINGS_SUCCESS = "SET_ORGANIZATION_SETTINGS_SUCCESS";
export const SET_ORGANIZATION_SETTINGS_FAILURE = "SET_ORGANIZATION_SETTINGS_FAILURE";

/**
 * Action creator interface for requesting to load organization settings data.
 */
export interface LoadOrganizationSettingsRequestAction {
  type: typeof LOAD_ORGANIZATION_SETTINGS_REQUEST;
}

/**
 * Action creator interface for indicating failure in loading organization settings data.
 */
export interface LoadOrganizationSettingsFailureAction {
  type: typeof LOAD_ORGANIZATION_SETTINGS_FAILURE;
  error: string;
}

/**
 * Action creator interface for successfully loading organization settings data.
 */
export interface LoadOrganizationSettingsDataAction {
  type: typeof LOAD_ORGANIZATION_SETTINGS_SUCCESS;
  data: OrganizationSettingsData;
}

/**
 * Action creator interface for requesting to set organization settings data.
 */
export interface SetOrganizationSettingsRequestAction {
  type: typeof SET_ORGANIZATION_SETTINGS_REQUEST;
}

/**
 * Action creator interface for indicating failure in setting organization settings data.
 */
export interface SetOrganizationSettingsFailureAction {
  type: typeof SET_ORGANIZATION_SETTINGS_FAILURE;
  error: string;
}

/**
 * Action creator interface for successfully setting organization settings data.
 */
export interface SetOrganizationSettingsDataAction {
  type: typeof SET_ORGANIZATION_SETTINGS_SUCCESS;
  data: OrganizationSettingsData;
}

/**
 * Union type for all the action types related to organization settings.
 */
export type OrganizationSettingsActionTypes =
  | LoadOrganizationSettingsRequestAction
  | LoadOrganizationSettingsFailureAction
  | LoadOrganizationSettingsDataAction
  | SetOrganizationSettingsRequestAction
  | SetOrganizationSettingsFailureAction
  | SetOrganizationSettingsDataAction;
