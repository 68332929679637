import React from "react";
import { Box, Collapse, Grid, Link } from "@material-ui/core";
import { CustomSelectArrow } from "../../../../../assets/icons/custom-svg-components/LumosSvgCollection";
import DOMPurify from "dompurify";
import { Utils } from "../../../../../services/utils";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

interface EventItemDescriptionProps {
  description: string;
}

export const EventItemDescription: React.FC<EventItemDescriptionProps> = (props) => {
  const [checked, setChecked] = React.useState(false);
  const { description } = props;

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const html = DOMPurify.sanitize(
    Utils.convertRteStateToHtml(Utils.convertJsonToRteState(description))
  );

  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));

  return html === "<p><br></p>" ? null : (
    <>
      <Collapse in={checked} collapsedSize={small ? 0 : 90}>
        <Box
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      </Collapse>

      <Grid container>
        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: "0.625rem" }}
          >
            <Link onClick={handleChange} className="event-item-details-icon">
              {checked ? (
                <CustomSelectArrow className="event-item-shrink-icon" />
              ) : (
                <CustomSelectArrow className="event-item-expand-icon" />
              )}
            </Link>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
