import { Dispatch } from "redux";
import { FeatureFlagData } from "../../models/featureFlaggingData";
import { featureFlaggingService } from "../../api/index";
import {
  LOAD_FEATURE_FLAGGING_DATA_SUCCESS,
  LoadFeatureFlaggingAction,
  FEATURE_FLAGGING_FAILURE,
  FEATURE_FLAGGING_REQUEST,
  FeatureFlaggingActionTypes,
  FeatureFlaggingFailureAction,
  FeatureFlaggingRequestAction,
} from "./types";

export function getFeatureFlags() {
  return (dispatch: Dispatch<FeatureFlaggingActionTypes>) => {
    dispatch(featureFlaggingRequest());
    featureFlaggingService
      .getFeatureFlags()
      .then((response: FeatureFlagData) => {
        dispatch(loadFeatureFlagDataSuccess(response));
      })
      .catch(() => {
        dispatch(featureFlaggingFailure());
      });
  };
}

export function loadFeatureFlagDataSuccess(
  data: FeatureFlagData
): LoadFeatureFlaggingAction {
  return {
    type: LOAD_FEATURE_FLAGGING_DATA_SUCCESS,
    data,
  };
}

export function featureFlaggingRequest(): FeatureFlaggingRequestAction {
  return {
    type: FEATURE_FLAGGING_REQUEST,
  };
}

export function featureFlaggingFailure(): FeatureFlaggingFailureAction {
  return {
    type: FEATURE_FLAGGING_FAILURE,
  };
}
