import { useState, useEffect } from "react";

/**
 * Custom hook to manage expanded table row state with localStorage persistence.
 * @param storageKey - The key for localStorage.
 * @returns [expandedRows, toggleRow] - The expanded rows state and a function to update it.
 */
export const useTableRowState = (storageKey: string) => {
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>(
    JSON.parse(localStorage.getItem(storageKey) || "{}")
  );

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(expandedRows));
  }, [expandedRows, storageKey]);

  /**
   * Clears localStorage when the component unmounts,
   * so the table resets when the user navigates away.
   */
  useEffect(() => {
    return () => {
      localStorage.removeItem(storageKey);
    };
  }, [storageKey]);

  /**
   * Toggles the expanded state of a table row.
   * @param {string} rowId - The unique key of the row to toggle.
   */
  const toggleRow = (rowId: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  return [expandedRows, toggleRow] as const;
};
