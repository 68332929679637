import {
  LOAD_ORGANIZATION_SETTINGS_FAILURE,
  LOAD_ORGANIZATION_SETTINGS_REQUEST,
  LOAD_ORGANIZATION_SETTINGS_SUCCESS,
  LoadOrganizationSettingsDataAction,
  LoadOrganizationSettingsFailureAction,
  LoadOrganizationSettingsRequestAction,
  OrganizationSettingsActionTypes,
  SET_ORGANIZATION_SETTINGS_FAILURE,
  SET_ORGANIZATION_SETTINGS_REQUEST,
  SET_ORGANIZATION_SETTINGS_SUCCESS,
  SetOrganizationSettingsDataAction,
  SetOrganizationSettingsFailureAction,
  SetOrganizationSettingsRequestAction,
} from "./types";
import { OrganizationSettingsData } from "../../models/organizationSettingsData";
import { enqueueSnackbar } from "notistack";
import { OrganizationSettingsService } from "../../api/organization/organizationSettingsService";
import { Dispatch } from "react";
import { getDynamicTranslation } from "../../components/core/localization/localizationUtils";

export const loadOrganizationSettingsRequest =
  (): LoadOrganizationSettingsRequestAction => {
    return {
      type: LOAD_ORGANIZATION_SETTINGS_REQUEST,
    };
  };

export const loadOrganizationSettingsSuccess = (
  data: OrganizationSettingsData
): LoadOrganizationSettingsDataAction => {
  return {
    type: LOAD_ORGANIZATION_SETTINGS_SUCCESS,
    data,
  };
};

export const loadOrganizationSettingsFailure = (
  error: string
): LoadOrganizationSettingsFailureAction => {
  return {
    type: LOAD_ORGANIZATION_SETTINGS_FAILURE,
    error,
  };
};

export const setOrganizationSettingsRequest =
  (): SetOrganizationSettingsRequestAction => {
    return {
      type: SET_ORGANIZATION_SETTINGS_REQUEST,
    };
  };

export const setOrganizationSettingsSuccess = (
  data: OrganizationSettingsData
): SetOrganizationSettingsDataAction => {
  return {
    type: SET_ORGANIZATION_SETTINGS_SUCCESS,
    data,
  };
};

export const setOrganizationSettingsFailure = (
  error: string
): SetOrganizationSettingsFailureAction => {
  return {
    type: SET_ORGANIZATION_SETTINGS_FAILURE,
    error,
  };
};

/**
 * Action creator to fetch the organization settings from the API.
 * @param {string} organizationId - The ID of the organization to fetch settings for.
 * @returns {Function} Thunk action.
 */
export const loadOrganizationSettings = (organizationId: string) => {
  return async (dispatch: Dispatch<OrganizationSettingsActionTypes>) => {
    dispatch(loadOrganizationSettingsRequest());
    try {
      const response = await new OrganizationSettingsService().getOrganizationSettings(
        organizationId
      );
      dispatch(loadOrganizationSettingsSuccess(response));
    } catch (error) {
      const errorMessage = getDynamicTranslation(
        "organization.loadOrgaSettingsError",
        "snackbars"
      );
      dispatch(loadOrganizationSettingsFailure(errorMessage));
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  };
};

/**
 * Action creator to update the organization settings.
 * @param {string} organizationSettingsId - The ID of the organization setting to be updated.
 * @param {OrganizationSettingsData} data - The updated settings data.
 * @returns {Function} Thunk action.
 */
export const setOrganizationSettings = (
  organizationSettingsId: string,
  data: OrganizationSettingsData
) => {
  return async (dispatch: Dispatch<OrganizationSettingsActionTypes>) => {
    dispatch(setOrganizationSettingsRequest());
    try {
      const response = await new OrganizationSettingsService().setOrganizationSettings(
        organizationSettingsId,
        data
      );
      dispatch(setOrganizationSettingsSuccess(response));
      enqueueSnackbar(
        getDynamicTranslation("organization.orgaSettingsSuccess", "snackbars"),
        {
          variant: "success",
        }
      );
    } catch (error) {
      const errorMessage = getDynamicTranslation(
        "organization.orgaSettingsError",
        "snackbars"
      );
      dispatch(setOrganizationSettingsFailure(errorMessage));
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  };
};
