import { EventStatus } from "../../../../../models/enums/eventStatus.enum";
import { AccessibilityState } from "../../../../../redux/accessibility/types";

/**
 * Determines the status color and text based on the event workload, status, accessibility, and other factors.
 *
 * @param {number} eventWorkload - The percentage of seats taken for the event.
 * @param {EventStatus} statusId - The current status of the event.
 * @param {AccessibilityState} accessibility - The accessibility state, including monoMode.
 * @param {boolean} maxSeatsTakenOrReserved - Whether the maximum number of seats have been taken or reserved.
 * @param {boolean} isElearning - Indicates if the event is an e-learning session.
 * @param {boolean} signUpStopped - Indicates if sign-up for the event has been stopped.
 * @returns {{ color: string; text: string }} - An object containing the CSS class for the status indicator and the corresponding status text.
 */
export const getStatusInfo = (
  eventWorkload: number,
  statusId: EventStatus,
  accessibility: AccessibilityState,
  maxSeatsTakenOrReserved: boolean,
  isElearning: boolean,
  signUpStopped: boolean
): { color: string; text: string } => {
  if (statusId === EventStatus.Completed || statusId === EventStatus.NotVisible) {
    return {
      color: "event-item-status-dot event-item-dot-grey",
      text: "notVisible",
    };
  }
  if (
    ((eventWorkload >= 100 ||
      statusId === EventStatus.Full ||
      statusId === EventStatus.Cancelled ||
      maxSeatsTakenOrReserved) &&
      !isElearning) ||
    signUpStopped
  ) {
    return {
      color: accessibility.monoMode
        ? "event-item-status-dot event-item-dot-mono-full"
        : "event-item-status-dot event-item-dot-red",
      text: "none",
    };
  } else if (eventWorkload < 80 || isElearning) {
    return {
      color: accessibility.monoMode
        ? "event-item-status-dot event-item-dot-mono-published"
        : "event-item-status-dot event-item-dot-green",
      text: "high",
    };
  } else if (eventWorkload >= 80 && !isElearning) {
    return {
      color: accessibility.monoMode
        ? "event-item-status-dot event-item-dot-mono-almost-full"
        : "event-item-status-dot event-item-dot-yellow",
      text: "low",
    };
  }
  return {
    color: "event-item-status-dot event-item-dot-default",
    text: "unknown",
  };
};
