import apiRoutes from "../apiRoutes";
import { BaseCrudService } from "../baseCrudService";
import {
  OrganizationMembershipData,
  OrganizationMembershipActivationData,
  OrganizationMembershipInviteData,
} from "../../models/organizationMembershipData";

/**
 * Service class for managing organization memberships.
 * Provides methods for retrieving organization membership data.
 */
export class OrganizationMembershipService extends BaseCrudService {
  /**
   * Retrieves all organization memberships from the API.
   * @returns {Promise<OrganizationMembershipData[]>} A promise that resolves to an array of organization membership data.
   */
  async getOrganizationMemberships() {
    const response = await this.getAll<OrganizationMembershipData[]>(
      apiRoutes.organization_membership
    );
    return response.data;
  }

  /**
   * Retrieves organization memberships by organization ID from the API.
   * @param {string} organizationId - The ID of the organization.
   * @returns {Promise<OrganizationMembershipData[]>} A promise that resolves to an array of organization membership data.
   */
  async getMembershipsByOrganizationId(organizationId: string) {
    const response = await this.getAll<OrganizationMembershipData[]>(
      `${apiRoutes.organization}/${organizationId}/memberships`
    );
    return response.data;
  }

  /**
   * Removes an organization membership.
   * @param {string} membershipId - The ID of the organization membership to remove.
   * @returns {Promise<void>} A Promise that resolves once the membership is removed.
   * @throws {Error} If there is an error while removing the membership.
   */
  async removeMembership(membershipId: string) {
    const response = await this.delete(apiRoutes.organization_membership, membershipId);
    return response.data;
  }

  /**
   * Accepts an organization membership invitation.
   * @param {string} membershipId - The ID of the membership to accept.
   * @returns {Promise<any>} A promise that resolves with the response data when the membership is accepted.
   */
  async acceptMembership(membershipId: string) {
    const response = await this.get(
      apiRoutes.organization_membership,
      membershipId + apiRoutes.accept_membership
    );
    return response.data;
  }

  /**
   * Patches the is_admin value of an organization membership.
   * @param {string} membershipId - The ID of the membership to be updated.
   * @param {object} data - The admin data to be updated with boolean is_admin.
   * @returns {Promise<any>} A promise that resolves with the response data when the membership' admin status is updated.
   * @throws {Error} If there is an error while patching the membership.
   */
  async patchMembershipAdminStatus(membershipId: string, data: { is_admin: boolean }) {
    const response = await this.edit(
      apiRoutes.organization_membership,
      membershipId + apiRoutes.patch_membership_admin_status,
      data
    );
    return response.data;
  }

  /**
   * Invites a user to join an organization.
   * @param {string} email - The email of the user to invite.
   * @param {string} firstName - The first name of the user to invite.
   * @param {string} lastName - The last name of the user to invite.
   * @param {string} organizationId - The ID of the organization to invite the user to.
   * @returns {Promise<boolean>} A promise that resolves to true if the invitation was successful, otherwise false.
   */
  async inviteUserToOrganization(data: OrganizationMembershipInviteData) {
    const response = await this.create(apiRoutes.invite_user_to_organization, data);
    return response.data;
  }

  /**
   * Activates an organization assignment.
   * @param {OrganizationMembershipActivationData} data - The organization assignment activation data.
   * @returns {Promise<boolean>} A promise that resolves to a boolean indicating the success of the operation.
   */
  async activateOrganizationAssignment(data: OrganizationMembershipActivationData) {
    try {
      await this.create<OrganizationMembershipActivationData>(
        apiRoutes.activate_organization_membership,
        data
      );
      return true;
    } catch (error) {
      return false;
    }
  }
}
