/* eslint-disable */
import { SelectData } from "../../components/forms/selects/selectData";
import { Utils } from "../../services/utils";
import { getDynamicTranslation } from "../../components/core/localization/localizationUtils";

export enum ParticipationStatus {
  Undefined = 0,
  WaitingList = 10,
  Booked = 20,
  Cancelled = 30,
  CancelledByStaff = 40,
  Participated = 50,
  NotParticipated = 60,
  SpeakingEvent = 80,
  ReservedCart = 90,
  IsFavorite = 100,
  CertificateRevoked = 110,
}

export enum ELearningParticipationStatus {
  Passed = 51,
  NotPassed = 61,
}

export const statusMainTranslationKeys: Record<string, string> = {
  [ParticipationStatus.WaitingList]: "participationStatus.waitingList",
  [ParticipationStatus.Booked]: "participationStatus.bookedInfo",
  [ParticipationStatus.Participated]: "participationStatus.participatedInfo",
  [ParticipationStatus.NotParticipated]: "participationStatus.notParticipated",
};

export function mapToParticipationStatusesString(status?: ParticipationStatus): string {
  switch (status) {
    case ParticipationStatus.Undefined:
      return "";
    case ParticipationStatus.WaitingList:
      return getDynamicTranslation("participationStatus.waitingList", "enums");
    case ParticipationStatus.Booked:
      return getDynamicTranslation("participationStatus.booked", "enums");
    case ParticipationStatus.Cancelled:
      return getDynamicTranslation("participationStatus.cancelled", "enums");
    case ParticipationStatus.CancelledByStaff:
      return getDynamicTranslation("participationStatus.cancelledByStaff", "enums");
    case ParticipationStatus.Participated:
      return getDynamicTranslation("participationStatus.participated", "enums");
    case ParticipationStatus.NotParticipated:
      return getDynamicTranslation("participationStatus.notParticipated", "enums");
    case ParticipationStatus.CertificateRevoked:
      return getDynamicTranslation("participationStatus.participationRevoked", "enums");
    default:
      return "";
  }
}

// TODO should not be mapped based on translated strings
export function mapToParticipationStatus(status?: string): ParticipationStatus {
  switch (status) {
    case "":
      return ParticipationStatus.Undefined;
    case getDynamicTranslation("participationStatus.waitingList", "enums"):
      return ParticipationStatus.WaitingList;
    case getDynamicTranslation("participationStatus.booked", "enums"):
      return ParticipationStatus.Booked;
    case getDynamicTranslation("participationStatus.cancelled", "enums"):
      return ParticipationStatus.Cancelled;
    case getDynamicTranslation("participationStatus.cancelledByStaff", "enums"):
      return ParticipationStatus.CancelledByStaff;
    case getDynamicTranslation("participationStatus.participated", "enums"):
      return ParticipationStatus.Participated;
    case getDynamicTranslation("participationStatus.notParticipated", "enums"):
      return ParticipationStatus.NotParticipated;
    case getDynamicTranslation("participationStatus.participationRevoked", "enums"):
      return ParticipationStatus.CertificateRevoked;
    default:
      return ParticipationStatus.Undefined;
  }
}

/**
 * Returns the SelectData[] for the participation status select.
 * @param status participation status enum
 */
export function getParticipationStatusForSelect(): SelectData[] {
  // get just the number values of the enum
  let statuses = Object.values(ParticipationStatus).filter(
    (value) => typeof value === "number"
  ) as ParticipationStatus[];

  statuses = statuses.filter(
    (x) =>
      x !== ParticipationStatus.Undefined &&
      x !== ParticipationStatus.IsFavorite &&
      x !== ParticipationStatus.ReservedCart &&
      x !== ParticipationStatus.SpeakingEvent
  );

  const allMappedStatuses = statuses.map((x) => {
    // map statuses to strings to display readable status value in UI
    return mapToParticipationStatusesString(x);
  });

  return allMappedStatuses.map((value) => {
    return {
      key: Utils.getEnumKeyByValue(ParticipationStatus, mapToParticipationStatus(value)),
      value: mapToParticipationStatus(value),
      label: value,
    } as SelectData;
  });
}
