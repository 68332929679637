import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { defaultLanguage } from "../components/core/localization/localizationUtils";

const namespaces = [
  "common",
  "authCapture",
  "authentication",
  "booking",
  "contactForm",
  "cookies",
  "enums",
  "errorPages",
  "events",
  "invoices",
  "layout",
  "lumos",
  "navigationData",
  "organization",
  "participationTypes",
  "snackbars",
  "validations",
];

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: defaultLanguage.value,
    load: "languageOnly",
    ns: namespaces,
    defaultNS: "common",
    initImmediate: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/static/locales/{{lng}}/{{ns}}.json",
    },
  });

export default i18n;
