import {
  CLEAR_INVOICE,
  InvoiceActionTypes,
  INVOICE_FAILURE,
  InvoiceState,
  INVOICE_REQUEST,
  SET_INVOICE_DATA,
  SET_MY_INVOICE_LIST_DATA,
  SET_INVOICES_PAGE_COUNT,
} from "./types";
import { NIL as NIL_UUID } from "uuid";

export const initialInvoiceState: InvoiceState = {
  invoiceData: {
    id: NIL_UUID,
    file_name: "",
    order_id: 0,
    invoice_number: "",
  },
  invoiceList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  invoiceListPrint: [],
  currentPage: 1,
  isLoading: false,
  invoiceListLoaded: false,
};

export function invoiceReducer(
  state = initialInvoiceState,
  action: InvoiceActionTypes
): InvoiceState {
  switch (action.type) {
    case INVOICE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case INVOICE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case CLEAR_INVOICE:
      return initialInvoiceState;
    case SET_INVOICE_DATA:
      return {
        ...state,
        invoiceData: action.invoice,
        isLoading: false,
      };
    case SET_MY_INVOICE_LIST_DATA:
      return {
        ...state,
        invoiceList: action.data,
        invoiceListLoaded: true,
        isLoading: false,
      };
    case SET_INVOICES_PAGE_COUNT:
      return {
        ...state,
        currentPage: action.pageCount,
        invoiceListLoaded: false,
      };
    default:
      return state;
  }
}

export default invoiceReducer;
