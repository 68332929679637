import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux";
import { useEffect, useState } from "react";
import {
  fetchMembershipDocuments,
  removeGroupMembership,
} from "../../../../redux/group-membership-documents/actions";
import { Badge, Grid, Typography, Box } from "@material-ui/core";
import { ImageWithDeleteIcon } from "../../events/shared/misc/ImageWithDeleteIcon";
import {
  getLableForMembershipDocumentType,
  MembershipDocumentReponseDto,
} from "../../../../models/groupMembership";
import {
  getCurrentLoggedInUser,
  getCurrentUserData,
} from "../../../../redux/users/actions";
import { formatDate } from "../../../../libs/date";
import { showDialog } from "../../../../redux/dialogs/actions";
import { useTranslation } from "react-i18next";
import { getCurrentLanguage } from "../../localization/localizationUtils";

const statusMappings = {
  approved: {
    batchContentTranslKey: "status.confirmed",
    color: "#999",
  },
  pending: {
    batchContentTranslKey: "status.waiting",
    color: "error",
  },
  rejected: {
    batchContentTranslKey: "status.rejected",
    color: "error",
  },
  expired: {
    batchContentTranslKey: "status.expired",
    color: "error",
  },
};

/**
 * Component to display a list of membership documents.
 * It fetches and displays the documents with their details, status, and delete option.
 */
export const MembershipDocumentList = () => {
  const { t } = useTranslation(["common", "layout", "participationTypes", "snackbars"]);
  const dispatch = useDispatch();
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const [userIsLoading, setUserIsLoading] = useState<boolean>(false);

  const groupMemberShipDocuments = useSelector(
    (state: AppState) => state.groupMemberShipDocuments
  );

  /* TODO Refactor to avoid workaround and multiple rerendering */

  useEffect(() => {
    if (!currentUser) {
      getCurrentUserData(dispatch);
    }
  }, [userIsLoading]);

  useEffect(() => {
    if (userIsLoading) {
      getCurrentUserData(dispatch);
      setUserIsLoading(false);
    }
  }, [dispatch, currentUser]);

  useEffect(() => {
    dispatch(fetchMembershipDocuments());
  }, [dispatch]);

  const groupMembershipDocumentTypes = useSelector(
    (state: AppState) => state.groupMemberShipDocumentTypes
  );
  const getDocumentType = (document: MembershipDocumentReponseDto) => {
    const currentLanguage = `label_${getCurrentLanguage()}` as keyof typeof documentType;
    const documentType = groupMembershipDocumentTypes.groupMembershipDocumentTypes.find(
      (docType) => {
        return docType.id === document.document_type ? docType : null;
      }
    );

    return documentType ? documentType[currentLanguage] : "";
  };

  const memberships = groupMemberShipDocuments?.memberships ?? [];

  return (
    <Grid container spacing={4} style={{ marginBottom: "1rem" }}>
      {memberships.map((item) => {
        const documentStatus = item?.document?.document_status;

        const statusInfo = statusMappings[documentStatus] || {
          error: {
            batchContent: t("error", { ns: "common" }),
            color: "error",
          },
        };
        const color = statusInfo.color as "error" | "primary";

        const handleDelete = () => {
          dispatch(
            showDialog({
              title: t("dialogues.deleteMembershipDoc.title", { ns: "layout" }),
              message: t("dialogues.deleteMembershipDoc.text", {
                ns: "layout",
              }),
              action: () => {
                dispatch(removeGroupMembership(item));
                setUserIsLoading(true);
                dispatch(getCurrentLoggedInUser());
              },
            })
          );
        };

        return (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            <Typography color="primary" variant="h6">
              {t(getLableForMembershipDocumentType(item.document.document_type), {
                ns: "participationTypes",
              })}
            </Typography>

            <Badge
              style={{
                width: 320,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ImageWithDeleteIcon
                src={item.document.file}
                alt={
                  t("document.description", { ns: "participationTypes" }) +
                  " " +
                  getDocumentType(item.document)
                }
                fileExtention={item.document?.file_extension}
                title={t("documentNaNpxove", { ns: "participationTypes" })}
                onDelete={() => handleDelete()}
                validUntil={formatDate(item.document.valid_until)}
                docHasNoExpiryDate={item.document.document_has_no_expiry_date}
              />
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "flex-end",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Typography color={color} style={{ fontSize: "1rem" }}>
                  Status:{" "}
                  {t(statusInfo.batchContentTranslKey, {
                    ns: "participationTypes",
                  })}
                </Typography>
              </Box>
            </Badge>
          </Grid>
        );
      })}
    </Grid>
  );
};
