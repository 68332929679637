/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import { AppState } from "../index";
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { bundIDService } from "../../api";
import {
  CLEAR_BUND_ID,
  ClearBundIDAction,
  BundIDActionTypes,
  BUND_ID_FAILURE,
  BUND_ID_REQUEST,
  BundIDRequestAction,
  BundIDFailureAction,
  BundIDSuccessAction,
  SetBundIDDataAction,
  SetBundIDRequestDataAction,
  SET_BUND_ID_DATA,
  SET_BUND_ID_REQUEST_DATA,
  BUND_ID_SUCCESS,
} from "./types";
import { BundIDData, BundIDRequestData } from "../../models/bundIDData";

export function createBundIDRequest(eventParticipation: string) {
  return (dispatch: Dispatch<BundIDActionTypes>, getState: () => AppState) => {
    dispatch(bundIDRequest());

    return bundIDService
      .createBundIDRequest(eventParticipation)
      .then((bundIDRequestData) => {
        dispatch(setBundIDRequestData(bundIDRequestData));
      })
      .catch((err: AxiosError) => {
        const errorStatus = err.response?.status;
        const errorObject = err.response?.data;
        dispatch(bundIDFailure());
      });
  };
}

export function getBundIDdata() {
  return (dispatch: Dispatch<BundIDActionTypes>, getState: () => AppState) => {
    dispatch(bundIDRequest());
    return bundIDService
      .getBundIDData()
      .then((bundIDCheckout) => {
        dispatch(setBundIDData(bundIDCheckout));
        bundIDService.getBundIDRequestData().then((bundIDRequestData) => {
          dispatch(setBundIDRequestData(bundIDRequestData));
          dispatch(bundIDSuccess());
        });
      })
      .catch((err: AxiosError) => {
        const errorStatus = err.response?.status;
        const errorObject = err.response?.data;
        dispatch(bundIDFailure());
      });
  };
}

export function getBundIDRequestdata() {
  return (dispatch: Dispatch<BundIDActionTypes>, getState: () => AppState) => {
    dispatch(bundIDRequest());
    return bundIDService
      .getBundIDRequestData()
      .then((bundIDRequestData) => {
        dispatch(setBundIDRequestData(bundIDRequestData));
      })
      .catch((err: AxiosError) => {
        const errorStatus = err.response?.status;
        const errorObject = err.response?.data;
        dispatch(bundIDFailure());
      });
  };
}

export function clearBundID(): ClearBundIDAction {
  return { type: CLEAR_BUND_ID };
}

export function bundIDRequest(): BundIDRequestAction {
  return {
    type: BUND_ID_REQUEST,
  };
}

export function bundIDFailure(): BundIDFailureAction {
  return {
    type: BUND_ID_FAILURE,
  };
}

export function bundIDSuccess(): BundIDSuccessAction {
  return {
    type: BUND_ID_SUCCESS,
  };
}

export function setBundIDData(bundID: BundIDData): SetBundIDDataAction {
  return {
    type: SET_BUND_ID_DATA,
    bundID,
  };
}

export function setBundIDRequestData(
  bundID: BundIDRequestData
): SetBundIDRequestDataAction {
  return {
    type: SET_BUND_ID_REQUEST_DATA,
    bundID,
  };
}
