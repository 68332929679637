import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { CartData } from "../models/cartData";

export class CartService extends BaseCrudService {
  async getCart() {
    const response = await this.getAll<CartData>(apiRoutes.cart);
    return response.data;
  }

  async addToCart(eventId: string, userId: string) {
    const response = await this.create(apiRoutes.cart_items, {
      event: eventId,
      book_for: userId,
    });
    return response;
  }

  async removeFromCart(eventId: string) {
    const response = await this.delete(apiRoutes.cart_items, eventId);
    return response;
  }

}
