import React, { useEffect } from "react";
import { Box, Button, Grid, Typography, Hidden } from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  LumosEducationSvg,
  LumosMapsSvg,
  LumosPointsSvg,
  LumosPriceSvg,
} from "../../../../../assets/icons/custom-svg-components/LumosSvgCollection";
import { EventListData } from "../../../../../models/eventData";
import { AppState } from "../../../../../redux";
import EventItemEndDateTime from "./date-time/EventItemEndDateTime";
import EventItemStartDateTime from "./date-time/EventItemStartDateTime";
import { getStatusInfo } from "./EventItemService";
import { EventItemBooking } from "./EventItemBooking";
import EventItemStatusText from "./EventItemStatusText";
import { mapStatusIdToStatusString } from "../../../../../models/enums/eventStatus.enum";
import { Utils } from "../../../../../services/utils";
import { UserType } from "../../../../../models/enums/userType.enum";
import usePermission from "../../../../../services/usePermissions";
import {
  mapToTrainingTypeString,
  TrainingType,
} from "../../../../../models/enums/trainingType.enum";
import { EventType } from "../../../../../models/enums/eventType.enum";
import { useTranslation } from "react-i18next";

interface EventItemPropertiesProps {
  event: EventListData;
  preview?: boolean;
}

/**
 * Displays the event properties, such as the event type, date and time, location, price, and points.
 * Also displays the status of the event.
 * @param {EventItemPropertiesProps} props - The properties of the component.
 * @returns {JSX.Element} - The component.
 */
export const EventItemProperties: React.FC<EventItemPropertiesProps> = (props) => {
  const { t } = useTranslation(["events"]);
  const { event, preview } = props;
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const accessibility = useSelector((state: AppState) => state.accessibility);

  const { isStaff } = usePermission(currentUser?.user_type);

  const [eventWorkload, setEventWorkload] = React.useState(0);

  useEffect(() => {
    const percentage =
      event.training_type === TrainingType.ELearning
        ? 0
        : (event.seats_taken * 100) / event.seats;
    setEventWorkload(percentage);
  }, [event]);

  const { color, text } = getStatusInfo(
    eventWorkload,
    event.status,
    accessibility,
    event.seats_taken >= event.seats,
    event.training_type === TrainingType.ELearning,
    event.is_capped
  );

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={4} md={12}>
          <Box component="span" display="flex" alignItems="center">
            <Hidden only={["xs", "md", "lg", "xl"]}>
              <Box flexGrow={1} />
            </Hidden>
            <LumosEducationSvg />
            {event.training_type === TrainingType.DefaultEvent
              ? `${
                  event.event_type === EventType.OnSite
                    ? t("eventLocation.onSiteEvent", { ns: "events" })
                    : t("eventLocation.onlineEvent", { ns: "events" })
                }`
              : mapToTrainingTypeString(event.training_type)}
          </Box>
        </Grid>
        {event.training_type !== TrainingType.ELearning && event.begin && event.end ? (
          <>
            <Grid item xs={12} sm={6} md={12}>
              <Box component="span" display="flex" alignItems="center">
                <EventItemStartDateTime
                  startDate={new Date(event.begin)}
                  endDate={new Date(event.end)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
              <Box component="span" display="flex" alignItems="center">
                <Hidden only={["xs", "md", "lg", "xl"]}>
                  <Box flexGrow={1} />
                </Hidden>
                <EventItemEndDateTime
                  startDate={new Date(event.begin)}
                  endDate={new Date(event.end)}
                />
              </Box>
            </Grid>
          </>
        ) : null}
        {event.event_type === EventType.OnSite && (
          <Grid item xs={12} sm={4} md={12}>
            <LumosMapsSvg />
            {event.location}
          </Grid>
        )}
        <Grid item xs={12} sm={4} md={12}>
          <Box component="span" display="flex" alignItems="center">
            <Hidden only={["xs", "md", "lg", "xl"]}>
              <Box flexGrow={1} />
            </Hidden>
            <LumosPriceSvg />
            {Utils.formatPrice(event.total_amount)}
            {event.total_amount !== 0 ? (
              <>
                {" "}
                {t("incl", { ns: "events" })}{" "}
                {Utils.formatTax(event.value_added_tax_rate)} %{" "}
                {t("tax", { ns: "events" })}
              </>
            ) : null}
            <Hidden only={["xs", "md", "lg", "xl"]}>
              <Box flexGrow={1} />
            </Hidden>
          </Box>
        </Grid>
        {event.score && event.score > 0 ? (
          <Grid item xs={12} sm={4} md={12}>
            <Box component="span" display="flex" alignItems="center">
              <Hidden only={["xs", "md", "lg", "xl"]}>
                <Box flexGrow={1} />
              </Hidden>
              <LumosPointsSvg />
              {event.score} {t("points", { ns: "events" })}
            </Box>
          </Grid>
        ) : null}
      </Grid>

      {!preview && (
        <Grid container style={{ bottom: "0rem" }}>
          <Grid item xs={3} />
          <Grid item xs={9}>
            <Box component="div" className="event-item-status-text">
              {event.training_type !== TrainingType.ELearning && (
                <EventItemStatusText
                  status={event.status}
                  slots={event.seats}
                  slots_taken={event.seats_taken}
                  eventWorkload={eventWorkload}
                  waiting_list={event.waiting_list}
                />
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Box
              className={color}
              style={{
                marginLeft: "0rem",
                width: 20,
                height: 20,
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexShrink: 0,
              }}
            />
            <Typography>{t(`availability.${text}`, { ns: "events" })}</Typography>
          </Grid>
          {!isStaff() ? (
            <Grid item xs={12} style={{ marginTop: "1rem" }}>
              {currentUser?.user_type === UserType.Speaker ? (
                <Button
                  disabled
                  id="event-item-status"
                  size="medium"
                  variant="contained"
                  fullWidth
                  style={{ marginLeft: "auto" }}
                >
                  {mapStatusIdToStatusString(event.status)}
                </Button>
              ) : (
                <EventItemBooking eventWorkload={eventWorkload} eventData={event} />
              )}
            </Grid>
          ) : null}
        </Grid>
      )}
    </>
  );
};
