/* eslint-disable camelcase */
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
} from "@material-ui/core";
import {
  fetchMembershipsByOrganizationId,
  patchMembershipAdminStatus,
  removeMembership,
} from "../../../../../redux/organizationMembership/action";
import { showDialog } from "../../../../../redux/dialogs/actions";
import React, { useEffect, useState } from "react";
import CustomCheckbox from "../../../../forms/CustomCheckbox";
import { AppState } from "../../../../../redux";
import { Field, Formik } from "formik";

/**
 * @interface MembershipInterface
 */
export interface MembershipInterface {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  status: string;
  is_admin: boolean;
  user: string;
}

/**
 * @interface MembershipsListProps
 */
interface MembershipsListProps {
  memberships: MembershipInterface[];
  organizationId: string;
}

/**
 * @interface RemoveMembershipParams
 */
interface RemoveMembershipParams {
  membershipId: string;
  title: string;
  confirmationText: string;
  confirmButtonText: string;
}

/**
 * Component for rendering a list of organization memberships.
 * @param {Object} props - Component props.
 * @param {Array} props.memberships - List of organization memberships.
 * @param {string} props.organizationId - ID of the organization.
 * @returns {JSX.Element} - Rendered component.
 */
const MembershipsList = ({
  memberships,
  organizationId,
}: MembershipsListProps): JSX.Element => {
  const { t } = useTranslation(["common", "organization"]);
  const dispatch = useDispatch();
  const currentUser = useSelector((state: AppState) => state.user.currentUser);

  /**
   * Handles the removal of a membership with confirmation dialog.
   *
   * @param {string} membershipId - The ID of the membership to be removed.
   * @param {string} title - The title of the confirmation dialog.
   * @param {string} confirmationText - The confirmation message shown to the user.
   * @param {string} confirmButtonText - The text displayed on the confirmation button.
   * @returns {Promise<void>} - A promise that resolves after the membership is removed and the list is refreshed.
   */
  const removeMembershipHandler = async ({
    membershipId,
    title,
    confirmationText,
    confirmButtonText,
  }: RemoveMembershipParams): Promise<void> => {
    dispatch(
      showDialog({
        title: title,
        message: confirmationText,
        confirmButtonText: confirmButtonText,
        action: async () => {
          await dispatch(removeMembership(membershipId) as unknown as Promise<void>);
          await dispatch(
            fetchMembershipsByOrganizationId(organizationId) as unknown as Promise<void>
          );
        },
      })
    );
  };

  const [adminCount, setAdminCount] = useState(0);

  useEffect(() => {
    const count = memberships.filter((membership) => membership.is_admin).length;
    setAdminCount(count);
  }, [memberships]); //

  /**
   * Handles the update of a membership's admin status.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event triggered by the Formik admin status checkbox input.
   * @param {string} membershipId - The ID of the membership to be updated.
   * @returns {Promise<void>} - A promise that resolves after the membership's admin status is updated and the list is refreshed.
   */
  const handleAdminStatusChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    membershipId: string
  ) => {
    await dispatch(
      patchMembershipAdminStatus(membershipId, {
        is_admin: event.target.checked,
      }) as unknown as Promise<void>
    );
    await dispatch(
      fetchMembershipsByOrganizationId(organizationId) as unknown as Promise<void>
    );
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>
              {t("profile.firstName", { ns: "common" })}
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              {t("profile.lastName", { ns: "common" })}
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              {t("profile.mail", { ns: "common" })}
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              {t("membership.status", { ns: "organization" })}
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              {t("membership.admin", { ns: "organization" })}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {memberships?.map((membership: MembershipInterface) => {
            return (
              <TableRow key={membership.id}>
                <TableCell>{membership.firstname}</TableCell>
                <TableCell>{membership.lastname}</TableCell>
                <TableCell>{membership.email}</TableCell>
                <TableCell>
                  {membership.status === "pending"
                    ? t("membership.invited", { ns: "organization" })
                    : membership.is_admin
                      ? t("membership.admin", { ns: "organization" })
                      : t("membership.member", { ns: "organization" })}
                </TableCell>
                <TableCell>
                  <Formik
                    initialValues={{ setAdminStatus: membership.is_admin }}
                    onSubmit={() => {}}
                  >
                    {({ setFieldValue }) => (
                      <Field
                        name={`setAdminStatus_${membership.id}`}
                        value={membership.is_admin}
                      >
                        {() => (
                          <CustomCheckbox
                            name={membership.id}
                            type="checkbox"
                            label=""
                            tooltipText={t("forms.disabledAdminStatusTooltip", {
                              ns: "organization",
                            })}
                            checked={membership.is_admin}
                            disabled={
                              (adminCount === 1 && membership.is_admin) ||
                              membership.user === currentUser?.id
                            }
                            onChange={async (
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setFieldValue(
                                `setAdminStatus_${membership.id}`,
                                event.target.checked
                              );
                              await handleAdminStatusChange(event, membership.id);
                            }}
                          />
                        )}
                      </Field>
                    )}
                  </Formik>
                </TableCell>
                <TableCell>
                  <Button
                    size="large"
                    variant="outlined"
                    color="secondary"
                    onClick={() =>
                      removeMembershipHandler({
                        membershipId: membership.id,
                        title:
                          membership?.status === "pending"
                            ? t("membership.revokeMembershipInvitation", {
                                ns: "organization",
                              })
                            : t("membership.revokeMembership", { ns: "organization" }),
                        confirmationText:
                          membership?.status === "pending"
                            ? t(
                                "membership.revokeMembershipInvitationConfirmationText",
                                {
                                  firstname: membership?.firstname,
                                  lastname: membership?.lastname,
                                  ns: "organization",
                                }
                              )
                            : t("membership.revokeMembershipConfirmationText", {
                                firstname: membership?.firstname,
                                lastname: membership?.lastname,
                                ns: "organization",
                              }),
                        confirmButtonText:
                          membership?.status === "pending"
                            ? t(
                                "membership.revokeMembershipInvitationConfirmationButton",
                                {
                                  ns: "organization",
                                }
                              )
                            : t("membership.revokeMembership", { ns: "organization" }),
                      })
                    }
                  >
                    {membership.status === "pending"
                      ? t("membership.revokeMembershipInvitation", {
                          ns: "organization",
                        })
                      : t("membership.revokeMembership", { ns: "organization" })}
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MembershipsList;
