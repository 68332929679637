import { enqueueSnackbar } from "notistack";
import { videoAuthService } from "../../api";
import { CaptureMetaData } from "../../components/core/events/shared/authentication/Instructions";
import { store } from "../index";
import {
  AUTHENTICATION_ADD_CAPTURE,
  AUTHENTICATION_ADD_PASS_CAPTURE,
  AUTHENTICATION_ERROR,
  AUTHENTICATION_FAILURE,
  AUTHENTICATION_REMOVE_CAPTURE,
  AUTHENTICATION_REMOVE_PASS_CAPTURE,
  AUTHENTICATION_RESET,
  AUTHENTICATION_SUCCESS,
  SET_CAPTURE_META,
  SET_DOCUMENT_CAPTURE_RECT,
  SET_PARTICIPANT_CAPTURE_RECT,
} from "./types";
import moment from "moment";
import AusweisIDentDialog from "../../components/layout/dialog/AusweisIDentDialog";
import { getDynamicTranslation } from "../../components/core/localization/localizationUtils";

export function addImageCapture(capture: string) {
  return {
    type: AUTHENTICATION_ADD_CAPTURE,
    capture,
  };
}
export function removeImageCapture(capture: string) {
  return {
    type: AUTHENTICATION_REMOVE_CAPTURE,
    capture,
  };
}

export function addDocumentCapture(capture: string) {
  return {
    type: AUTHENTICATION_ADD_PASS_CAPTURE,
    capture,
  };
}

export function removePassImageCapture(capture: string) {
  return {
    type: AUTHENTICATION_REMOVE_PASS_CAPTURE,
    capture,
  };
}

export function abortVideoAuth() {
  const { dispatch } = store;
  dispatch({ type: AUTHENTICATION_RESET });
}

export function setCaptureMetaData(metaData: CaptureMetaData) {
  const { dispatch } = store;
  dispatch({ type: SET_CAPTURE_META, metaData });
}

export function setParticipantCaptureRect({
  x,
  y,
  width,
  height,
}: {
  x: number;
  y: number;
  width: number;
  height: number;
}): any {
  return {
    type: SET_PARTICIPANT_CAPTURE_RECT,
    x: x,
    y: y,
    width: width,
    height: height,
  };
}
export function setDocumentCaptureRect({
  x,
  y,
  width,
  height,
}: {
  x: number;
  y: number;
  width: number;
  height: number;
}): any {
  return {
    type: SET_DOCUMENT_CAPTURE_RECT,
    x: x,
    y: y,
    width: width,
    height: height,
  };
}
export function requestAuthenticationWithImageCapture(elearningId: string) {
  const { getState, dispatch } = store;
  const {
    videoAuthentication: { captures, documentCaptures, metaData },
  } = getState();
  const capture = captures[0];
  const documentCapture = documentCaptures[0];

  const event_particiaption = elearningId;

  if (metaData === undefined) {
    return false;
  }

  const maxAuthenticationTrials: number = 5;

  metaData.participant_capture_file = capture;
  metaData.document_capture_file = documentCapture;
  metaData.event_participation = event_particiaption;
  metaData.birthday = moment(metaData.birthday).format("DD.MM.YYYY");

  if (!capture) {
    dispatch({
      type: AUTHENTICATION_ERROR,
      error: getDynamicTranslation("events.imageNotFound", "snackbars"),
    });
    return false;
  }

  /**
   * Initiates the video authentication process by sending the captured data to the authentication service.
   *
   * @param {string} elearningId - The unique identifier for the E-Learning session.
   *
   * @returns {void}
   */
  videoAuthService.sendCapture(metaData).then((response) => {
    /**
     * Handles the response from the authentication service after sending the capture data.
     * If authentication is confirmed, it dispatches a success action; otherwise, dispatches failure and error actions.
     *
     * @param {Object} response - The response object from the authentication service.
     */
    if (response?.data?.authentication_automatically_confirmed === true) {
      enqueueSnackbar(
        getDynamicTranslation("events.authenticationSuccess", "snackbars"),
        {
          variant: "success",
        }
      );
      // Dispatch success action with E-Learning ID
      dispatch({ type: AUTHENTICATION_SUCCESS, forElearning: elearningId });
    } else if (response?.data?.authentication_manually_confirmed === true) {
      enqueueSnackbar(
        getDynamicTranslation("events.authenticationSuccess", "snackbars"),
        {
          variant: "success",
        }
      );
      // Dispatch success action with E-Learning ID
      dispatch({ type: AUTHENTICATION_SUCCESS, forElearning: elearningId });
    } else {
      const errMessage = getDynamicTranslation(
        "events.authenticationError",
        "snackbars"
      );
      enqueueSnackbar(errMessage, { variant: "error" });
      if (response?.data?.trial >= maxAuthenticationTrials) {
        // TODO: Ticket LU-800: Uncomment underneath line if url for
        // AusweisIDent is available.
        // dispatch(AusweisIDentDialog());
      }
      // Dispatch failure and error actions with the error message
      dispatch({
        type: AUTHENTICATION_FAILURE,
        error: errMessage,
      });
      dispatch({
        type: AUTHENTICATION_ERROR,
        error: errMessage,
      });
    }
  }, console.error);
}
