import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { FeatureFlagData } from "../models/featureFlaggingData";

export class FeatureFlaggingService extends BaseCrudService {
  async getFeatureFlags() {
    const response = await this.getAll<FeatureFlagData>(apiRoutes.feature_flags);
    return response.data;
  }
}
