import {
  OnApproveActions,
  OnApproveData,
} from "@paypal/paypal-js/types/components/buttons";
import { PayPalButtons } from "@paypal/react-paypal-js";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PaypalData } from "../../../../../models/billingAddressData";
import {
  BookingDataWithPayment,
  BookingEventData,
} from "../../../../../models/bookingData";
import { AppState } from "../../../../../redux";
import {
  bookEvent,
  bookingsFailure,
  loadAfterCheckout,
} from "../../../../../redux/bookings/actions";
import { getCart } from "../../../../../redux/cart/actions";
import { history } from "../../../../../routing/history";
import routes from "../../../../../routing/routes";
import { paypalService } from "../../../../../api";
import { setPaypalOrderData } from "../../../../../redux/paypal/actions";
import { PaypalOrderData } from "../../../../../models/paypalData";
import { ReducedOrderData } from "../../../../../models/orderData";
import { NIL as NIL_UUID } from "uuid";
import { loggerService } from "../../../../../api";
import { LogLevelType } from "../../../../../models/enums/logLevelType.enum";

interface PaypalButtonProps {
  funding: "paypal" | "paylater" | "card" ;
  onClick?: () => void;
  orderData: ReducedOrderData;
}

export const PaypalButton: React.FC<PaypalButtonProps> = ({
  funding,
  onClick,
  orderData,
}) => {
  const dispatch = useDispatch();

  const cartState = useSelector((appState: AppState) => appState.cart);
  const billingAddressState = useSelector(
    (appState: AppState) => appState.billingAddress
  );

  const totalPrice = cartState.cart.gross_total;

  let paypal: PaypalOrderData = {
    id: "",
    transaction_id: "",
    url: "",
    order: "",
    useGiropay: false,
    status: "",
  };
  const isUserLoggedIn = useSelector((state: AppState) => state.auth.loggedIn);

  const handlePaypalResponse = (paypalData: PaypalData, orderData: ReducedOrderData) => {
    loggerService.addLog(
      "PaypalButton - handlePaypalResponse: " + paypalData.transaction_id,
      LogLevelType.Info
    );
    const events: BookingEventData[] = cartState.cart.cart_items.map((cartItem) => {
      return {
        event: cartItem.id,
        status: 20,
        catering_option: cartItem.catering_option,
        use_alternative_billing_address: true,
      };
    });

    const bookingDataWithPaypal: BookingDataWithPayment = {
      events,
      payment: {
        payment_method: billingAddressState.payment.payment_method,
        invoice_organization: {
          id: NIL_UUID,
        },
        invoice_is_for_user: true,
        paypal_data: {
          id: paypalData.id,
          transaction_id: paypalData.transaction_id,
          gross_amount: paypalData.gross_amount,
          capture_id: paypalData.capture_id,
          currency_code: paypalData.currency_code,
        },
        order: orderData,
      },
    };

    const book_for = cartState.cart.cart_items.find((item) => !!item.book_for?.id);
    if (book_for) {
      bookingDataWithPaypal.book_for = book_for.book_for.id;
    }
    loggerService.addLog(
      "PaypalButton - handlePaypalResponse: " + paypalData.transaction_id + " bookEvent",
      LogLevelType.Info
    );
    dispatch(bookEvent(bookingDataWithPaypal));
    loggerService.addLog(
      "PaypalButton - handlePaypalResponse: " +
        paypalData.transaction_id +
        " bookEvent finished",
      LogLevelType.Info
    );
  };

  useEffect(() => {
    if (!cartState.cartInitialLoaded && isUserLoggedIn) {
      dispatch(getCart());
    }
  }, [cartState.cart, cartState.cartInitialLoaded, dispatch, isUserLoggedIn]);

  const createPaypalOrder = (orderID: string, isGiropay: boolean): Promise<string> => {
    return paypalService.createPaypalOrder(orderID, isGiropay).then((paypalOrder) => {
      dispatch(setPaypalOrderData(paypalOrder));
      paypal = paypalOrder;
      return paypalOrder.transaction_id;
    });
  };

  const createOrder = (): Promise<string> => {
    let isGiropay: boolean = false;
    return createPaypalOrder(orderData.id, isGiropay);
  };

  const handleApprove = (
    data: OnApproveData,
    actions: OnApproveActions
  ): Promise<void> => {
    if (actions.order === undefined) {
      return Promise.reject("Order is undefined");
    }
    loggerService.addLog(
      "PaypalButton - handleApprove: " + paypal.transaction_id,
      LogLevelType.Info
    );

    paypal.status = "completed";
    return paypalService.updatePaypalOrder(paypal).then((data) => {
      const id = paypal.id;
      const transaction_id = paypal.transaction_id;
      const gross_amount = totalPrice.toString();
      const currency_code = "EUR";
      const capture_id = "";

      handlePaypalResponse(
        {
          id,
          transaction_id,
          gross_amount,
          capture_id,
          currency_code,
        },
        orderData
      );
      if (data.status === 200) {
        history.push(routes.afterCheckoutPaypal);
      } else {
        loggerService.addLog(
          "Unusual response status processing transaction " +
            paypal.transaction_id +
            ": " +
            data.status,
          LogLevelType.Warning
        );
      }
    });
  };

  return (
    <PayPalButtons
      fundingSource={funding}
      createOrder={createOrder}
      onApprove={handleApprove}
      onError={(err) => {
        loggerService.addLog(
          "Error processing transaction " + paypal.transaction_id + ": " + err,
          LogLevelType.Error
        );
        dispatch(bookingsFailure());
        loadAfterCheckout(false, "paypal");
      }}
      onClick={onClick}
    />
  );
};
