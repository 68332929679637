/**
 * Class that provides helper functionality like parsing operations.
 */

import DOMPurify from "dompurify";
import { ContentState, convertFromHTML, convertFromRaw, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import moment, { Moment } from "moment";
import { PaymentData } from "../models/paymentData";
import { getDynamicTranslation } from "../components/core/localization/localizationUtils";

export class Utils {
  // !provide typesafe use of property names of interfaces
  static readonly propertyOf = <T>(name: keyof T) => name;

  /**
   * Tries to parse the given string in param "stringToParse" to an integer. If the string is empty, return 0. If the value is no number return the defaultValue otherwise return the parsed number.
   * If "allowNegative" is set to false and the number is negative return the absolute value.
   * @param stringToParse String that is parsed
   * @param defaultValue Default value that is set if the string could not be parsed
   * @param allowNegative Determines whether negative values are allowed. Default is false.
   * @param getAbsoluteValue Determines whether the value that is returned if the number is negative should be the absolute value of the entered negative number or just zero. Default is false.
   * ```
   * Usage:
   *
   * tryParseInt("5", 0); // returns 5
   * tryParseInt("5asdf", 0); // returns 0 as default
   * ```
   */
  static tryParseInt(
    stringToParse: string | null | undefined,
    defaultValue: number,
    allowNegative: boolean = false,
    getAbsoluteValue: boolean = false
  ) {
    if (stringToParse?.length === 0) return 0;
    if (
      stringToParse !== null &&
      stringToParse !== undefined &&
      stringToParse.length > 0
    ) {
      const parsedInteger = Number(stringToParse);
      if (!isNaN(parsedInteger)) {
        if (!allowNegative && parsedInteger < 0) {
          if (getAbsoluteValue) {
            return Math.abs(parsedInteger);
          } else {
            return 0;
          }
        }
        return parsedInteger;
      }
    }
    return defaultValue;
  }

  static toCurrency(input: number) {
    const formatter = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
      maximumFractionDigits: 2,
      currencyDisplay: "code",
    });

    return formatter.format(input);
  }

  static getPseudoRandomId() {
    const pseudoRandomNumberString =
      "1" +
      Math.floor(Math.random() * 10).toString() +
      Math.floor(Math.random() * 10).toString() +
      Math.floor(Math.random() * 10).toString();
    return Utils.tryParseInt(pseudoRandomNumberString, -1).toString();
  }

  static getRandomNumber(range: number) {
    return Math.floor(Math.random() * range);
  }

  static getEnumKeyByValue<T extends { [key: number]: string | number }>(
    enumToFindKeyIn: T,
    enumValueToMatch: string | number
  ): string {
    let enumKeys = Object.keys(enumToFindKeyIn);

    const indexOfElementInEnum = Object.values(enumToFindKeyIn).findIndex(
      (value) => value === enumValueToMatch
    );

    return enumKeys[indexOfElementInEnum];
  }

  static isToday(dateToCheck: Date): boolean {
    const today = new Date();
    return (
      dateToCheck.getDate() === today.getDate() &&
      dateToCheck.getMonth() === today.getMonth() &&
      dateToCheck.getFullYear() === today.getFullYear()
    );
  }

  static isTomorrow(dateToCheck: Date): boolean {
    const today = new Date();
    return (
      dateToCheck.getDate() === today.getDate() + 1 &&
      dateToCheck.getMonth() === today.getMonth() &&
      dateToCheck.getFullYear() === today.getFullYear()
    );
  }

  static generateRandomDateInDateRange(startDate: Date, endDate: Date): Date {
    return new Date(
      startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime())
    );
  }

  static getYearsOfBirth(): string[] {
    const todayYear = new Date().getFullYear();
    let startYear = todayYear - 100;
    let birthYears: string[] = [];

    while (startYear <= todayYear) {
      birthYears.push(startYear.toString());
      startYear++;
    }

    return birthYears;
  }

  /**
   * Truncates a given string, so that only the number of characters set are returned.
   * @param fullString String to truncate
   * @param maxLength number of characters that limit the displayed string
   * @param truncateInBetween Determines whether the string should be truncated in the middle - first part and last part are appended,
   * everything in the middle that exceeds the maxLength will be truncated if it is true. If it is false only the end of the string is truncated.
   * @returns truncated string
   */
  static truncateNames(
    fullString: string,
    maxLength: number,
    truncateInBetween: boolean = true
  ): string {
    if (fullString.length <= maxLength) return fullString;

    let separator = "...";

    let sepLen = separator.length,
      charsToShow = maxLength - sepLen,
      frontChars = truncateInBetween ? Math.ceil(charsToShow / 2) : maxLength,
      backChars = Math.floor(charsToShow / 2);

    return (
      fullString.substr(0, frontChars) +
      separator +
      (truncateInBetween ? fullString.substr(fullString.length - backChars) : "")
    );
  }

  static truncateRichText(fullJson: string, maxLength: number): string {
    const fullHtml = DOMPurify.sanitize(
      Utils.convertRteStateToHtml(Utils.convertJsonToRteState(fullJson))
    );
    const fullString = fullHtml.replace(/<[^>]*>?/gm, "").replaceAll("&nbsp;", " ");
    return fullString.length > maxLength
      ? `${fullString.substring(0, maxLength)}...`
      : fullString;
  }

  static roundToQuartHourFromNow(now: Moment = moment(), addMinutes = 0) {
    const remainingTime = 15 - (now.minute() % 15);

    const dateTime = moment(now).add(remainingTime + addMinutes, "minutes");

    return dateTime;
  }

  static convertRteStateToHtml(rteState: ContentState): string {
    const options = {
      inlineStyles: {
        INDENTLINE: { style: { marginLeft: "1.25rem" } },
      },
    };
    return stateToHTML(rteState, options);
  }

  static convertHTMLToRteState(html: string): ContentState {
    if (html) {
      try {
        const contentHTML = convertFromHTML(html);
        return ContentState.createFromBlockArray(
          contentHTML.contentBlocks,
          contentHTML.entityMap
        );
      } catch (e) {
        return EditorState.createEmpty().getCurrentContent();
      }
    }
    return EditorState.createEmpty().getCurrentContent();
  }

  static convertJsonToRteState(json: string): ContentState {
    if (json) {
      try {
        return convertFromRaw(JSON.parse(json));
      } catch (e) {
        return Utils.convertHTMLToRteState(json);
      }
    }
    return EditorState.createEmpty().getCurrentContent();
  }

  static getCurrentPathWithoutLastSubPath(path: string) {
    return path.slice(0, path.lastIndexOf("/"));
  }

  static getLastPartOfPathWithoutSlash(path: string) {
    return path.slice(path.lastIndexOf("/") + 1);
  }

  static preventEnterToSubmit = (keyEvent: React.KeyboardEvent<HTMLFormElement>) => {
    if (
      keyEvent.key === "Enter" &&
      (keyEvent.target as Element).tagName !== "TEXTAREA"
    ) {
      keyEvent.preventDefault();
    }
  };

  static formatToLocalTime(date: Date) {
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  static formatDiscountAmount(amount: number | undefined) {
    if (amount === undefined || amount === 0) {
      return "0,00 €";
    } else {
      return (amount / 100).toFixed(2).replaceAll(".", ",") + " €";
    }
  }

  static formatTax(tax: number | undefined, useTaxPostfix: boolean = true) {
    const taxPostfix: string = " % MwSt.";
    let taxString = "";
    if (tax === undefined || tax === 0) {
      taxString = "0";
    } else {
      taxString = Math.trunc(tax).toString();
    }
    return taxString;
  }

  static formatPrice(
    cents: number,
    showDecimalsIfInt: boolean = false,
    showKostenFrei: boolean = true
  ) {
    if (showKostenFrei && cents === 0) {
      return getDynamicTranslation("freeOfCharge", "events");
    } else {
      if (cents % 100 === 0 && !showDecimalsIfInt) {
        return cents / 100 + ",00 €";
      } else {
        return (cents / 100).toFixed(2).replaceAll(".", ",") + " €";
      }
    }
  }

  static capitalizeFirstLetter(stringToCapitalize: string): string {
    return stringToCapitalize.charAt(0).toUpperCase() + stringToCapitalize.slice(1);
  }

  static formatDateToCetFormat = (date: Date | null): string => {
    //! NOTE: CustomDatepicker return a date back like "2012-12-05T23:00:00.000Z" format
    return moment(date).format("DD.MM.YYYY");
  };

  /**
   * Parses a date string in DD.MM.YYYY format into a JavaScript Date object.
   * @param {string} dateString - The date string in DD.MM.YYYY format.
   * @returns {Date} The parsed Date object.
   * @throws {Error} Throws an error if the input date string is invalid.
   */
  static parseBirthdayWithEuFormat = (dateString: string): Date => {
    const [day, month, year] = dateString.split(".");

    // Create a new Date object by passing year, month (zero-based), and day
    const parsedDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

    // Check if the parsed date is valid
    if (
      parsedDate.getDate() === parseInt(day) &&
      parsedDate.getMonth() === parseInt(month) - 1 &&
      parsedDate.getFullYear() === parseInt(year)
    ) {
      return parsedDate;
    } else {
      throw new Error("Invalid date format");
    }
  };

  /**
   * Looks up a specific value inside array of paymentMethods.
   * @param allPaymentMethods collection of all available payment methods
   * @param paymentMethodID ID of the desired object
   * @returns matching payment method object
   */
  static getPaymentMethod = (
    allPaymentMethods: PaymentData[],
    paymentMethodID: string
  ) => {
    return allPaymentMethods.find((element) => {
      return element.id === paymentMethodID;
    });
  };
}
