/* eslint-disable camelcase */

import { OrganizationForm } from "../models/organizationData";

const organizationFormInitialData = (data?: OrganizationForm) => {
  return {
    organization_type: data?.organization_type || "",
    name: data?.name || "",
    contact_person_firstname: data?.contact_person_firstname || "",
    contact_person_lastname: data?.contact_person_lastname || "",
    contact_person_email: data?.contact_person_email || "",
    contact_person_phone: data?.contact_person_phone || "",
    street: data?.street || "",
    street_number: data?.street || "",
    zip_code: data?.zip_code || "",
    city: data?.city || "",
    email: data?.email || "",
    tax_number: data?.tax_number || "",
    commercial_register_number: data?.commercial_register_number || "",
    use_address_data_for_billing: data?.use_address_data_for_billing || true,
    billing_address_firstname: data?.billing_address_firstname || "",
    billing_address_lastname: data?.billing_address_lastname || "",
    billing_address_email: data?.billing_address_email || "",
    billing_address_phone: data?.billing_address_phone || "",
    billing_address_street: data?.billing_address_street || "",
    billing_address_street_number: data?.billing_address_street_number || "",
    billing_address_zip_code: data?.billing_address_zip_code || "",
    billing_address_city: data?.billing_address_city || "",
    // extra
    id: "",
    country: "",
  } as OrganizationForm;
};

export default organizationFormInitialData;
