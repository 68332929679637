import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { StripeData } from "../models/stripeData";

export class StripeService extends BaseCrudService {

  async createStripe(order: string) {
    const stripeData: StripeData = {
      id: "",
      session_id: "",
      status: "",
      order: order,
      client_secret: "",
    };
    const response = await this.create<StripeData>(apiRoutes.stripe_checkout, stripeData);
    return response.data;
  }

  async getStripeData(id: string) {
    const response = await this.get<StripeData>(apiRoutes.stripe_data, id);
    return response.data;
  }

  async updateStripeSessionStatus(id: string) {
    const data = {};
    const response = await this.edit(apiRoutes.stripe_session_status, id, data);
    return response.data;
  }

}
