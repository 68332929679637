import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { enqueueSnackbar } from "notistack";
import { organizationMembershipService } from "../../api";
import {
  OrganizationMembershipData,
  OrganizationMembershipInviteData,
} from "../../models/organizationMembershipData";
import {
  ACCEPT_MEMBERSHIP_FAILURE,
  ACCEPT_MEMBERSHIP_REQUEST,
  ACCEPT_MEMBERSHIP_SUCCESS,
  CREATE_MEMBERSHIP_INVITE_FAILURE,
  CREATE_MEMBERSHIP_INVITE_REQUEST,
  CREATE_MEMBERSHIP_INVITE_SUCCESS,
  FETCH_MEMBERSHIPS_FAILURE,
  FETCH_MEMBERSHIPS_REQUEST,
  FETCH_MEMBERSHIPS_SUCCESS,
  MembershipActionTypes,
  PATCH_MEMBERSHIP_ADMIN_STATUS_FAILURE,
  PATCH_MEMBERSHIP_ADMIN_STATUS_REQUEST,
  PATCH_MEMBERSHIP_ADMIN_STATUS_SUCCESS,
  REMOVE_MEMBERSHIP_FAILURE,
  REMOVE_MEMBERSHIP_REQUEST,
  REMOVE_MEMBERSHIP_SUCCESS,
  SET_MEMBERSHIPS_LOADED,
  SetMembershipsLoadedAction,
} from "./types";
import { getDynamicTranslation } from "../../components/core/localization/localizationUtils";

// Action creator to initiate the fetch memberships request
export const fetchMembershipsRequest = (): MembershipActionTypes => ({
  type: FETCH_MEMBERSHIPS_REQUEST,
});

// Action creator to handle successful fetch memberships response
export const fetchMembershipsSuccess = (
  memberships: OrganizationMembershipData[]
): MembershipActionTypes => ({
  type: FETCH_MEMBERSHIPS_SUCCESS,
  payload: memberships,
});

// Action creator to handle failed fetch memberships response
export const fetchMembershipsFailure = (error: string): MembershipActionTypes => ({
  type: FETCH_MEMBERSHIPS_FAILURE,
  error,
});

// Action creator to initiate the create membership invite request
export const createMembershipInviteRequest = (): MembershipActionTypes => ({
  type: CREATE_MEMBERSHIP_INVITE_REQUEST,
});

// Action creator to handle successful creation of membership invite
export const createMembershipInviteSuccess = (): MembershipActionTypes => ({
  type: CREATE_MEMBERSHIP_INVITE_SUCCESS,
});

// Action creator to handle failed creation of membership invite
export const createMembershipInviteFailure = (error: string): MembershipActionTypes => ({
  type: CREATE_MEMBERSHIP_INVITE_FAILURE,
  error,
});

export const removeMembershipRequest = (): MembershipActionTypes => ({
  type: REMOVE_MEMBERSHIP_REQUEST,
});

export const removeMembershipSuccess = (
  membershipId: string
): MembershipActionTypes => ({
  type: REMOVE_MEMBERSHIP_SUCCESS,
  membershipId,
});

export const removeMembershipFailure = (error: string): MembershipActionTypes => ({
  type: REMOVE_MEMBERSHIP_FAILURE,
  error,
});

// Action creator to initiate the accept membership request
export const acceptMembershipRequest = (): MembershipActionTypes => ({
  type: ACCEPT_MEMBERSHIP_REQUEST,
});

// Action creator to handle successful acceptance of membership
export const acceptMembershipSuccess = (
  membershipId: string
): MembershipActionTypes => ({
  type: ACCEPT_MEMBERSHIP_SUCCESS,
  membershipId,
});

// Action creator to handle failed acceptance of membership
export const acceptMembershipFailure = (error: string): MembershipActionTypes => ({
  type: ACCEPT_MEMBERSHIP_FAILURE,
  error,
});

export function setMembershipsLoaded(): SetMembershipsLoadedAction {
  return {
    type: SET_MEMBERSHIPS_LOADED,
  };
}

export const patchMembershipAdminStatusRequest = (): MembershipActionTypes => ({
  type: PATCH_MEMBERSHIP_ADMIN_STATUS_REQUEST,
});

export const patchMembershipAdminStatusSuccess = (
  membershipId: string
): MembershipActionTypes => ({
  type: PATCH_MEMBERSHIP_ADMIN_STATUS_SUCCESS,
  membershipId,
});

export const patchMembershipAdminStatusFailure = (
  error: string
): MembershipActionTypes => ({
  type: PATCH_MEMBERSHIP_ADMIN_STATUS_FAILURE,
  error,
});

/**
 * Action creator to initiate the asynchronous fetching of memberships.
 * Dispatches appropriate actions based on the success or failure of the fetch operation.
 * @returns {ThunkAction<void, {}, unknown, MembershipActionTypes>} A thunk action.
 */
export const fetchMemberships = () => {
  return async (dispatch: Dispatch<MembershipActionTypes>) => {
    dispatch(fetchMembershipsRequest());
    try {
      const response = await organizationMembershipService.getOrganizationMemberships();
      dispatch(fetchMembershipsSuccess(response));
    } catch (error) {
      const axiosError = error as AxiosError;
      const errorMessage =
        axiosError.response?.data?.message ||
        getDynamicTranslation("error.fetchMembershipsError", "organization");
      dispatch(fetchMembershipsFailure(errorMessage));
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  };
};

/**
 * Action creator to initiate the asynchronous fetching of memberships by organization ID.
 * Dispatches appropriate actions based on the success or failure of the fetch operation.
 * @param {string} organizationId - The ID of the organization.
 * @returns {ThunkAction<void, {}, unknown, MembershipActionTypes>} A thunk action.
 */
export const fetchMembershipsByOrganizationId = (organizationId: string) => {
  return async (dispatch: Dispatch<MembershipActionTypes>) => {
    dispatch(fetchMembershipsRequest());
    try {
      const response =
        await organizationMembershipService.getMembershipsByOrganizationId(
          organizationId
        );
      dispatch(fetchMembershipsSuccess(response));
    } catch (error) {
      const axiosError = error as AxiosError;
      const errorMessage =
        axiosError.response?.data?.message ||
        getDynamicTranslation("error.fetchMembershipByOrgaID", "organization");
      dispatch(fetchMembershipsFailure(errorMessage));
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  };
};

/**
 * Action creator to initiate the asynchronous creation of membership invitations.
 * Dispatches appropriate actions based on the success or failure of the create operation.
 * @param {OrganizationMembershipInviteData} inviteData - The data for the membership invitation.
 * @returns {ThunkAction<void, {}, unknown, MembershipActionTypes>} A thunk action.
 */
export const createMembershipInvite = (inviteData: OrganizationMembershipInviteData) => {
  return async (dispatch: Dispatch<MembershipActionTypes>) => {
    dispatch(createMembershipInviteRequest());
    try {
      // Call the API service method to create the membership invite
      await organizationMembershipService.inviteUserToOrganization(inviteData);
      await dispatch(createMembershipInviteSuccess());

      // Display a success message using notistack or similar library
      enqueueSnackbar(
        getDynamicTranslation(
          "backendResponses.success.membershipInvitation",
          "snackbars"
        ),
        {
          variant: "success",
        }
      );
      return true;
    } catch (error) {
      const errorMessage = getDynamicTranslation(
        "error.membershipExists",
        "organization"
      );
      dispatch(createMembershipInviteFailure(errorMessage));
      // // Display an error message using notistack or similar library
      // enqueueSnackbar(errorMessage, { variant: "error" });
      return false;
    }
  };
};

/**
 * Action creator for creating bulk membership invitations.
 * @param {OrganizationMembershipInviteData} inviteData - The data for the bulk membership invitation.
 * @returns {Promise<boolean>} A promise that resolves to true if the invitations are successfully created, otherwise false.
 */
export const createBulkMembershipInvite = (
  inviteData: OrganizationMembershipInviteData
) => {
  return async (dispatch: Dispatch<MembershipActionTypes>) => {
    dispatch(createMembershipInviteRequest());
    try {
      // Call the API service method to create the membership invite
      await organizationMembershipService.inviteUserToOrganization(inviteData);
      await dispatch(createMembershipInviteSuccess());

      // Display a success message using notistack or similar library
      enqueueSnackbar(
        getDynamicTranslation(
          "backendResponses.success.membershipInvitation",
          "snackbars"
        ),
        {
          variant: "success",
        }
      );
      return true;
    } catch (error) {
      return false;
    }
  };
};

/**
 * Action creator for removing a membership.
 * @param {string} membershipId - The ID of the membership to remove.
 * @returns {Promise<void>} A promise that resolves when the membership is successfully removed.
 */
export const removeMembership = (membershipId: string) => {
  return async (dispatch: Dispatch<MembershipActionTypes>) => {
    dispatch(removeMembershipRequest());
    try {
      await organizationMembershipService.removeMembership(membershipId);
      dispatch(removeMembershipSuccess(membershipId));
      enqueueSnackbar(
        getDynamicTranslation("backendResponses.success.membershipRemoved", "snackbars"),
        {
          variant: "success",
        }
      );
    } catch (error) {
      dispatch(
        removeMembershipFailure(
          getDynamicTranslation("error.removeMembership", "organization")
        )
      );
    }
  };
};

/**
 * Action creator for removing a membership.
 * @param {string} membershipId - The ID of the membership to remove.
 * @returns {Promise<void>} A promise that resolves when the membership is successfully removed.
 */
export const acceptMembership = (membershipId: string) => {
  return async (dispatch: Dispatch<MembershipActionTypes>) => {
    dispatch(acceptMembershipRequest());
    try {
      await organizationMembershipService.acceptMembership(membershipId);
      dispatch(acceptMembershipSuccess(membershipId));
      enqueueSnackbar(
        getDynamicTranslation(
          "backendResponses.success.membershipAccepted",
          "snackbars"
        ),
        {
          variant: "success",
        }
      );
    } catch (error) {
      dispatch(
        acceptMembershipFailure(
          getDynamicTranslation("error.acceptingMembership", "organization")
        )
      );
    }
  };
};

/**
 * Action creator for patching the admin status of a membership.
 * @param {string} membershipId - The ID of the membership to be updated.
 * @param {object} data - The admin data to be updated with boolean is_admin.
 * @returns {Promise<void>} A promise that resolves when the membership's admin status is successfully updated
 */
export const patchMembershipAdminStatus = (
  membershipId: string,
  data: { is_admin: boolean }
) => {
  return async (dispatch: Dispatch<MembershipActionTypes>) => {
    dispatch(patchMembershipAdminStatusRequest());
    try {
      await organizationMembershipService.patchMembershipAdminStatus(membershipId, data);
      dispatch(patchMembershipAdminStatusSuccess(membershipId));
      enqueueSnackbar(
        getDynamicTranslation("organization.adminStatusSuccess", "snackbars"),
        {
          variant: "success",
        }
      );
    } catch (error) {
      dispatch(
        patchMembershipAdminStatusFailure(
          getDynamicTranslation("organization.adminStatusError", "snackbars")
        )
      );
    }
  };
};
