/* eslint-disable */
import { Grid, Link } from "@material-ui/core";
import { Formik, FormikConfig, FormikProps } from "formik";
import { FormEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useDynamicYupValidations } from "../../../../../libs/yup-validations";
import authenticationImage from "../../../../../assets/video_authentisierung/authentication.svg";
import { DocumentType } from "../../../../../models/documentData";
import { AppState } from "../../../../../redux";
import { setCaptureMetaData } from "../../../../../redux/videoAuthentication/actions";
import CustomCheckbox from "../../../../forms/CustomCheckbox";
import CustomDatepicker from "../../../../forms/CustomDatepicker";
import CustomTextField from "../../../../forms/CustomTextField";
import CustomSelect from "../../../../forms/selects/CustomSelect";
import { SelectData } from "../../../../forms/selects/selectData";
import HeadingLumos from "../../../../theming/HeadingLumos";
import { NavControls } from "./NavControls";
import { getCurrentUserData } from "../../../../../redux/users/actions";
import routes from "../../../../../routing/routes";
import { AUTH_DOC_REQUIRED } from "../../../../../libs/validation_strings";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { isMobileDevice } from "../../../../../utils/isMobileDevice";
import { isIpadDevice } from "../../../../../utils/isIpadDevice";

export interface CaptureMetaData {
  event_participation: string;
  terms_accepted: boolean;
  biometric_data_processing_allowed: boolean;
  birthday: string;
  document_type: DocumentType;
  document_number: string;
  first_name: string;
  last_name: string;
  document_capture_file: string;
  participant_capture_file: string;
  is_mobile_capture: boolean;
}

export function Instructions() {
  const { t } = useTranslation(["authCapture", "layout", "snackbars", "validations"]);
  const history = useHistory();
  const dispatch = useDispatch();
  
  const isMobile = isMobileDevice();
  const isIpad = isIpadDevice();

  const savedInputs: CaptureMetaData | {} =
    useSelector<AppState>(
      (appState: AppState) => appState.videoAuthentication.metaData
    ) ?? {};
  const currentUser = useSelector((state: AppState) => state.user.currentUser);

  useEffect(() => {
    if (!currentUser) {
      getCurrentUserData(dispatch);
    }
  }, [currentUser, dispatch]);

  const handleProceed = (formik: FormikProps<CaptureMetaData>) => {
    formik.submitForm().then(() => {
      history.push("?step=1");
    });
  };
  const selectDocumentTypes: SelectData[] = [
    {
      key: DocumentType.IdentityCard,
      label: t("authDocs.types.identityCard", { ns: "authCapture" }),
      value: DocumentType.IdentityCard,
      disabled: false,
    },
    {
      key: DocumentType.Passport,
      label: t("authDocs.types.passport", { ns: "authCapture" }),
      value: DocumentType.Passport,
      disabled: false,
    },
    {
      key: DocumentType.ResidencePermit,
      label: t("authDocs.types.residencePermit", { ns: "authCapture" }),
      value: DocumentType.ResidencePermit,
      disabled: false,
    },
  ];

  const {
    YupValidationMatchBirthday,
    YupValidationMatchFirstName,
    YupValidationMatchLastName,
  } = useDynamicYupValidations();

  const formikConf: FormikConfig<any> = {
    initialValues: {
      terms_accepted: false,
      birthday: "",
      document_type: "",
      document_number: "",
      last_name: "",
      first_name: "",
      is_mobile_capture: isMobile || isIpad,
      ...savedInputs,
    },
    onSubmit: (formData: CaptureMetaData) => {
      setCaptureMetaData(formData);
    },
    validateOnChange: true,
    validationSchema: yup.object({
      terms_accepted: yup
        .boolean()
        .required(t("instructions.termsAccept", { ns: "authCapture" }))
        .isTrue(),
      biometric_data_processing_allowed: yup
        .boolean()
        .required(t("instructions.privacyAccept", { ns: "authCapture" }))
        .isTrue(),
      birthday: YupValidationMatchBirthday(currentUser?.birthday || ""),
      document_type: yup.string().required(t(AUTH_DOC_REQUIRED, { ns: "validations" })),
      document_number: yup
        .string()
        .required(t("instructions.docNumberValidate", { ns: "authCapture" }))
        .trim(),
      first_name: YupValidationMatchFirstName(currentUser?.first_name || ""),
      last_name: YupValidationMatchLastName(currentUser?.last_name || ""),
    }),
  };

  return (
    <>
      <Grid item xs={12}>
        <HeadingLumos>{t("instructions.title", { ns: "authCapture" })}</HeadingLumos>
        <img
          style={{ width: "100%", height: "auto" }}
          src={authenticationImage}
          alt={t("instructions.description", { ns: "authCapture" })}
        />
      </Grid>
      <Grid item xs={12}>
        <Formik key={i18n.language} {...formikConf}>
          {(formik: FormikProps<CaptureMetaData>) => (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <form
                  onSubmit={(e: FormEvent<HTMLFormElement>) => {
                    formik.handleSubmit(e);
                  }}
                  noValidate
                >
                  <Grid container spacing={2} justifyContent={"space-between"}>
                    <Grid item xs={12}>
                      <p>{t("instructions.authDocInstr", { ns: "authCapture" })}</p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomSelect
                        id="document_type"
                        name="document_type"
                        labelId={"document_type"}
                        value={formik.values.document_type}
                        label={t("authDocs.data.title", { ns: "authCapture" })}
                        options={selectDocumentTypes}
                        required={true}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomTextField
                        id="document_number"
                        name="document_number"
                        label={t("authDocs.data.number", { ns: "authCapture" })}
                        required={true}
                        value={formik.values.document_number}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <CustomDatepicker
                        format="DD.MM.YYYY"
                        id="birthday"
                        name="birthday"
                        label={t("profile.birthdate", { ns: "common" })}
                        required={true}
                        value={formik.values.birthday}
                        onChange={() => {}}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <CustomTextField
                        id="last_name"
                        name="last_name"
                        label={t("profile.lastName", { ns: "common" })}
                        required={true}
                        fullWidth
                        value={formik.values.last_name}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <CustomTextField
                        id="first_name"
                        name="first_name"
                        label={t("profile.firstName", { ns: "common" })}
                        required={true}
                        fullWidth
                        value={formik.values.first_name}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <p>
                        {t("instructions.conditionsTitle1", {
                          ns: "authCapture",
                        })}
                      </p>
                      <p>
                        {t("instructions.conditions1", {
                          ns: "authCapture",
                        })}
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomCheckbox
                        name={"terms_accepted"}
                        required={true}
                        checked={formik.values.terms_accepted}
                        label={
                          <span>
                            {t("instructions.conditionsAccepted1", {
                              ns: "authCapture",
                            })}
                          </span>
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <p>
                        {t("instructions.conditionsTitle2", {
                          ns: "authCapture",
                        })}
                      </p>
                      <p>
                        {t("instructions.conditions2a", {
                          ns: "authCapture",
                        })}
                      </p>
                      <p>
                        {t("instructions.conditions2b", {
                          ns: "authCapture",
                        })}{" "}
                        <Link href={routes.privacy_policy} rel="noopener noreferrer">
                          {t("footer.privacy", {
                            ns: "layout",
                          })}
                        </Link>
                        .
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomCheckbox
                        name={"biometric_data_processing_allowed"}
                        required={true}
                        checked={formik.values.biometric_data_processing_allowed}
                        label={
                          <span>
                            {t("instructions.conditionsAccepted2", {
                              ns: "authCapture",
                            })}
                          </span>
                        }
                      />
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              <Grid item xs={12}>
                <NavControls
                  canProceed={
                    formik.isValid &&
                    formik.values.terms_accepted &&
                    formik.values.biometric_data_processing_allowed
                  }
                  onProceed={() => handleProceed(formik)}
                />
              </Grid>
            </Grid>
          )}
        </Formik>
      </Grid>
    </>
  );
}
