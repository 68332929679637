import {
  ELearningParticipationStatus,
  ParticipationStatus,
} from "../models/enums/participationStatus.enum";

/**
 * The function checks if the start of the eLearning is possible
 * @returns {boolean} - true if the start of the eLearning is possible
 */
export const checkIfStartDisabled = (
  endDate: string | Date | null,
  status: ParticipationStatus,
  elearningStatus: ELearningParticipationStatus
) => {
  const isEndDateInvalid = endDate ? new Date(endDate) <= new Date() : false;

  return (
    isEndDateInvalid ||
    status === ParticipationStatus.CertificateRevoked ||
    elearningStatus === ELearningParticipationStatus.Passed
  );
};
