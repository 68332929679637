import { showDialog } from "../../../redux/dialogs/actions";
import { getDynamicTranslation } from "../localization/localizationUtils";

const ShowEventDoneDialog = () =>
  showDialog(
    {
      title: getDynamicTranslation("dialogues.showEventDone.title", "layout"),
      message: <>{getDynamicTranslation("dialogues.showEventDone.text", "layout")}</>,
      confirmButtonText: getDynamicTranslation(
        "dialogues.showEventDone.close",
        "layout"
      ),
    },
    true
  );
export default ShowEventDoneDialog;

// TODO no translation yet
