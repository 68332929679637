import * as React from "react";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux";
import { useEffect } from "react";
import { getAllFavorites, setPageCount } from "../../../redux/favorites/actions";
import { EventItem } from "../../../components/core/events/list/eventItem/EventItem";
import { Box, Divider, Grid } from "@material-ui/core";
import { Pagination as PaginationUI } from "@material-ui/lab";
import { PAGE_SIZE } from "../../../models/pagination";
import Loader from "../../../components/theming/loader/Loader";
import { getTargetGroups } from "../../../redux/target-groups/actions";
import { useTranslation } from "react-i18next";

export const FavoritesPage: React.FC = () => {
  const { t } = useTranslation(["events", "snackbars"]);
  const dispatch = useDispatch();
  const favorite = useSelector((state: AppState) => state.favorite);
  const targetGroups = useSelector((state: AppState) => state.targetGroups);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(setPageCount(value));
  };

  const loadData = React.useCallback(() => {
    if (!favorite.isLoading && !favorite.eventFavoriteListLoaded) {
      dispatch(getAllFavorites(favorite.currentPage));
    }
  }, [
    favorite.isLoading,
    favorite.eventFavoriteListLoaded,
    favorite.currentPage,
    dispatch,
  ]);

  useEffect(loadData, [loadData]);

  useEffect(() => {
    if (!targetGroups.isLoading && !targetGroups.targetGroupsLoaded) {
      dispatch(getTargetGroups());
    }
  }, [dispatch, targetGroups]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <HeadingLumos>{t("favorites.title", { ns: "events" })}</HeadingLumos>
        </Grid>
      </Grid>
      {favorite.isLoading ? (
        <Loader />
      ) : favorite.eventFavoriteList.count === 0 ? (
        <Box
          component="span"
          display="flex"
          alignItems="center"
          style={{ height: "6.25rem" }}
        >
          {t("favorites.isEmpty", { ns: "events" })}
        </Box>
      ) : (
        favorite.eventFavoriteList.results.map((fav) => {
          return (
            <React.Fragment key={`event-${fav.id}`}>
              <EventItem event={fav.event} favorite />
              <Divider className="event-list-separator" />
            </React.Fragment>
          );
        })
      )}
      <Grid container style={{ marginTop: ".625rem" }}>
        <Box component="div" style={{ margin: "auto" }}>
          <PaginationUI
            count={Math.ceil(favorite.eventFavoriteList.count / PAGE_SIZE)}
            page={favorite.currentPage}
            onChange={handleChange}
          />
        </Box>
      </Grid>
    </>
  );
};
