/* eslint-disable camelcase */
import { useState } from "react";
import { faStar as faStarOutline } from "@fortawesome/free-regular-svg-icons/faStar";
import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { LumosIdSvg } from "../../../../../assets/icons/custom-svg-components/LumosSvgCollection";
import { ParticipationStatus } from "../../../../../models/enums/participationStatus.enum";
import { TrainingType } from "../../../../../models/enums/trainingType.enum";
import { EventListData } from "../../../../../models/eventData";
import { AppState } from "../../../../../redux";
import { clearDocuments } from "../../../../../redux/documents/actions";
import { clearEditEvent } from "../../../../../redux/events/actions";
import { addFavorite, deleteFavorite } from "../../../../../redux/favorites/actions";
import { clearParticipant } from "../../../../../redux/participants/actions";
import { clearSpeakers } from "../../../../../redux/speaker/actions";
import { clearTemplateEvent } from "../../../../../redux/templates/events/actions";
import routes from "../../../../../routing/routes";
import usePermission from "../../../../../services/usePermissions";
import TargetGroupList from "./TargetGroupList";
import { defaultPalette } from "../../../../theming/defaultTheme";

interface EventItemTitleProps {
  event: EventListData;
  preview?: boolean;
  favorite?: boolean;
  // TODO for refactoring:
  // Why are there so many different properties stating whether favorite or not:
  // 1. favorite
  // 2. event.isFavorit
  // 3. event.participationStatus = isFavorite
  // Check if this is needed or redundant
}

export const EventItemTitle = ({ event, preview, favorite }: EventItemTitleProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((state: AppState) => state.auth);
  const currentUser = useSelector((state: AppState) => state.user.currentUser);
  const accessibility = useSelector((state: AppState) => state.accessibility);

  const { isStaff } = usePermission(currentUser?.user_type);
  const { isParticipant } = usePermission(currentUser?.user_type);
  const [showEditButton, setShowEditButton] = useState(false); // set the hovered state of the home link to style the element manually
  const isLoading = useSelector((state: AppState) => state.favorite.isLoading);

  return (
    <>
      <Box
        component="span"
        className="event-item-title"
        display="flex"
        alignItems="center"
        onMouseEnter={() => {
          setShowEditButton(true);
        }}
        onMouseLeave={() => {
          setShowEditButton(false);
        }}
      >
        {auth.loggedIn && isStaff() && !event.has_open_tasks ? (
          <DoneIcon
            style={{
              color: accessibility.monoMode ? "#000" : green[500],
              margin: "0.625rem",
            }}
          />
        ) : null}
        {auth.loggedIn && isParticipant() ? (
          favorite ? (
            <FontAwesomeIcon
              icon={faStar}
              size="xs"
              color={accessibility.monoMode ? "#000" : "#f44336"}
              style={{ marginRight: "0.3125rem", cursor: "pointer" }}
              onClick={() => {
                dispatch(deleteFavorite(event.id));
              }}
            />
          ) : event.participation_status === ParticipationStatus.Undefined ||
            event.participation_status === ParticipationStatus.Cancelled ||
            event.participation_status === ParticipationStatus.CancelledByStaff ||
            event.participation_status === ParticipationStatus.IsFavorite ? (
            event.is_favorite ? (
              <FontAwesomeIcon
                icon={faStar}
                size="xs"
                color={accessibility.monoMode ? "#000" : defaultPalette.primary?.main}
                style={{ marginRight: "0.3125rem", cursor: "pointer" }}
                onClick={() => {
                  dispatch(deleteFavorite(event.id));
                }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faStarOutline}
                size="xs"
                color={accessibility.monoMode ? "#000" : defaultPalette.primary?.main}
                style={{ marginRight: "0.3125rem", cursor: "pointer" }}
                onClick={() => {
                  if (!isLoading) dispatch(addFavorite(event.id));
                }}
              />
            )
          ) : (
            <FontAwesomeIcon
              icon={faStarOutline}
              size="xs"
              color={accessibility.monoMode ? "#777" : "#efefef"}
              style={{ marginRight: "0.3125rem" }}
            />
          )
        ) : null}
        {event.title}{" "}
        {auth.loggedIn && isStaff() && showEditButton && !preview ? (
          <EditIcon
            onClick={() => {
              dispatch(clearEditEvent());
              dispatch(clearSpeakers());
              dispatch(clearDocuments());
              dispatch(clearParticipant());
              dispatch(clearTemplateEvent());
              if (event.training_type === TrainingType.DefaultEvent) {
                history.push(`${routes.event_edit}/${event.id}`);
              } else if (event.training_type === TrainingType.ELearning) {
                history.push(`${routes.elearning_edit}/${event.id}`);
              } else if (event.training_type === TrainingType.BlendedLearning) {
                history.push(`${routes.blended_learning_edit}/${event.id}`);
              }
            }}
            style={{ cursor: "pointer", marginLeft: "6.25rem" }}
          />
        ) : null}
      </Box>
      <Box component="span" display="flex" alignItems="center">
        <LumosIdSvg /> {event.public_id !== 0 ? event.public_id : "XXXXX"}{" "}
        <div style={{ width: "6.25rem" }} />
        <TargetGroupList
          targetGroups={{
            participation_types: event.participation_types,
          }}
        />
      </Box>
    </>
  );
};
