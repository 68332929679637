import apiRoutes from "./apiRoutes";
import { BaseCrudService } from "./baseCrudService";
import { CancellationInvoiceListData } from "../models/cancellationInvoiceData";
import { Pagination } from "../models/pagination";

/**
 * The cancellation invoice service
 */
export class CancellationInvoiceService extends BaseCrudService {
  /**
   * Fetches the cancellation invoice data
   * @returns {Promise<Pagination<CancellationInvoiceListData>>}
   * @param {number} page - Page to be displayed.
   */
  async getinvoiceList(page: number) {
    const response = await this.getAll<Pagination<CancellationInvoiceListData>>(
      apiRoutes.cancellation_invoices,
      { page: page }
    );
    return response.data;
  }

  /**
   * Fetches the cancellation invoice data count
   * @returns {Promise<number>}
   */
  async getMyCancellationInvoicesCount() {
    const response = await this.getAll<{ count: number }>(apiRoutes.invoices_count);
    return response.data;
  }
}
