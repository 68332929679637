import apiRoutes from "../apiRoutes";
import { BaseCrudService } from "../baseCrudService";
import { OrganizationSettingsData } from "../../models/organizationSettingsData";

/**
 * Service class for managing organization settings.
 * Provides methods for retrieving and updating organization settings.
 */
export class OrganizationSettingsService extends BaseCrudService {
  /**
   * Retrieves the organization settings data from the API.
   * @param {string} organizationId - The ID of the organization whose settings are being retrieved.
   * @returns {Promise<OrganizationSettingsData>} A promise that resolves to the organization settings data.
   */
  async getOrganizationSettings(
    organizationId: string
  ): Promise<OrganizationSettingsData> {
    const response = await this.getAll<OrganizationSettingsData>(
      `${apiRoutes.organization_settings}/get_settings_by_organization_id`,
      { organization_id: organizationId }
    );

    return response.data;
  }

  /**
   * Updates the organization settings data.
   * @param {string} organizationSettingsId - The ID of the organization settings to be updated.
   * @param {OrganizationSettingsData} data - The new organization settings data.
   * @returns {Promise<OrganizationSettingsData>} A promise that resolves to the updated organization settings data.
   */
  async setOrganizationSettings(
    organizationSettingsId: string,
    data: OrganizationSettingsData
  ): Promise<OrganizationSettingsData> {
    const response = await this.edit<OrganizationSettingsData>(
      apiRoutes.organization_settings,
      organizationSettingsId,
      data
    );
    return response.data;
  }
}
