import { OrganizationMembershipData } from "../../models/organizationMembershipData";

export interface MembershipState {
  memberships: OrganizationMembershipData[];
  isLoading: boolean;
  memberShipsLoaded: boolean;
  error: string | null;
}

export interface MembershipInviteState {
  isLoading: boolean;
  error: string | null;
}

export const FETCH_MEMBERSHIPS_REQUEST = "FETCH_MEMBERSHIPS_REQUEST";
export const FETCH_MEMBERSHIPS_SUCCESS = "FETCH_MEMBERSHIPS_SUCCESS";
export const FETCH_MEMBERSHIPS_FAILURE = "FETCH_MEMBERSHIPS_FAILURE";
export const REMOVE_MEMBERSHIP_REQUEST = "REMOVE_MEMBERSHIP_REQUEST";
export const REMOVE_MEMBERSHIP_SUCCESS = "REMOVE_MEMBERSHIP_SUCCESS";
export const REMOVE_MEMBERSHIP_FAILURE = "REMOVE_MEMBERSHIP_FAILURE";
export const ACCEPT_MEMBERSHIP_REQUEST = "ACCEPT_MEMBERSHIP_REQUEST";
export const ACCEPT_MEMBERSHIP_SUCCESS = "ACCEPT_MEMBERSHIP_SUCCESS";
export const ACCEPT_MEMBERSHIP_FAILURE = "ACCEPT_MEMBERSHIP_FAILURE";
export const CREATE_MEMBERSHIP_INVITE_REQUEST = "CREATE_MEMBERSHIP_INVITE_REQUEST";
export const CREATE_MEMBERSHIP_INVITE_SUCCESS = "CREATE_MEMBERSHIP_INVITE_SUCCESS";
export const CREATE_MEMBERSHIP_INVITE_FAILURE = "CREATE_MEMBERSHIP_INVITE_FAILURE";
export const SET_MEMBERSHIPS_LOADED = "SET_MEMBERSHIPS_LOADED";
export const PATCH_MEMBERSHIP_ADMIN_STATUS_REQUEST =
  "PATCH_MEMBERSHIP_ADMIN_STATUS_REQUEST";
export const PATCH_MEMBERSHIP_ADMIN_STATUS_SUCCESS =
  "PATCH_MEMBERSHIP_ADMIN_STATUS_SUCCESS";
export const PATCH_MEMBERSHIP_ADMIN_STATUS_FAILURE =
  "PATCH_MEMBERSHIP_ADMIN_STATUS_FAILURE";

export interface AcceptMembershipRequestAction {
  type: typeof ACCEPT_MEMBERSHIP_REQUEST;
}

export interface AcceptMembershipSuccessAction {
  type: typeof ACCEPT_MEMBERSHIP_SUCCESS;
  membershipId: string;
}

export interface AcceptMembershipFailureAction {
  type: typeof ACCEPT_MEMBERSHIP_FAILURE;
  error: string;
}

export interface FetchMembershipsRequestAction {
  type: typeof FETCH_MEMBERSHIPS_REQUEST;
}

export interface FetchMembershipsSuccessAction {
  type: typeof FETCH_MEMBERSHIPS_SUCCESS;
  payload: OrganizationMembershipData[];
}

export interface FetchMembershipsFailureAction {
  type: typeof FETCH_MEMBERSHIPS_FAILURE;
  error: string;
}

export interface SetMembershipsLoadedAction {
  type: typeof SET_MEMBERSHIPS_LOADED;
}

export interface CreateMembershipInviteRequestAction {
  type: typeof CREATE_MEMBERSHIP_INVITE_REQUEST;
}

export interface CreateMembershipInviteSuccessAction {
  type: typeof CREATE_MEMBERSHIP_INVITE_SUCCESS;
}

export interface CreateMembershipInviteFailureAction {
  type: typeof CREATE_MEMBERSHIP_INVITE_FAILURE;
  error: string;
}

export interface RemoveMembershipRequestAction {
  type: typeof REMOVE_MEMBERSHIP_REQUEST;
}

export interface RemoveMembershipSuccessAction {
  type: typeof REMOVE_MEMBERSHIP_SUCCESS;
  membershipId: string;
}

export interface RemoveMembershipFailureAction {
  type: typeof REMOVE_MEMBERSHIP_FAILURE;
  error: string;
}

export interface PatchMembershipAdminStatusRequestAction {
  type: typeof PATCH_MEMBERSHIP_ADMIN_STATUS_REQUEST;
}

export interface PatchMembershipAdminStatusSuccessAction {
  type: typeof PATCH_MEMBERSHIP_ADMIN_STATUS_SUCCESS;
  membershipId: string;
}

export interface PatchMembershipAdminStatusFailureAction {
  type: typeof PATCH_MEMBERSHIP_ADMIN_STATUS_FAILURE;
  error: string;
}

export type MembershipActionTypes =
  | CreateMembershipInviteRequestAction
  | CreateMembershipInviteSuccessAction
  | CreateMembershipInviteFailureAction
  | FetchMembershipsRequestAction
  | FetchMembershipsSuccessAction
  | FetchMembershipsFailureAction
  | RemoveMembershipRequestAction
  | RemoveMembershipSuccessAction
  | RemoveMembershipFailureAction
  | AcceptMembershipRequestAction
  | AcceptMembershipSuccessAction
  | AcceptMembershipFailureAction
  | SetMembershipsLoadedAction
  | PatchMembershipAdminStatusRequestAction
  | PatchMembershipAdminStatusSuccessAction
  | PatchMembershipAdminStatusFailureAction;
