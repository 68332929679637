import {
  LOAD_FEATURE_FLAGGING_DATA_SUCCESS,
  FeatureFlaggingActionTypes,
  FeatureFlaggingState,
  FEATURE_FLAGGING_FAILURE,
  FEATURE_FLAGGING_REQUEST,
} from "./types";

export const initialFeatureFlaggingState: FeatureFlaggingState = {
  featureFlags: {
    support_hotline_is_enabled: false,
    group_membership_is_enabled: false,
    bund_id_authentication_is_enabled: false,
    foto_id_is_enabled: false,
    stripe_payment_is_enabled: false,
  },
  isLoading: false,
  featureFlagsLoaded: false,
  isError: false,
};

export function featureFlaggingReducer(
  state = initialFeatureFlaggingState,
  action: FeatureFlaggingActionTypes
): FeatureFlaggingState {
  switch (action.type) {
    case FEATURE_FLAGGING_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LOAD_FEATURE_FLAGGING_DATA_SUCCESS:
      return {
        ...state,
        featureFlags: action.data,
        isLoading: false,
        featureFlagsLoaded: true,
      };
    case FEATURE_FLAGGING_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    default:
      return state;
  }
}

export default featureFlaggingReducer;
