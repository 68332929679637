/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { Button, Tooltip } from "@material-ui/core";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { EventStatus } from "../../../../../models/enums/eventStatus.enum";
import {
  mapToTrainingTypeString,
  mapTrainingTypeToArticleWithNoun,
  TrainingType,
} from "../../../../../models/enums/trainingType.enum";
import { EventFormData } from "../../../../../models/eventData";
import { AppState } from "../../../../../redux";
import { Utils } from "../../../../../services/utils";

interface CreateOrUpdateButtonProps {
  isEditMode: boolean;
  typeOfTrainingToBeCreated: TrainingType;
  onSaveSuccess?: (title: string) => void;
}

/**
 * CreateOrUpdateButton component handles the rendering and behavior
 * of the button used to create or update events.
 *
 * @component
 * @param {Object} props - Component props
 * @param {boolean} props.isEditMode - Indicates whether the component is in edit mode.
 * @param {TrainingType} props.typeOfTrainingToBeCreated - Type of training for which the button is used.
 * @returns {JSX.Element} - Rendered component.
 */
const CreateOrUpdateButton = ({
  isEditMode,
  typeOfTrainingToBeCreated,
  onSaveSuccess,
}: CreateOrUpdateButtonProps) => {
  // Redux selector to get the overall event state
  const event = useSelector((state: AppState) => state.event);
  // Redux selector to get the edit event state
  const editEventState = useSelector((state: AppState) => state.event.editEvent);
  // Destructure values from the Formik context
  const { setFieldValue, isSubmitting, isValidating, submitForm, values } =
    useFormikContext<EventFormData>();
  // Local state to track the submission state
  const [isSubmittingForm, setIsSubmittingForm] = useState<boolean>(false);
  // Get the string representation of the training type
  const trainingType = mapToTrainingTypeString(typeOfTrainingToBeCreated);

  // Update isSubmittingForm state based on form submission state
  useEffect(() => {
    if (isSubmitting) setIsSubmittingForm(isValidating);
    else setIsSubmittingForm(false);
  }, [isSubmitting, isValidating]);

  return (
    <Tooltip
      id="event-stats-tooltip"
      arrow
      title={
        // Check if the event is completed
        editEventState.status === EventStatus.Completed
          ? // Display a message indicating that the event cannot be modified
            `${Utils.capitalizeFirstLetter(
              mapTrainingTypeToArticleWithNoun(typeOfTrainingToBeCreated, trainingType)
            )} kann nicht mehr verändert werden, da ${
              typeOfTrainingToBeCreated === TrainingType.DefaultEvent ? "sie" : "es"
            } bereits abgeschlossen wurde.`
          : // Display an empty string if the event is not completed
            ""
      }
    >
      {/* Button for creating or updating events */}
      <span>
        <Button
          id="add-or-edit-event"
          size="large"
          variant="contained"
          color="primary"
          fullWidth
          disabled={
            isEditMode
              ? editEventState.status === EventStatus.Completed
              : isSubmittingForm || event.isLoading
          }
          type="submit"
          onClick={async () => {
            setFieldValue("submitWithTemplate", false);
            await submitForm();
            if (onSaveSuccess) {
              onSaveSuccess(values?.title);
            }
          }}
        >
          {/* Button label based on the type of training */}
          {`${trainingType} speichern`}
        </Button>
      </span>
    </Tooltip>
  );
};

export default CreateOrUpdateButton;

// TODO no translation yet
