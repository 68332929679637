import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../redux";
import { Box, Grid } from "@material-ui/core";
import { removePassImageCapture } from "../../../../../redux/videoAuthentication/actions";
import { ImageWithDeleteIcon } from "../misc/ImageWithDeleteIcon";
import { useTranslation } from "react-i18next";
import { isMobileDevice } from "../../../../../utils/isMobileDevice";

/**
 * Renders a list of ID card captures.
 *
 * @component
 * @returns {JSX.Element} - Rendered component.
 */
export function CaptureIdCardList() {
  const { t } = useTranslation(["authCapture", "snackbars"]);

  const { documentCaptures: passCaptures } = useSelector(
    (state: AppState) => state.videoAuthentication
  );
  const dispatch = useDispatch();

  const isMobile = isMobileDevice();

  // TODO: Does apple identify rem?
  let imageStyle = {
    width: "26.563rem", height: "12.5rem", padding: ".625rem"
  };
  if (isMobile) {
    imageStyle = {
      width: "12.5rem", height: "26.563rem", padding: ".625rem"
    }
  };

  const defaultStyle = { maxWidth: "100%", padding: ".625rem" };

  /**
   * Handles the removal of an ID card capture.
   *
   * @param {string} capture - The ID card capture to be removed.
   */
  function handleRemoveCapture(capture: string) {
    dispatch(removePassImageCapture(capture));
  }

  let height = Math.round(100 / 9 * 16).toString() + "px";

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box>{t("authDocs.title", { ns: "authCapture" })}</Box>
      </Grid>
      {passCaptures.length > 1 && (
        <Grid item xs={12}>
          <Box>{t("shots.shotSelect", { ns: "authCapture" })}</Box>
        </Grid>
      )}
      {passCaptures.map((capture, index) => {
        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={capture}
            style={{ display: "flex", justifyContent: "center"}}
          >
            <div style={{padding: ".625rem"}} >
              <Box>

              <ImageWithDeleteIcon
                src={capture}
                alt={`${t("shots.shot", { ns: "authCapture" })} ${index}`}
                title={t("shots.title", { ns: "authCapture" })}
                onDelete={() => handleRemoveCapture(capture)}
                isMemberShipDocumentPage={false}
                />
              </Box>
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
}
