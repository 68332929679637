/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Alert } from "@material-ui/lab";
import { Field, Form, Formik } from "formik";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Typography,
  Link,
  Switch,
  CircularProgress,
} from "@material-ui/core";
import { TERMS_AND_CONDITIONS_URL } from "../../../routing/externalUrls";

import "./RegistrationPage.scss";
import { initialData, userNeedsAGuardian } from "./RegistrationPage.utils";
import { UserRegistrationRequestData } from "../../../models/userData";
import { useDynamicYupValidations } from "../../../libs/yup-validations";
import { fetchMinimumAge } from "../../../redux/system-settings/actions";
import { usersService } from "../../../api";
import { AppState } from "../../../redux";
import CustomTextField from "../../../components/forms/CustomTextField";
import CustomPasswordField from "../../../components/forms/CustomPasswordField";
import CustomDatepicker from "../../../components/forms/CustomDatepicker";
import routes from "../../../routing/routes";
import i18n from "../../../localization/i18n";
import { GroupMembershipDocumentSection } from "../../../components/core/registration/GroupMembershipDocumentSection";
import { FileObject } from "material-ui-dropzone";
import { MembershipDocumentRequestDto } from "../../../models/groupMembership";
import { groupMembershipService } from "../../../api";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { EMAIL_REQUIRED } from "../../../libs/validation_strings";
import SalutationSelect from "../../../components/forms/selects/SalutationSelect";
import axios from "axios";
import DOMPurify from "dompurify";
import { getCurrentLanguage } from "../../../models/enums/salutationType.enum";

/**
 * RegistrationPage component
 * @returns {JSX.Element}
 */
export const RegistrationPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation(["authentication", "common", "snackbars"]);
  const [code, setCode] = React.useState<string | null>(null);
  const [orgaSkipGuardians, setOrgaSkipGuardians] = useState(false);
  const [minimumAge, setMinimumAge] = useState<number>(0);
  const [userMail, setUserMail] = useState<string | undefined>(undefined);
  // eslint-disable-next-line
  const [ATBandPrivacy, setATBandPrivacy] = useState(false);

  const minimumAgeFromApi = useSelector((state: AppState) => state.minimumAge);

  const currentDateLocal = new Date();
  const today = currentDateLocal.toDateString();

  const initialGroupMembershipDocumentValues: MembershipDocumentRequestDto = {
    file: "",
    valid_until: today,
    document_has_no_expiry_date: false,
    document_type: "",
  };

  // Local state for metadata and loading status
  const [metaData, setMetaData] = useState({
    registration_info_text_german: "",
    registration_info_text_english: "",
  });

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await axios.get("/api/meta-data");
        setMetaData({
          registration_info_text_german: response.data.registration_info_text_german,
          registration_info_text_english: response.data.registration_info_text_english,
        });
      } catch (error) {
        enqueueSnackbar(
          t("backendResponses.error.captureNotSaved", { ns: "snackbars" }),
          {
            variant: "error",
          }
        );
      } finally {
        setMetaDataIsLoading(false);
      }
    };
    fetchMetaData();
  }, [i18n.language]);

  const [featureFlags, setFeatureFlags] = useState({
    group_membership_is_enabled: false,
  });

  useEffect(() => {
    const fetchFeatureFlag = async () => {
      try {
        const response = await axios.get("/api/feature-flags");
        setFeatureFlags({
          group_membership_is_enabled: response.data.group_membership_is_enabled,
        });
      } catch (e) {
        enqueueSnackbar(
          t("backendResponses.error.captureNotSaved", { ns: "snackbars" }),
          {
            variant: "error",
          }
        );
      }
    };
    fetchFeatureFlag();
  }, []);

  const [groupMembershipDocument, setGroupMembershipDocument] =
    useState<MembershipDocumentRequestDto>(initialGroupMembershipDocumentValues);
  const [files, setFiles] = useState<FileObject[]>([]);

  const [guardianRequired, setGuardianRequired] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [birthday, setBirthday] = useState<MaterialUiPickersDate>();

  const {
    YupValidationFirstName,
    YupValidationLastName,
    YupValidationEmail,
    YupValidationConfirmEmail,
    YupValidationBirthday,
    YupValidationStreet,
    YupValidationStreetNumber,
    YupValidationCity,
    YupValidationZipCode,
    YupValidationConfirmPassword,
    YupValidationGuardianEmail,
    YupValidationSalutation,
    YupValidationConfirmGuardianEmail,
  } = useDynamicYupValidations();

  const registerValidationSchema = yup.object({
    first_name: YupValidationFirstName,
    last_name: YupValidationLastName,
    email: YupValidationEmail,
    email_confirmation: YupValidationConfirmEmail("email"),
    birthday: YupValidationBirthday,
    salutation: YupValidationSalutation,
    street: YupValidationStreet,
    street_number: YupValidationStreetNumber,
    city: YupValidationCity,
    zip_code: YupValidationZipCode,
    password: YupValidationConfirmPassword("confirmPassword"),
    confirmPassword: YupValidationConfirmPassword("password"),
    guardian_email: guardianRequired
      ? YupValidationGuardianEmail(minimumAge, userMail, orgaSkipGuardians).required(
          t(EMAIL_REQUIRED)
        )
      : YupValidationGuardianEmail(minimumAge, userMail, orgaSkipGuardians),
    guardian_email_confirmation: guardianRequired
      ? YupValidationConfirmGuardianEmail("guardian_email").required(t(EMAIL_REQUIRED))
      : YupValidationGuardianEmail(minimumAge, userMail, orgaSkipGuardians),
  });

  /**
   * Executes the effect hook to extract the verification code from the URL search parameters.
   * If a verification code is found, it sets the code state.
   * @param {object} location - The location object containing the search parameters.
   */
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const payloadToken = searchParams.get("token");
    if (payloadToken) {
      const decodedUrlPayload = jwtDecode<{
        code: string;
        skip_guardians: boolean;
      }>(payloadToken);
      setCode(decodedUrlPayload.code);
      setOrgaSkipGuardians(decodedUrlPayload.skip_guardians);
    }
  }, [location.search]);

  /**
   * Fetches the minimum age from the API.
   * The minimum age is used to determine if a user needs a guardian.
   */
  useEffect(() => {
    dispatch(fetchMinimumAge());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Sets the minimum age from the API response to the state.
   */

  useEffect(() => {
    if (!minimumAgeFromApi?.isLoading && minimumAgeFromApi?.minimumAge?.minimum_age) {
      setMinimumAge(minimumAgeFromApi.minimumAge.minimum_age);
    }
  }, [minimumAgeFromApi]);

  /**
   * Submits the registration form.
   * @param values - The form values.
   */
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const submitRegister = async (values: any) => {
    let dataUrl = "";

    if (files.length !== 0 && files[0].file) {
      dataUrl = await groupMembershipService.getFileAsDataUrl(files[0].file);
    }
    let validUntilDate = null;
    if (
      groupMembershipDocument.valid_until &&
      !groupMembershipDocument.document_has_no_expiry_date
    ) {
      validUntilDate = moment(groupMembershipDocument.valid_until).format("YYYY-MM-DD");
    }

    let group_membership_document = null;

    if (groupMembershipDocument && dataUrl.length !== 0) {
      group_membership_document = {
        file: dataUrl,
        valid_until: validUntilDate,
        document_has_no_expiry_date: groupMembershipDocument.document_has_no_expiry_date,
        document_type: groupMembershipDocument.document_type,
      };
    }

    const data: UserRegistrationRequestData = {
      first_name: values.first_name,
      last_name: values.last_name,
      salutation: values.salutation,
      email: values.email,
      birthday: moment(values.birthday).format("DD.MM.YYYY"),
      group_keys: values.group_keys,
      street: values.street,
      guardian_email: values.guardian_email,
      street_number: values.street_number,
      zip_code: values.zip_code,
      city: values.city,
      password: values.password,
      verification_code: code || undefined,
      group_membership_document: group_membership_document,
      terms_agreed_at: values.terms_agreed_at,
    };

    // Errors are handled in BaseCrudService
    usersService.register(data).then((success) => {
      if (success) {
        enqueueSnackbar(t("profile.accountRegistered", { ns: "snackbars" }), {
          variant: "success",
        });
        history.push(routes.register_success);
      }
    });
  };
  const [metaDataIsLoading, setMetaDataIsLoading] = useState(true);

  const registrationText =
    getCurrentLanguage() === "de" || getCurrentLanguage() === "de-DE"
      ? metaData.registration_info_text_german
      : metaData.registration_info_text_english;

  const createSafeHTML = (htmlString: string) => {
    return { __html: DOMPurify.sanitize(htmlString) };
  };

  // Show loader if metadata is still loading
  const registerContent = metaDataIsLoading ? (
    <CircularProgress />
  ) : (
    <Box
      className="register-content"
      style={{ padding: 0, margin: 0, overflowWrap: "anywhere" }}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <p dangerouslySetInnerHTML={createSafeHTML(registrationText)} />
    </Box>
  );

  return (
    <Box>
      <Grid item xs={12} md={9}>
        <Typography>{registerContent}</Typography>
        <Formik
          key={i18n.language}
          initialValues={initialData}
          onSubmit={submitRegister}
          validationSchema={registerValidationSchema}
          validateOnChange
        >
          {({ values, handleChange, setFieldValue, isValid }) => (
            <Form className="RegistrationForm">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <Field
                        as={SalutationSelect}
                        style={{ width: "100%" }}
                        className="RegistrationPage"
                        fullWidth
                        type="name"
                        name="salutation"
                        id="salutation-registration"
                        variant="filled"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        as={CustomTextField}
                        style={{ width: "100%" }}
                        className="RegistrationPage"
                        fullWidth
                        type="name"
                        name="first_name"
                        id="first-name-registration"
                        variant="filled"
                        label={t("profile.firstName", { ns: "common" })}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        as={CustomTextField}
                        style={{ width: "100%" }}
                        className="RegistrationPage"
                        fullWidth
                        type="name"
                        name="last_name"
                        id="last-name-registration"
                        variant="filled"
                        label={t("profile.lastName", { ns: "common" })}
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    as={CustomTextField}
                    style={{ width: "100%" }}
                    className="RegistrationPage"
                    fullWidth
                    type="E-Mail"
                    name="email"
                    id="email-registration"
                    variant="filled"
                    label={t("profile.mail", { ns: "common" })}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const lowercaseEmail = e.target.value.toLowerCase();
                      handleChange(e.target.name)(lowercaseEmail);
                      setUserMail(lowercaseEmail);
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    as={CustomTextField}
                    style={{ width: "100%" }}
                    className="RegistrationPage"
                    fullWidth
                    type="E-Mail"
                    name="email_confirmation"
                    id="email-confirmation"
                    variant="filled"
                    label={t("profile.mailConfirmation", { ns: "common" })}
                    required
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Field
                    as={CustomDatepicker}
                    fullWidth
                    autoComplete="birthday"
                    name="birthday"
                    id="birthday-registration"
                    label={t("profile.birthdate", { ns: "common" })}
                    value={values.birthday ?? ""}
                    customChangeHandler={(date: MaterialUiPickersDate) => {
                      setFieldValue("birthday", date);
                      setBirthday(date);
                      const dateString = date ? date.toISOString() : "";
                      setGuardianRequired(
                        userNeedsAGuardian(dateString, minimumAge, orgaSkipGuardians)
                      );
                    }}
                    required
                  />
                </Grid>
                {values.birthday
                  ? userNeedsAGuardian(
                      values.birthday,
                      minimumAge,
                      orgaSkipGuardians
                    ) && ( // eslint-disable-line
                      <>
                        <Grid item xs={12} lg={6}>
                          <Field
                            as={CustomTextField}
                            style={{ width: "100%", marginBottom: 8 }}
                            className="RegistrationPage"
                            fullWidth
                            type="E-Mail"
                            name="guardian_email"
                            variant="filled"
                            label={t("registration.guardianEmail", {
                              ns: "authentication",
                            })}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const lowercaseEmail = e.target.value.toLowerCase();
                              handleChange(e.target.name)(lowercaseEmail);
                            }}
                            required={guardianRequired}
                          />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Field
                            as={CustomTextField}
                            style={{ width: "100%", marginBottom: 8 }}
                            className="RegistrationPage"
                            fullWidth
                            type="E-Mail"
                            name="guardian_email_confirmation"
                            variant="filled"
                            label={t("registration.guardianEmailConfirmation", {
                              ns: "authentication",
                            })}
                            required={guardianRequired}
                          />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                          <Typography className="GuardianEmailNote">
                            {t("registration.guardianEmailNote", {
                              ns: "authentication",
                            })}
                          </Typography>
                        </Grid>
                      </>
                    )
                  : null}
                {/* Disabled only because of LU-561
                <Grid item lg={12}>
                <Field
                  as={CustomTextField}
                  fullWidth
                  id="group_keys"
                  label="Gruppenschlüssel"
                  name="group_keys"
                />
              </Grid> */}
              </Grid>
              <div className="RegistrationAddress" style={{ width: "100%" }}>
                <h3>{t("profile.addressHeader", { ns: "common" }).toUpperCase()}</h3>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={9}>
                  <Field
                    as={CustomTextField}
                    fullWidth
                    id="street-registration"
                    label={t("profile.street", { ns: "common" })}
                    name="street"
                    required
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    as={CustomTextField}
                    fullWidth
                    id="street-number-registration"
                    label={t("profile.streetNumber", { ns: "common" })}
                    name="street_number"
                    required
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    as={CustomTextField}
                    fullWidth
                    id="zip-code-registration"
                    label={t("profile.zipCode", { ns: "common" })}
                    name="zip_code"
                    required
                  />
                </Grid>{" "}
                <Grid item xs={12} lg={9}>
                  <Field
                    as={CustomTextField}
                    fullWidth
                    id="city-registration"
                    label={t("profile.city", { ns: "common" })}
                    name="city"
                    required
                  />
                </Grid>
              </Grid>

              <div className="RegistrationAdress" style={{ width: "100%" }}>
                <h3>{t("profile.password", { ns: "common" }).toUpperCase()}</h3>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <Field
                    as={CustomPasswordField}
                    fullWidth
                    id="password"
                    label={t("profile.password", { ns: "common" })}
                    name="password"
                    required
                  />
                </Grid>{" "}
                <Grid item xs={12} lg={6}>
                  <Field
                    as={CustomPasswordField}
                    fullWidth
                    id="confirm-password"
                    label={t("password.forgotten.confirm", {
                      ns: "authentication",
                    })}
                    name="confirmPassword"
                    required
                  />
                </Grid>
              </Grid>
              <p />
              {featureFlags.group_membership_is_enabled ? (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Box>
                      <GroupMembershipDocumentSection
                        groupMembershipDocument={groupMembershipDocument}
                        setGroupMembershipDocument={setGroupMembershipDocument}
                        files={files}
                        setFiles={setFiles}
                      />
                    </Box>
                  </Grid>
                </Grid>
              ) : null}
              <Box component="div" className="checkout-atb-box">
                {t("checkout.AGBPrivacyAccepted.part1", { ns: "booking" })}{" "}
                <Link
                  href={TERMS_AND_CONDITIONS_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("footer.agb", { ns: "layout" })}
                </Link>{" "}
                {t("checkout.AGBPrivacyAccepted.part2", { ns: "booking" })}{" "}
                <Link
                  href={routes.privacy_policy}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("privacyDec", { ns: "common" })}
                </Link>{" "}
                {t("checkout.AGBPrivacyAccepted.part3", { ns: "booking" })}{" "}
                <Switch
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setATBandPrivacy(event.target.checked);
                    const currentDateTime = new Date().toISOString();
                    setFieldValue("terms_agreed_at", currentDateTime);
                  }}
                  color="primary"
                  name="ATBandPrivacyCheck"
                  inputProps={{ "aria-label": "ATB and Privacy checkbox" }}
                />
              </Box>
              {!ATBandPrivacy && (
                <Box my={2}>
                  <Alert severity="error">
                    {t("checkout.AGBPrivacyAccept", { ns: "booking" })}
                  </Alert>
                </Box>
              )}
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControl className="registrationButton" fullWidth>
                    <Button
                      id="login"
                      type="submit"
                      size="large"
                      variant="contained"
                      color="primary"
                      disableElevation
                      disableRipple
                      disabled={!ATBandPrivacy || !isValid}
                    >
                      {t("loginPage.register", { ns: "authentication" })}
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Box>
  );
};
