import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NIL as NIL_UUID } from "uuid";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Grid,
  Tooltip,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import GetAppIcon from "@material-ui/icons/GetApp";
import { FolderZip } from "@mui/icons-material";
import HeadingLumos from "../../../../theming/HeadingLumos";
import { getOrganizationEventParticipationsByOrganizationId } from "../../../../../redux/organizationEventParticipations/action";
import { AppState } from "../../../../../redux";
import { OrganizationEventParticipationsData } from "../../../../../models/organizationEventParticipationsData";
import {
  ParticipationStatus,
  statusMainTranslationKeys,
} from "../../../../../models/enums/participationStatus.enum";
import Loader from "../../../../theming/loader/Loader";
import {
  BookingsManagementProps,
  UserParticipation,
} from "./BookingManagement.interfaces";
import { useTableRowState } from "../../../../../hooks/useTableRowState";

/**
 * Component for managing organization event participations and displaying bookings in a table format.
 *
 * @param {BookingsManagementProps} props - Props for the component.
 * @property {string} props.organizationId - The ID of the organization to fetch event participations for.
 * @returns {JSX.Element} The BookingsManagement component.
 */
export const BookingsManagement: React.FC<BookingsManagementProps> = ({
  organizationId,
}) => {
  const { t } = useTranslation(["enums", "organization", "snackbars", "common"]);
  const zipToolTip = t("bookings.tooltips.downloadCertificatesZip", {
    ns: "organization",
  });
  const dispatch = useDispatch();

  const [expandedRows, toggleRow] = useTableRowState("expandedTableRows");

  const { organizationEventParticipationsData = [], isLoading } = useSelector(
    (state: AppState) => state.organizationEventParticipations
  );

  /**
   * Fetches event participations for the given organization ID when the
   * component is mounted or the organization ID changes.
   */
  useEffect(() => {
    dispatch(getOrganizationEventParticipationsByOrganizationId(organizationId));
  }, [dispatch, organizationId]);

  /**
   * Maps the participation status to its corresponding translation key.
   *
   * @param {string} status - The status to map.
   * @returns {string} The translated key for the status.
   */
  const mapToParticipationStatus = (status: string): string => {
    return statusMainTranslationKeys[status];
  };

  /**
   * Array of status column keys, representing different participation statuses.
   * @type {string[]}
   */
  const statusColumns: string[] = [
    String(ParticipationStatus.WaitingList),
    String(ParticipationStatus.Booked),
    String(ParticipationStatus.Participated),
    String(ParticipationStatus.NotParticipated),
  ];

  return (
    <Box>
      <HeadingLumos>
        {t("bookings.manageBookingsTitle", { ns: "organization" })}
      </HeadingLumos>
      <Typography>{t("bookings.manageBookingsDesc", { ns: "organization" })}</Typography>
      <Box style={{ width: "100%", marginTop: "1.25rem" }}>
        {isLoading ? (
          <Loader />
        ) : organizationEventParticipationsData.length === 0 ? (
          <Typography>
            {t("bookings.manageBookingsNoBookings", { ns: "organization" })}
          </Typography>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table
                  aria-label="collapsible table"
                  style={{ tableLayout: "auto", width: "100%" }}
                >
                  <TableBody>
                    {organizationEventParticipationsData.map(
                      (eventParticipation: OrganizationEventParticipationsData) => {
                        const {
                          event,
                          status = {},
                          certificatesZipUrl,
                        } = eventParticipation;
                        const isExpanded = expandedRows[event] || false;

                        return (
                          <React.Fragment key={event}>
                            <TableRow>
                              <TableCell
                                align="left"
                                style={{
                                  wordBreak: "break-word",
                                  whiteSpace: "nowrap",
                                  fontWeight: "bold",
                                }}
                              >
                                {event}
                              </TableCell>
                              <TableCell align="right">
                                {certificatesZipUrl ? (
                                  <Tooltip title={zipToolTip} placement="top" arrow>
                                    <IconButton href={certificatesZipUrl}>
                                      <FolderZip />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  ""
                                )}
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => toggleRow(event)}
                                >
                                  {isExpanded ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </TableCell>
                            </TableRow>
                            {isExpanded ? (
                              <TableRow>
                                <TableCell
                                  colSpan={2}
                                  style={{ paddingBottom: 0, paddingTop: 0 }}
                                >
                                  <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                                    <Box margin={1}>
                                      <Table
                                        size="small"
                                        aria-label="expanded-status"
                                        style={{ tableLayout: "auto", width: "100%" }}
                                      >
                                        <TableHead>
                                          <TableRow>
                                            {statusColumns.map((statusKey) => (
                                              <TableCell
                                                key={statusKey}
                                                style={{
                                                  wordBreak: "break-word",
                                                  whiteSpace: "nowrap",
                                                  fontWeight: "bold",
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                <Trans>
                                                  {t(
                                                    mapToParticipationStatus(statusKey),
                                                    {
                                                      ns: "enums",
                                                    }
                                                  )}
                                                </Trans>
                                              </TableCell>
                                            ))}
                                            <TableCell
                                              style={{
                                                wordBreak: "break-word",
                                                whiteSpace: "nowrap",
                                                fontWeight: "bold",
                                                verticalAlign: "top",
                                              }}
                                            >
                                              {t("bookings.certificate", {
                                                ns: "organization",
                                              })}
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {Object.keys(status).map((statusKey) => {
                                            const users: UserParticipation[] = (
                                              status[statusKey] || []
                                            ).map((user) =>
                                              typeof user === "string"
                                                ? // eslint-disable-next-line camelcase
                                                  { user, certificate_link: null }
                                                : user
                                            );

                                            return users.map(
                                              (user: UserParticipation) => (
                                                <TableRow
                                                  key={`${statusKey}-${NIL_UUID}`}
                                                >
                                                  {statusColumns.map((columnKey) => (
                                                    <TableCell
                                                      key={columnKey}
                                                      style={{
                                                        wordBreak: "break-word",
                                                        whiteSpace: "pre-wrap",
                                                      }}
                                                    >
                                                      {columnKey === statusKey
                                                        ? user.user
                                                        : ""}
                                                    </TableCell>
                                                  ))}
                                                  <TableCell
                                                    style={{
                                                      wordBreak: "break-word",
                                                      whiteSpace: "pre-wrap",
                                                      textAlign: "left",
                                                    }}
                                                  >
                                                    {user.certificate_link ? (
                                                      <IconButton
                                                        size="small"
                                                        href={user.certificate_link}
                                                      >
                                                        <GetAppIcon />
                                                      </IconButton>
                                                    ) : null}
                                                  </TableCell>
                                                </TableRow>
                                              )
                                            );
                                          })}
                                        </TableBody>
                                      </Table>
                                    </Box>
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            ) : null}
                          </React.Fragment>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default BookingsManagement;
