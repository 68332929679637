import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { enqueueSnackbar } from "notistack";
import { systemSettingsService } from "../../api/services/systemSettingsService";
import {
  MinimumAgeActionTypes,
  FetchMinimumAgeRequestAction,
  FetchMinimumAgeSuccessAction,
  FetchMinimumAgeFailureAction,
  FETCH_MINIMUM_AGE_REQUEST,
  FETCH_MINIMUM_AGE_SUCCESS,
  FETCH_MINIMUM_AGE_FAILURE,
} from "./types";
import { MinimumAgeData } from "../../models/systemSettingsData";
import { getDynamicTranslation } from "../../components/core/localization/localizationUtils";

/**
 * @returns {FetchMinimumAgeRequestAction}
 */
export function fetchMinimumAgeRequest(): FetchMinimumAgeRequestAction {
  return {
    type: FETCH_MINIMUM_AGE_REQUEST,
  };
}

/**
 * @param {MinimumAgeData} payload - The minimum age data
 * @returns {FetchMinimumAgeSuccessAction}
 */
export function fetchMinimumAgeSuccess(
  payload: MinimumAgeData
): FetchMinimumAgeSuccessAction {
  return {
    type: FETCH_MINIMUM_AGE_SUCCESS,
    payload,
  };
}

/**
 * @param {string} payload - The error message
 * @returns {FetchMinimumAgeFailureAction}
 */
export function fetchMinimumAgeFailure(error: string): FetchMinimumAgeFailureAction {
  return {
    type: FETCH_MINIMUM_AGE_FAILURE,
    error,
  };
}

/**
 * Fetches the minimum age data
 * @returns {Promise<void>}
 */
export function fetchMinimumAge() {
  return async (dispatch: Dispatch<MinimumAgeActionTypes>) => {
    dispatch(fetchMinimumAgeRequest());

    try {
      const response = await systemSettingsService.getMinimumAge();
      dispatch(fetchMinimumAgeSuccess(response));
    } catch (error) {
      const axiosError = error as AxiosError;
      const errorMessage =
        axiosError.response?.data?.message ||
        getDynamicTranslation("systemSettings.fetchMinimumAgeFailed", "errorPages");
      dispatch(fetchMinimumAgeFailure(errorMessage));
      enqueueSnackbar(errorMessage);
    }
  };
}
