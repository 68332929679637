import {
  OrganizationSettingsActionTypes,
  LOAD_ORGANIZATION_SETTINGS_REQUEST,
  LOAD_ORGANIZATION_SETTINGS_FAILURE,
  LOAD_ORGANIZATION_SETTINGS_SUCCESS,
  SET_ORGANIZATION_SETTINGS_REQUEST,
  SET_ORGANIZATION_SETTINGS_FAILURE,
  SET_ORGANIZATION_SETTINGS_SUCCESS,
  OrganizationSettingsState,
} from "./types";

/**
 * Initial state for the organization settings reducer.
 */
export const initialOrganizationSettingsState: OrganizationSettingsState = {
  // eslint-disable-next-line camelcase
  organizationSettingsData: { id: "", skip_guardians: false },
  isLoading: false,
  organizationSettingsDataLoaded: false,
  error: null,
};

/**
 * Reducer function for managing organization settings related state.
 * @param state Current state of the organization settings.
 * @param action Action dispatched to update organization settings state.
 * @returns Updated state of the organization settings.
 */
export function organizationSettingsReducer(
  state = initialOrganizationSettingsState,
  action: OrganizationSettingsActionTypes
): OrganizationSettingsState {
  switch (action.type) {
    // Handling Load actions
    case LOAD_ORGANIZATION_SETTINGS_REQUEST:
    case SET_ORGANIZATION_SETTINGS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case LOAD_ORGANIZATION_SETTINGS_FAILURE:
    case SET_ORGANIZATION_SETTINGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case LOAD_ORGANIZATION_SETTINGS_SUCCESS:
      return {
        ...state,
        organizationSettingsData: action.data,
        organizationSettingsDataLoaded: true,
        isLoading: false,
      };

    case SET_ORGANIZATION_SETTINGS_SUCCESS:
      return {
        ...state,
        organizationSettingsData: action.data,
        isLoading: false,
      };

    default:
      return state;
  }
}

export default organizationSettingsReducer;
