import { useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";

/**
 * Options for the useTabPersistence hook.
 * @interface UseTabPersistenceOptions
 */
interface UseTabPersistenceOptions {
  defaultTab?: number;
  // eslint-disable-next-line no-unused-vars
  onTabChange?: (newIndex: number) => void;
}

/**
 * Custom hook to manage tab persistence across page refreshes and navigation.
 *
 * @param {UseTabPersistenceOptions} options - Options containing `defaultTab` and an optional `onTabChange` function.
 * @returns {Object} - Returns `activeTab` and `handleTabChange` function.
 */
export const useTabPersistence = ({
  defaultTab = 0,
  onTabChange,
}: UseTabPersistenceOptions) => {
  const location = useLocation();
  const history = useHistory();
  const firstRender = useRef(true);

  const params = new URLSearchParams(location.search);
  const initialTab = Number(params.get("tab")) || defaultTab;
  const [activeTab, setActiveTab] = useState(initialTab);

  /**
   * This effect is used to update the active tab when the URL changes.
   */
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    const urlTab = Number(new URLSearchParams(location.search).get("tab"));
    if (!isNaN(urlTab) && urlTab !== activeTab) setActiveTab(urlTab);
  }, [location.search]);

  /**
   * This function is used to handle tab changes.
   * @param {number} newIndex - The index of the new tab.
   * @returns {void}
   */
  const handleTabChange = (newIndex: number) => {
    if (newIndex !== activeTab) {
      setActiveTab(newIndex);
      history.replace({ search: `tab=${newIndex}` });
      onTabChange?.(newIndex);
    }
  };

  return { activeTab, handleTabChange };
};
