import { AuthService } from "./auth/authService";
import { DocumentsService } from "./documentsService";
import { EventService } from "./event/eventService";
import { SpeakerService } from "./speakerService";
import { BookingService } from "./bookingService";
import { ParticipantsService } from "./participantsService";
import { LecturesService } from "./lecturesService";
import { CartService } from "./cartService";
import { UsersService } from "./usersService";
import { TasksService } from "./tasksService";
import { ContactService } from "./contactService";
import { TemplateService } from "./templateService";
import { FavoriteService } from "./favoriteService";
import { ChecklistService } from "./checklistService";
import { BillingAddressService } from "./billingAddressService";
import { LinkAccountService } from "./linkAccountService";
import { TargetGroupService } from "./targetGroupService";
import { ELearningService } from "./elearningService";
import { PinboardNoAlertService, PinboardService } from "./pinboardService";
import { DashboardService } from "./dashboardService";
import { VideoAuthService } from "./auth/videoAuthService";
import { GroupMembershipService } from "./groupMembershipService";
import { GroupMembershipDocumentTypeService } from "./groupMembershipDocumentTypeService";
import { InvoiceService } from "./invoiceService";
import { CancellationInvoiceService } from "./cancellationInvoiceService";
import { OrderService } from "./orderService";
import { DiscountService } from "./discountService";
import { PaypalService } from "./paypalService";
import { OrganizationService } from "./organization/organizationService";
import { OrganizationTypeService } from "./organization/organizationTypeService";
import { OrganizationMembershipService } from "./organization/organizationMembershipService";
import { CertificateTypeService } from "./certificateTypeService";
import { AccountInvitesService } from "./organization/invitesToJoinOrganizationService";
import { OrganizationInvoiceService } from "./organizationInvoiceService";
import { LoggerService } from "./loggerService";
import { PaymentMethodService } from "./paymentMethodService";
import { MetaDataService } from "./metaDataService";
import { OrganizationEventParticipationsService } from "./organizationEventParticipationsService";
import { StripeService } from "./stripeService";
import { BundIDService } from "./bundIDService";
import { FeatureFlaggingService } from "./featureFlaggingService";
import { CertificateService } from "./certificateService";
import { UserLanguageService } from "./userLanguageService";

// all instances of services
export const authService = new AuthService();
export const bookingService = new BookingService();
export const cartService = new CartService();
export const documentsService = new DocumentsService();
export const eventService = new EventService();
export const elearningService = new ELearningService();
export const lecturesService = new LecturesService();
export const participantsService = new ParticipantsService();
export const speakerService = new SpeakerService();
export const usersService = new UsersService();
export const tasksService = new TasksService();
export const contactService = new ContactService();
export const templateService = new TemplateService();
export const favoriteService = new FavoriteService();
export const checklistService = new ChecklistService();
export const billingAddressService = new BillingAddressService();
export const linkAccountService = new LinkAccountService();
export const targetGroupService = new TargetGroupService();
export const pinboardService = new PinboardService();
export const pinboardNoAlertService = new PinboardNoAlertService();
export const dashboardService = new DashboardService();
export const videoAuthService = new VideoAuthService();
export const groupMembershipService = new GroupMembershipService();
export const groupMembershipDocumentTypeService =
  new GroupMembershipDocumentTypeService();
export const invoiceService = new InvoiceService();
export const cancellationInvoiceService = new CancellationInvoiceService();
export const discountService = new DiscountService();
export const orderService = new OrderService();
export const paypalService = new PaypalService();
export const organizationEventParticipationsService =
  new OrganizationEventParticipationsService();
export const organizationService = new OrganizationService();
export const organizationTypeService = new OrganizationTypeService();
export const organizationInvoiceService = new OrganizationInvoiceService();
export const organizationMembershipService = new OrganizationMembershipService();
export const certificateTypeService = new CertificateTypeService();
export const accountInvitesService = new AccountInvitesService();
export const loggerService = new LoggerService();
export const paymentMethodService = new PaymentMethodService();
export const metaDataService = new MetaDataService();
export const bundIDService = new BundIDService();
export const featureFlaggingService = new FeatureFlaggingService();
export const certificateService = new CertificateService();
export const stripeService = new StripeService();
export const userLanguageService = new UserLanguageService();
