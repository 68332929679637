import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import clsx from "clsx";
import { FieldAttributes, useField } from "formik";
import * as React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../redux";
import { useTranslation } from "react-i18next";

/**
 * @interface CustomCheckboxProps
 */
interface CustomCheckboxProps {
  label?: string | React.ReactNode;
  small?: boolean;
  tooltipText?: string;
}

/**
 * @interface StyleProps
 */
export interface StyleProps {
  monoMode: boolean;
  disabled?: boolean;
}

/**
 * @function useStyles
 * @param {StyleProps} props
 */
const useStyles = makeStyles({
  small: {
    transform: "scale(0.75)",
  },
  large: {},
  icon: {
    width: 30,
    height: 30,
    transition: "opacity 300ms, border 300ms",
  },
  checkedIcon: {
    width: 29,
    height: 15,
    position: "absolute",
    transform: "rotate(-45deg)",
    transition: "border-color 300ms, background-color 300ms",
    border: (props: StyleProps) =>
      props.disabled
        ? ".1875rem solid #a2a2a2"
        : props.monoMode
          ? ".1875rem solid #000"
          : ".1875rem solid var(--secondary-color)",
    borderTop: (props: StyleProps) => (props.monoMode ? "none" : "none"),
    borderRight: (props: StyleProps) => (props.monoMode ? "none" : "none"),
    top: "0rem",
    left: ".1875rem",
  },
});

/**
 * @component CustomCheckbox
 * @param {FieldAttributes<unknown> & CustomCheckboxProps & CheckboxProps} props
 * @returns {React.ReactElement}
 */
const CustomCheckbox: React.FunctionComponent<
  FieldAttributes<unknown> & CustomCheckboxProps & CheckboxProps
> = ({ label, ...props }) => {
  const { t } = useTranslation(["layout"]);
  const [field, meta] = useField<unknown>(props);
  const error = meta.error && meta.touched ? meta.error : ""; // get validation error text
  const [focused, setFocused] = React.useState(false);
  const accessibility = useSelector((state: AppState) => state.accessibility);
  const monoMode = accessibility.monoMode;
  const classes = useStyles({ monoMode: monoMode, disabled: props.disabled });

  const small = props.small;
  props.small = undefined; /* gives warning when passed to Checkbox */

  return (
    <>
      <FormControlLabel
        style={{ margin: 0 }}
        className={small ? classes.small : classes.large}
        control={
          <Tooltip
            title={
              props.tooltipText
                ? props.tooltipText
                : t("disabled", {
                    ns: "layout",
                  })
            }
            disableHoverListener={!props.disabled}
            arrow
          >
            <Box>
              <Checkbox
                {...field}
                {...props}
                disabled={props.disabled}
                onFocus={() => {
                  setFocused(true);
                }}
                onBlur={() => {
                  setFocused(false);
                }}
                checked={
                  field.value === undefined ? props.checked : (field.value as boolean)
                }
                color="default"
                className={
                  focused ? (accessibility.monoMode ? "focused-mono" : "focused") : ""
                }
                checkedIcon={
                  <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                icon={<span className={classes.icon} />}
                inputProps={{ "aria-label": "checkbox" }}
              />
            </Box>
          </Tooltip>
        }
        label={label ? <Box style={{ marginLeft: "1.25rem" }}>{label}</Box> : ""}
      />
      <Box className="error-text">{error}</Box>
    </>
  );
};

export default CustomCheckbox;
