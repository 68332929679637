import i18n from "i18next";
import { UserLanguage, LanguageOption } from "../../../models/userLanguageData";

export const languageOptionsFallback: LanguageOption[] = [
  { value: "en", label: "English", isDefault: false },
  { value: "de", label: "Deutsch", isDefault: true },
];

export const defaultLanguage: LanguageOption = {
  value: "de",
  label: "Deutsch",
  isDefault: true,
};

export function getCurrentLanguage(): string {
  return i18n.language.split("-")[0] || "de";
}

export function getDynamicTranslation(key: string | unknown, ns: string): string {
  return i18n.t(key, { ns, lng: getCurrentLanguage() });
}

/**
 * Formats an array of UserLanguage objects into structure usable in language switcher.
 *
 * @param {UserLanguage[]} languages - The array of `UserLanguage` objects to format.
 * @returns {LanguageOption[]} An array of formatted language options.
 */
export const formatLanguageOptions = (languages: UserLanguage[]) => {
  return languages.map((language) => ({
    value: language.language_code,
    label: language.name_native,
    isDefault: language.is_default,
  }));
};
