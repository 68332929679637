import React from "react";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import { Grid } from "@material-ui/core";
import "./UserInvoicePage.scss";
import { useTranslation } from "react-i18next";
import { useTabPersistence } from "../../../hooks/useTabPersistence";
import { InvoicesTable } from "../../../components/core/invoice/InvoicesTable";
import TabsLumos from "../../../components/theming/tabs/TabsLumos";

export const UserInvoicePage: React.FC = () => {
  const { t } = useTranslation(["common", "invoices", "snackbars"]);

  const { activeTab, handleTabChange } = useTabPersistence({ defaultTab: 0 });

  const tabs = [
    {
      label: t("title", { ns: "invoices" }),
      content: <InvoicesTable />,
    },
    // {
    //   label: t("cancellationInvoices.title", { ns: "invoices" }),
    //   content: <InvoicesTable isCancellationInvoiceList />,
    // },
  ];

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <HeadingLumos>{t("header", { ns: "invoices" })}</HeadingLumos>
        </Grid>
      </Grid>
      <TabsLumos tabs={tabs} activeTab={activeTab} onTabChange={handleTabChange} />
    </>
  );
};
