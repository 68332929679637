import { showDialog } from "../../../redux/dialogs/actions";
import { getDynamicTranslation } from "../../core/localization/localizationUtils";

const RevokedCertificateDialog = () =>
  showDialog(
    {
      title: getDynamicTranslation("dialogues.revokedCertificate.title", "layout"),
      message: (
        <>{getDynamicTranslation("dialogues.revokedCertificate.text", "layout")}</>
      ),
      confirmButtonText: getDynamicTranslation("closeHint", "common"),
    },
    true
  );
export default RevokedCertificateDialog;
