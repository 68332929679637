import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { enqueueSnackbar } from "notistack";
import {
  DocumentUploadDataResponse,
  DocumentData,
  ScormFile,
} from "../../models/documentData";

// Base URL and API key for the SCORM service
const scormApiEndpoint = process.env.REACT_APP_SCORM_BASE_URL || "";
const scormApiKey = process.env.REACT_APP_SCORM_API_KEY || "";
// TODO const scormRealm = process.env.REACT_APP_SCORM_REALM || "";

// Base configuration for SCORM API requests
const baseScormConfig: AxiosRequestConfig = {
  headers: {
    Authorization: `Basic ${scormApiKey}`,
  },
};

/**
 * Uploads a SCORM package to the SCORM service for a specific course.
 *
 * This function is designed to handle the upload of SCORM packages to both a
 * Django server and a Scorm Cloud service. It extracts the SCORM file from the
 * provided FormData and performs the necessary actions to upload the file to both services.
 * If an existingScormFile is provided, it uses its ID to associate the new SCORM
 * package with an existing course; otherwise, it creates a new course on
 * the Django server and uses the generated ID for the Scorm Cloud upload.
 *
 * @param {FormData} data - The FormData containing the SCORM package file to be uploaded.
 * @param {string} courseId - The unique identifier of the course to which
 *        the SCORM package will be associated.
 * @returns {Promise<DocumentUploadDataResponse>} A promise that resolves to the
 *          response containing information about the uploaded document.
 * @throws If an error occurs during the upload process.
 */
export const uploadToScormService = async (
  data: FormData,
  courseId: string
): Promise<DocumentUploadDataResponse> => {
  try {
    // Configuration for the Axios request
    const config: AxiosRequestConfig = {
      headers: {
        ...baseScormConfig.headers,
        "Content-Type": "multipart/form-data",
        uploadedContentType: "application/zip",
        accept: "application/json",
      },
    };

    // Perform the POST request to upload the SCORM package
    const response = await axios.post(
      `${scormApiEndpoint}/courses/importJobs/upload?courseId=${courseId}&mayCreateNewVersion=true`,
      data,
      config
    );
    // Extract jobId from the response
    const jobId = response.data.result;
    // Wait until the upload is completed
    await waitForScormProcessing(jobId);
    // Set course properties after upload
    await setScormCourseProperties(courseId);

    return response;
  } catch (error) {
    // If an error occurs during the upload, throw the error
    enqueueSnackbar(
      "Beim Hochladen der Scorm Datei ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!",
      { variant: "error" }
    );
    throw error;
  }
};

/**
 * Polls the SCORM API to monitor the status of an import job.
 *
 * This function continuously checks the job status every 8 seconds until:
 * - The job status is `"COMPLETE"` (success).
 * - The job status is neither `"RUNNING"` nor `"COMPLETE"` (failure).
 * - The maximum wait time (default 5 minutes) is exceeded (timeout).
 *
 * @param {string} jobId - The unique identifier for the SCORM import job.
 * @param {number} [maxWaitTime=5 * 60 * 1000] - Maximum wait time in milliseconds (default: 5 minutes).
 * @throws {Error} If the job fails, times out, or encounters an API error.
 * @returns {Promise<void>} Resolves when the job completes successfully.
 */
const waitForScormProcessing = async (
  jobId: string,
  maxWaitTime = 5 * 60 * 1000 // 5 minutes
): Promise<void> => {
  const delay = 8000; // 8-second polling delay
  let elapsedTime = 0;

  while (elapsedTime < maxWaitTime) {
    try {
      // Fetch the current status of the SCORM import job
      const { data } = await axios.get<{ status: string }>(
        `${scormApiEndpoint}/courses/importJobs/${jobId}`,
        baseScormConfig
      );
      // Exit successfully if job is completed
      if (data.status === "COMPLETE") return;
      // Throw an error if the job status is neither "RUNNING" nor "COMPLETE"
      if (data.status !== "RUNNING") {
        throw new Error(`SCORM Upload failed. Unexpected status: ${data?.status}`);
      }
    } catch {
      throw new Error("SCORM processing failed due to an API error.");
    }
    // Wait before polling again
    await new Promise((resolve) => setTimeout(resolve, delay));
    elapsedTime += delay;
  }
  // Throw an error if the job exceeds the max wait time
  throw new Error("SCORM processing timed out after exceeding max wait time.");
};

// Set course properties after upload
const setScormCourseProperties = async (courseId: string) => {
  try {
    const config: AxiosRequestConfig = {
      headers: {
        ...baseScormConfig.headers,
        "Content-Type": "application/json",
        accept: "application/json",
      },
    };

    const courseSettings = {
      settings: [
        { settingId: "PlayerScoLaunchType", value: "FRAMESET", explicit: true },
        { settingId: "PlayerLaunchType", value: "FRAMESET", explicit: true },
        { settingId: "PlayerDesiredFullScreen", value: "true", explicit: true },
      ],
    };

    await axios.post(
      `${scormApiEndpoint}/courses/${courseId}/configuration`,
      courseSettings,
      config
    );
  } catch (error) {
    enqueueSnackbar(
      `Fehler beim Setzen der SCORM-Kurseigenschaften. Bitte erneut versuchen! ${error}`,
      { variant: "error" }
    );
  }
};

/**
 * Fetches a SCORM course by its ID from the SCORM API.
 *
 * This function is responsible for making a GET request to the SCORM API to retrieve
 * information about a specific SCORM course. It uses the provided `scormCourseId`
 * to identify the course and includes the necessary headers for authentication.
 * The resulting AxiosResponse contains the data associated with the SCORM course.
 *
 * @param scormCourseId - The ID of the SCORM course to fetch.
 * @returns A promise that resolves to the AxiosResponse containing the SCORM course data.
 * @throws If an error occurs during the request.
 */
export const getScormCourseById = async (
  scormCourseId: string
): Promise<AxiosResponse<ScormFile>> => {
  return await axios.get<ScormFile>(
    `${scormApiEndpoint}/courses/${scormCourseId}`,
    baseScormConfig
  );
};

/**
 * Deletes a SCORM course by its ID from the SCORM API.
 *
 * This function is responsible for making a DELETE request to the SCORM API to
 * delete a specific SCORM course. It uses the provided `scormCourseId` to
 * identify the course and includes the necessary headers for authentication.
 * The resulting AxiosResponse contains information about the deleted SCORM course.
 *
 * @param scormCourseId - The ID of the SCORM course to be deleted.
 * @returns A promise that resolves to the AxiosResponse containing the deleted SCORM course data.
 * @throws If an error occurs during the API request.
 */
export const deleteCourseById = async (
  scormCourseId: string
): Promise<AxiosResponse<ScormFile>> => {
  return await axios.delete<ScormFile>(
    `${scormApiEndpoint}/courses/${scormCourseId}`,
    baseScormConfig
  );
};

/**
 * Asynchronously checks if there is any SCORM file in the existing documents.
 *
 * This function takes an array of existing documents and searches for the first
 * document with `is_scorm_file` set to true. If such a document is found,
 * it retrieves additional information about the corresponding SCORM course using its ID.
 * The resulting ScormFileInfo includes the SCORM course ID, title, and version.
 * If no SCORM file is found, the function resolves to undefined.
 *
 * @param {DocumentData[]} existingDocuments - An array of existing documents.
 * @returns {Promise<ScormFile | undefined>} A promise that resolves to ScormFileInfo
 *          if a SCORM file is found, otherwise resolves to undefined.
 */
export const checkForScormFile = async (
  existingDocuments: DocumentData[]
): Promise<ScormFile | undefined> => {
  // Check if there is any SCORM file among the existing documents
  const scormFileDocument = existingDocuments?.find(
    (document) => document.is_scorm_file
  );
  // If a SCORM file document is found
  if (scormFileDocument?.id) {
    try {
      const scormFromCloud = await getScormCourseById(scormFileDocument?.id);

      return {
        id: scormFileDocument?.id || "",
        title: scormFromCloud?.data?.title || "",
        version: scormFromCloud?.data?.version || "",
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return undefined;
    }
  }
};

/**
 * Updates the title of a SCORM course.
 *
 * @param {string} scormCourseId - The unique identifier of the SCORM course.
 * @param {string} title - The new title for the SCORM course.
 * @returns {Promise<AxiosResponse<ScormFile>>} A promise resolving to the updated SCORM course data.
 *
 * @throws {AxiosError} If the API request fails, the error should be handled where this function is called.
 */
export const updateScormTitle = async (
  scormCourseId: string,
  title: string
): Promise<AxiosResponse<ScormFile> | undefined> => {
  try {
    const isScorm = await getScormCourseById(scormCourseId);
    if (isScorm) {
      return await axios.put<ScormFile>(
        `${scormApiEndpoint}/courses/${scormCourseId}/title`,
        { title },
        baseScormConfig
      );
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("error in updateScormTitle: ", e);
    return undefined;
  }
};
