/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Loader from "../../../../theming/loader/Loader";
import { getBundIDdata } from "../../../../../redux/bundID/actions";
import { useSnackbar } from "notistack";
import routes from "../../../../../routing/routes";

export const BundIDCheck: React.FC = () => {
  const { t } = useTranslation(["authOptions"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const bundIDState = useSelector((appState: AppState) => appState.bundID);

  const [elearningID, setElearningID] = useState("");
  const [eventParticipationID, setEventParticipationID] = useState("");

  let verified = false;

  useEffect(() => {
    if (!bundIDState.isLoading && !bundIDState.bundIDDataLoaded) {
      dispatch(getBundIDdata());
    }
    if (bundIDState.bundIDRequestData) {
      setEventParticipationID(bundIDState.bundIDRequestData.event_participation);
      setElearningID(bundIDState.bundIDRequestData.event);
    }
  }, [bundIDState]);

  if (bundIDState.isLoading && !bundIDState.bundIDDataLoaded) {
    return <Loader />;
  }

  if (bundIDState.bundIDDataLoaded) {
    if (
      bundIDState.bundIDData.session_key !== null &&
      bundIDState.bundIDRequestData.unmatched_fields.length === 0
    ) {
      const successMessage = t("events.authenticationSuccess", { ns: "snackbars" });
      enqueueSnackbar(successMessage, { variant: "success" });
      verified = true;
    } else {
      const errMessage =
        t("events.authenticationError", { ns: "snackbars" }) +
        t("events.bundIDauthUnmatchedFieldsNote", { ns: "snackbars" }) +
        ` ${bundIDState.bundIDRequestData.unmatched_fields}`;
      enqueueSnackbar(errMessage, { variant: "error" });
    }
  }

  if (elearningID.length !== 0 && eventParticipationID.length !== 0) {
    if (verified) {
      history.push(
        routes.elearning_test_id
          .replace(":elearning_id", elearningID)
          .replace(":event_participation_id", eventParticipationID)
      );
    } else {
      history.push(
        routes.my_events_details_elearning_id
          .replace(":id", elearningID)
          .replace(":event_participation_id", eventParticipationID)
      );
    }
  }

  return null;
};
