/* eslint-disable camelcase */
import { useState } from "react";
import * as yup from "yup";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import { enqueueSnackbar } from "notistack";
import { Grid, Button, Box, Typography } from "@material-ui/core";
import { useDynamicYupValidations } from "../../../../../libs/yup-validations";
import { OrganizationMembershipInviteData } from "../../../../../models/organizationMembershipData";
import { createMembershipInvite } from "../../../../../redux/organizationMembership/action";
import CustomTextField from "../../../../forms/CustomTextField";
import HeadingLumos from "../../../../theming/HeadingLumos";
import CsvBulkUpload from "./CsvBulkUpload";

/**
 * @interface MembershipInviteFormData
 */
interface MembershipInviteFormData {
  firstName: string;
  lastName: string;
  email: string;
}

/**
 * @interface AddMembershipProps
 */
interface AddMembershipProps {
  organizationId: string;
  onMembershipAdded: () => void;
}

/**
 * Component for adding memberships to an organization.
 * @param {AddMembershipProps} props - The component props.
 */
const AddMembership = ({ organizationId, onMembershipAdded }: AddMembershipProps) => {
  const { t } = useTranslation(["common", "organization"]);
  const [invitedMembers, setInvitedMembers] = useState<
    OrganizationMembershipInviteData[]
  >([]);
  const [isOpenSection, setIsOpenSection] = useState(false);

  const dispatch = useDispatch();
  const { YupValidationFirstName, YupValidationLastName, YupValidationEmail } =
    useDynamicYupValidations();

  const validationSchema = yup.object({
    firstName: YupValidationFirstName,
    lastName: YupValidationLastName,
    email: YupValidationEmail,
  });

  /**
   * Handles form submission.
   * @param {MembershipInviteFormData} values - The form values.
   */
  const handleSubmit = async (
    values: MembershipInviteFormData,
    { resetForm }: { resetForm: () => void }
  ) => {
    try {
      const data: OrganizationMembershipInviteData = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        organization_id: organizationId,
      };
      const success = await dispatch(createMembershipInvite(data));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (success) {
        setInvitedMembers((prevInvitedMembers) => [...prevInvitedMembers, data]);
        resetForm();
        onMembershipAdded();
        setIsOpenSection(false);
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  };

  return (
    <>
      <Box style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={() => setIsOpenSection(!isOpenSection)}
          variant={isOpenSection ? "outlined" : "contained"}
          color="primary"
          style={{ marginBottom: "1rem" }}
        >
          {isOpenSection
            ? t("forms.buttons.close", { ns: "common" })
            : t("membership.addMemberships", { ns: "organization" })}
        </Button>
      </Box>
      {isOpenSection ? (
        <Box style={{ paddingBottom: "3rem" }}>
          <HeadingLumos>
            {t("membership.addMember", { ns: "organization" })}
          </HeadingLumos>
          <Formik
            key={i18n.language}
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
            }}
            onSubmit={handleSubmit}
          >
            {({ values }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={3}>
                    <CustomTextField
                      fullWidth
                      autoComplete="firstName"
                      name="firstName"
                      id="firstname-textfield-add-membership"
                      label={t("profile.firstName", { ns: "common" })}
                      value={values.firstName ?? ""}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <CustomTextField
                      fullWidth
                      value={values.lastName ?? ""}
                      autoComplete="lastName"
                      name="lastName"
                      id="lastname-textfield-add-membership"
                      label={t("profile.lastName", { ns: "common" })}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <CustomTextField
                      fullWidth
                      value={values.email ?? ""}
                      type="email"
                      autoComplete="email"
                      name="email"
                      id="email-textfield-add-membership"
                      label={t("profile.mail", { ns: "common" })}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <Button
                      id="add-organization"
                      type="submit"
                      size="large"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      {t("membership.invite", { ns: "organization" })}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          {invitedMembers?.length > 0 &&
            invitedMembers.map((member) => (
              <Typography
                key={member.email}
                variant="body1"
                style={{ paddingTop: "1rem" }}
              >
                {t("membership.memberAdded", {
                  firstName: member.first_name,
                  lastName: member.last_name,
                  ns: "organization",
                })}
              </Typography>
            ))}

          <Box style={{ marginTop: "3rem" }}>
            <CsvBulkUpload organizationId={organizationId} />
          </Box>
        </Box>
      ) : null}
    </>
  );
};
export default AddMembership;
