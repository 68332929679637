import i18n from "i18next";
import { getDynamicTranslation } from "../../components/core/localization/localizationUtils";

export enum SalutationType {
  Neutral = 0,
  Male = 10,
  Female = 20,
}

export function getCurrentLanguage(): string {
  return i18n.language; // Replace 'default-language' with your actual default language
}

export function mapToSalutationTypeString(salutationType?: SalutationType): string {
  const currentLanguage = getCurrentLanguage();
  switch (salutationType) {
    case SalutationType.Male:
      return getDynamicTranslation("profile.overviewProfile.salutation.male", "common");
    case SalutationType.Female:
      return i18n.t("profile.overviewProfile.salutation.female", {
        ns: "common",
        lng: currentLanguage,
      });
    default:
      return "";
  }
}
export function mapSalutationTypeAsLabel(salutationType?: SalutationType): string {
  switch (salutationType) {
    case SalutationType.Male:
      return getDynamicTranslation("profile.overviewProfile.salutation.male", "common");
    case SalutationType.Female:
      return getDynamicTranslation(
        "profile.overviewProfile.salutation.female",
        "common"
      );
    default:
      return getDynamicTranslation("profile.overviewProfile.salutation.none", "common");
  }
}
export function mapSalutationTypeIdAsLabel(salutationTypeID: string): string {
  switch (salutationTypeID) {
    case SalutationType.Male.toString():
      return getDynamicTranslation("profile.overviewProfile.salutation.male", "common");
    case SalutationType.Female.toString():
      return getDynamicTranslation(
        "profile.overviewProfile.salutation.female",
        "common"
      );
    default:
      return "";
  }
}
export function salutationValueOrDefault(salutationType: string): string {
  if (salutationType === undefined || salutationType === "")
    return SalutationType.Neutral.toString();

  const salutation = salutationType as keyof typeof SalutationType;

  return salutation.toString();
}
