/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
import { AxiosError } from "axios";
import { Dispatch } from "redux";
import { cancellationInvoiceService } from "../../api";
import {
  CancellationInvoiceActionTypes,
  INVOICE_FAILURE,
  INVOICE_REQUEST,
  CancellationInvoiceRequestAction,
  CancellationInvoiceFailureAction,
  SetCancellationInvoiceListAction,
  SET_INVOICES_PAGE_COUNT,
  SET_CANCELLATION_INVOICE_LIST_DATA,
  SetPageCountAction,
} from "./types";
import { enqueueSnackbar } from "notistack";
import { CancellationInvoiceListData } from "../../models/cancellationInvoiceData";
import { Pagination } from "../../models/pagination";
import { getDynamicTranslation } from "../../components/core/localization/localizationUtils";

/**
 * Action creator to dispatch an action indicating cancellation invoice data request.
 */
export function invoiceRequest(): CancellationInvoiceRequestAction {
  return {
    type: INVOICE_REQUEST,
  };
}

/**
 * Action creator to dispatch an action indicating cancellation invoice data fetch failure.
 */
export function invoiceFailure(): CancellationInvoiceFailureAction {
  return {
    type: INVOICE_FAILURE,
  };
}

/**
 * Action creator to fetch cancellation invoices from the server.
 * Dispatches actions to update the Redux store based on the API response.
 */
export function getCancellationInvoices(page: number) {
  return (dispatch: Dispatch<CancellationInvoiceActionTypes>) => {
    dispatch(invoiceRequest());
    cancellationInvoiceService
      .getinvoiceList(page)
      .then((response: Pagination<CancellationInvoiceListData>) => {
        dispatch(setCancellationInvoiceListData(response));
      })
      .catch((err: AxiosError) => {
        dispatch(invoiceFailure());
        enqueueSnackbar(
          getDynamicTranslation("invoices.loadInvoicesError", "snackbars"),
          {
            variant: "error",
          }
        );
      });
  };
}

/**
 * Action creator to set cancellation invoice data in the Redux store.
 * @param data The cancellation invoice data to be set in the store.
 */
export function setCancellationInvoiceListData(
  data: Pagination<CancellationInvoiceListData>
): SetCancellationInvoiceListAction {
  return {
    type: SET_CANCELLATION_INVOICE_LIST_DATA,
    data,
  };
}

/**
 * Action creator to set page count in the Redux store.
 * @param pageCount The page count data to be set in the store.
 */
export function setPageCount(pageCount: number): SetPageCountAction {
  return {
    type: SET_INVOICES_PAGE_COUNT,
    pageCount: pageCount,
  };
}
