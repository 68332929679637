import * as React from "react";
import HeadingLumos from "../../../components/theming/HeadingLumos";
import TabsLumos from "../../../components/theming/tabs/TabsLumos";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux";
import { getTabsByRole } from "../../../components/core/profile-settings/tabsData";
import { useTranslation } from "react-i18next";
import { useTabPersistence } from "../../../hooks/useTabPersistence";

/**
 * Wrapper page for the profile settings.
 * @returns JSX.Element
 */
export const ProfileSettingsWrapperPage: React.FC = () => {
  const { t } = useTranslation(["common", "snackbars"]);
  const userData = useSelector((state: AppState) => state.user.currentUser);
  // Translate the labels
  const translatedTabs = getTabsByRole(userData?.user_type).map((tab) => ({
    ...tab,
    label: t(tab.label, { ns: "common" }),
  }));
  const { activeTab, handleTabChange } = useTabPersistence({ defaultTab: 0 });

  return (
    <>
      <HeadingLumos>{t("settings", { ns: "common" })}</HeadingLumos>

      <TabsLumos
        tabs={translatedTabs}
        activeTab={activeTab}
        onTabChange={handleTabChange}
      />
    </>
  );
};
