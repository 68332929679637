/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/button-has-type */
import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { isMobileDevice } from "../../../../../utils/isMobileDevice";
import "./BundIDModal.scss";
import { Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { createBundIDRequest } from "../../../../../redux/bundID/actions";

interface BundIDModalProps {
  open: boolean;
  setOpen: any;
  eventParticipation: string;
}

export const BundIDModal: React.FC<BundIDModalProps> = (props: BundIDModalProps) => {
  const { t } = useTranslation(["authOptions"]);
  const dispatch = useDispatch();

  const isMobile = isMobileDevice();

  // const bundIDState = useSelector((appState: AppState) => appState.bundID);

  const logo = require(
    `../../../../../assets/icons/bundid/Logo_weiss-bunt_Sicherheitszone_002.png`
  );

  const { open, setOpen, eventParticipation } = props;

  const mobileSuffix = "-mobile";

  let styleSuffix = "";

  if (isMobile) {
    styleSuffix = mobileSuffix;
  }

  const handleBundId = () => {
    dispatch(createBundIDRequest(eventParticipation));

    // call backend for saml bundId authentication
    const url = `${window.location.origin}/api/saml/login/`;
    window.location.href = url;
  };

  const handleDecline = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} className="dialog" style={{ padding: 0 }}>
      <DialogContent className="dialog">
        <div id="modal-container">
          <div id={`modal${styleSuffix}`}>
            <div id={`modal-header${styleSuffix}`}>
              <img src={logo} alt="BundID" />
            </div>
            <h3>{t("bundID.forwardNote1", { ns: "authOptions" })}</h3>
            <p>{t("bundID.forwardNote2", { ns: "authOptions" })}</p>
            <div id="modal-button-container">
              <button
                className="button-style"
                id={`modal-button${styleSuffix}`}
                onClick={handleBundId}
              >
                {t("bundID.buttonText", { ns: "authOptions" })}
              </button>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDecline} color="secondary">
          {t("close", { ns: "common" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
